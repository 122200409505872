import React from 'react';
import { LinearProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    width: '280px',
    maxWidth: '80%',
    minHeight: '100%'
  },
  container: {
    minHeight: '100vh',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '20%'
  }
}));

export default function ProgressBar() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.root}>
        <LinearProgress />
      </div>
    </div>
  );
}
