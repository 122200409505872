import { HttpMethodsEnum } from '../models/enums/httpMethodsEnum';
import { CreateUserRequest, User, EditCompanyUserRequest } from '../models/userModel';
import { LocalStorageItemEnum } from '../models/enums/localStorageItemEnum';

const { REACT_APP_BACKEND_API_KEY, REACT_APP_BACKEND_API_URL } = process.env;

const getOptions = (method: HttpMethodsEnum): { method: HttpMethodsEnum; headers: Headers } => {
  const idToken = localStorage.getItem(LocalStorageItemEnum.idToken);
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  if (typeof REACT_APP_BACKEND_API_KEY === 'string') {
    headers.append('x-api-key', REACT_APP_BACKEND_API_KEY);
  }
  if (idToken != null) {
    headers.append('Authorization', idToken);
  }
  return {
    method: method,
    headers
  };
};

const getUserById = (userId: string): Promise<Response> => {
  const options = getOptions(HttpMethodsEnum.get);
  return fetch(`${REACT_APP_BACKEND_API_URL}/users`, options);
};

const getUsersByCompanyId = (companyId: string): Promise<Response> => {
  const options = getOptions(HttpMethodsEnum.get);
  return fetch(`${REACT_APP_BACKEND_API_URL}/users?companyId=${companyId}`, options);
};

const createUser = (userDetails: User): Promise<Response> => {
  const options = getOptions(HttpMethodsEnum.post);
  return fetch(`${REACT_APP_BACKEND_API_URL}/users`, { ...options, body: JSON.stringify(userDetails) });
};

const createAccountHolder = (email: string): Promise<Response> => {
  const options = getOptions(HttpMethodsEnum.post);
  return fetch(`${REACT_APP_BACKEND_API_URL}/users`, { ...options, body: JSON.stringify({ email }) });
};

const createCompanyUser = async (userDetails: CreateUserRequest): Promise<Response> => {
  const options = getOptions(HttpMethodsEnum.post);
  return fetch(`${REACT_APP_BACKEND_API_URL}/company-user-management`, {
    ...options,
    body: JSON.stringify(userDetails)
  });
};

const editCompanyUser = async (userDetails: EditCompanyUserRequest): Promise<Response> => {
  const options = getOptions(HttpMethodsEnum.patch);
  return fetch(`${REACT_APP_BACKEND_API_URL}/company-user-management`, {
    ...options,
    body: JSON.stringify(userDetails)
  });
};

const removeCompanyUser = async (userId: string, companyId: string): Promise<Response> => {
  const options = getOptions(HttpMethodsEnum.delete);
  return fetch(`${REACT_APP_BACKEND_API_URL}/company-user-management`, {
    ...options,
    body: JSON.stringify({ userId, companyId })
  });
};

function updateUser(userDetails: User): Promise<Response> {
  const options = getOptions(HttpMethodsEnum.patch);
  return fetch(`${REACT_APP_BACKEND_API_URL}/users`, { ...options, body: JSON.stringify(userDetails) });
}

const deleteUser = (userId: string): Promise<Response> => {
  const options = getOptions(HttpMethodsEnum.delete);
  return fetch(`${REACT_APP_BACKEND_API_URL}/users`, { ...options, body: JSON.stringify({ userId }) });
};

export { getUserById, getUsersByCompanyId, createUser, updateUser, deleteUser, createCompanyUser, removeCompanyUser, editCompanyUser, createAccountHolder };
