import React, { useState, useContext } from 'react';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  Table,
  TableContainer,
  Theme,
  Link as LinkUI,
  IconButton,
  Menu,
  MenuItem,
  Typography
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import NoData from './noData';
import { Company } from './Models/CompanyModel';
import { deleteCompany } from '../../api-queries/companyManagement';
import EditCompany from './Company/editCompany';
import ConfirmationSnackbar from '../common/Confirmation/confirmationSnackbar';
import { minimumAccessRequirements } from '../../utils/userLevelEnum';
import { UserContext } from '../../contexts/UserContext';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: '2%',
    maxwidth: '100%'
  },
  table: {
    maxWidth: '95%',
    margin: 'auto'
  },
  header: {
    fontWeight: 'bold'
  },
  hiddenOnMobile: {
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
}));

interface Props {
  companies: Company[];
  setCompanies: React.Dispatch<React.SetStateAction<any>>;
  fullCompanies: Company[];
}

const CompanyList: React.FC<Props> = ({ companies, setCompanies, fullCompanies }): JSX.Element => {
  const classes = useStyles();
  const { loggedInUser } = useContext<any>(UserContext);
  const emptyCompany: Company = {
    appIds: [],
    userPermissions: [],
    companyContactEmail: '',
    companyContactName: '',
    companyDescription: '',
    companyId: '',
    companyName: ''
  };
  const [editCompany, setEditCompany] = useState<Company | null>(null);
  // confirmation state
  const [selectedCompany, setSelectedCompany] = useState<Company>(emptyCompany);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const companyIsSelected = Boolean(anchorEl);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [type, setType] = useState<string>('');

  const toggleEditCompany = (): void => setAnchorEl(null);

  // sets the row to edit and turns things into text fields
  const handleEdit = () => {
    setEditCompany(selectedCompany);
  };

  const handleEditCompanyMenu = (event: React.MouseEvent<HTMLElement>, company: Company | undefined): void => {
    setAnchorEl(event.currentTarget);
    if (company) setSelectedCompany(company);
  };

  const handleDeleteCompany = async (): Promise<void> => {
    try {
      const response = await deleteCompany(selectedCompany.companyId);
      if (response === 204) {
        const companiesCopy = [...fullCompanies];
        const indexOfCompany = companiesCopy.findIndex(company => company.companyId === selectedCompany.companyId);
        companiesCopy.splice(indexOfCompany, 1);
        setCompanies([...companiesCopy]);
        toggleEditCompany();
        setOpenConfirm(true);
        setType('success');
      } else {
        setOpenConfirm(true);
        setType('error');
      }
    } catch (e) {
      console.log(e);
    }
  };

  const ITEM_HEIGHT = 48;

  interface userPermissions {
    userId: string;
    userLevel: number;
  }

  const checkUserCanViewCompany = (userPermissionsArr: userPermissions[]): boolean => {
    const foundUser: userPermissions | undefined = userPermissionsArr.find(user => user.userId === loggedInUser.userId);
    if (foundUser && foundUser.userLevel >= minimumAccessRequirements.viewCompanyPage) return true;
    if (loggedInUser.userLevel > minimumAccessRequirements.companyManage) return true;
    return false;
  };

  const checkUserCanEditAndDeleteCompany = (userPermissionsArr: userPermissions[]): boolean => {
    const foundUser: userPermissions | undefined = userPermissionsArr.find(user => user.userId === loggedInUser.userId);
    if (foundUser && foundUser.userLevel >= minimumAccessRequirements.companyManage) return true;
    if (loggedInUser.userLevel > minimumAccessRequirements.companyManage) return true;
    return false;
  };

  return companies && companies.length > 0 ? (
    <div>
      <TableContainer className={classes.paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.header}>Company Name</TableCell>
              <TableCell className={classes.header}>Users</TableCell>
              <TableCell className={classes.header}>Apps</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {companies.map((company: Company, idx) => {
              return (
                <TableRow key={idx}>
                  <TableCell>
                    {checkUserCanViewCompany(company.userPermissions) ? (
                      <LinkUI component={Link} to={`${company.companyId}/manage`}>
                        {company.companyName}
                      </LinkUI>
                    ) : (
                      <Typography>{company.companyName}</Typography>
                    )}
                  </TableCell>
                  <TableCell>{company.userPermissions.length}</TableCell>
                  <TableCell>{company.appIds.length}</TableCell>
                  <TableCell>
                    {checkUserCanEditAndDeleteCompany(company.userPermissions) && (
                      <div>
                        <IconButton
                          aria-label="more"
                          aria-controls="long-menu"
                          aria-haspopup="true"
                          onClick={e => {
                            handleEditCompanyMenu(e, company);
                          }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          key={idx}
                          id="long-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={companyIsSelected}
                          onClose={toggleEditCompany}
                          PaperProps={{
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5,
                              width: '20ch'
                            }
                          }}
                        >
                          <MenuItem button onClick={handleEdit}>
                            Edit
                          </MenuItem>
                          <MenuItem button onClick={handleDeleteCompany}>
                            Remove
                          </MenuItem>
                        </Menu>
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {editCompany && (
          <EditCompany
            company={editCompany}
            fullCompanies={fullCompanies}
            setCompanies={setCompanies}
            setEditCompany={setEditCompany}
            toggleAnchorEl={toggleEditCompany}
            setOpenConfirm={setOpenConfirm}
            setType={setType}
          />
        )}
      </TableContainer>
      <ConfirmationSnackbar open={openConfirm} setOpen={setOpenConfirm} type={type} />
    </div>
  ) : (
    <NoData category={'companies'} />
  );
};

export default CompanyList;
