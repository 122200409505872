import React, { useEffect, useState, useContext } from 'react';
import {
  Backdrop,
  Button,
  Chip,
  Fade,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  makeStyles,
  MenuItem,
  Modal,
  Select,
  Switch,
  TextField,
  Typography,
  useTheme
} from '@material-ui/core';
import { ApiEndpoint } from './Interface/ApiMonitoringResponse';
import { EditEndpointRequest } from './Interface/EditEndpointRequest';
import { editEndpoint } from '../../api-queries/apiMonitoring';
import ConfirmationSnackbar from '../common/Confirmation/confirmationSnackbar';
import statusCodes from './statusCodes';
import { HttpMethodsEnum } from '../../models/enums/httpMethodsEnum';
import { AppContext } from '../../contexts/AppContext';
import { disabledSwitchExplanation, checkSwitchIsDisabled } from '../../utils/authEndpointSwitchUtils';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

function getStyles(code: number, statusCode: number[], theme: any) {
  return {
    fontWeight: statusCode.indexOf(code) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightBold
  };
}

interface Props {
  appId: string;
  endpoint: ApiEndpoint;
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  editEndpointInArray: (updatedEndpoint: ApiEndpoint) => void;
  refresh: Function;
}

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[0],
    padding: theme.spacing(3),
    outline: 'none',
    border: 'solid 1px rgba(35, 0, 30, 0.2)',
    borderRadius: '5px',
    '&:focus': {
      outline: 'none'
    },
    maxWidth: '400px'
  },
  formTitle: {
    marginBottom: '5%'
  },
  button: {
    marginRight: '2%',
    backgroundColor: '#fff',
    border: 'solid 1px rgba(35, 0, 30, 0.2)',
    textTransform: 'none',
    '&:hover': {
      background: '#DCDCDC',
      transition: '0.3s',
      transitionTimingFunction: 'ease-in-out'
    }
  },
  textField: {
    display: 'block',
    width: '100%'
  },
  input: {
    width: '100%',
    height: '30pX'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    display: 'block'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  select: {
    width: '100%'
  },
  note: {
    marginTop: '15px',
    marginBottom: '15px'
  }
}));

const EditEndpointModal: React.FC<Props> = ({ appId, endpoint, open, setOpen, editEndpointInArray, refresh }) => {
  const { appInContext } = useContext<any>(AppContext);
  const classes = useStyles();
  const theme = useTheme();
  const [updatedEndpoint, setUpdatedEndpoint] = useState<ApiEndpoint>();
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [type, setType] = useState<string>('');

  const toggleOpen = (): void => setOpen(!open);

  useEffect(() => {
    setUpdatedEndpoint(endpoint);
  }, [endpoint]);

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (!updatedEndpoint) return;

    const requestBody: EditEndpointRequest = {
      appId,
      endpoint: updatedEndpoint
    };
    try {
      const response: Response = await editEndpoint(requestBody);
      if (response.status === 200) {
        setType('success');
        setOpenConfirmation(true);
        toggleOpen();
        editEndpointInArray(updatedEndpoint);
        refresh(true);
      } else {
        setType('error');
        setOpenConfirmation(true);
      }
    } catch (e) {
      throw new Error((e as unknown) as string);
    }
  };

  const handleChangeMethod = (method: string): void => {
    if (!updatedEndpoint) return;
    setUpdatedEndpoint({ ...updatedEndpoint, method });
  };

  const handleChangeStatusCodes = (updatedCodes: number[]): void => {
    if (!updatedEndpoint) return;
    setUpdatedEndpoint({ ...updatedEndpoint, rejectedStatusCodes: updatedCodes });
  };

  const handleAuthChange = () => {
    if (!updatedEndpoint) return;
    setUpdatedEndpoint({ ...updatedEndpoint, method: HttpMethodsEnum.get, requiresAuth: !updatedEndpoint.requiresAuth });
  };

  const handleCancel = (): void => {
    setUpdatedEndpoint(endpoint);
    toggleOpen();
  };

  if (!updatedEndpoint) {
    return null;
  }
  return (
    <div>
      <Modal
        disableEnforceFocus
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
        className={classes.modal}
        open={open}
        onClose={toggleOpen}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Fade in={open}>
          <form className={classes.paper} onSubmit={handleSubmit}>
            <div className={classes.formTitle}>
              <Typography variant="h5">Edit endpoint</Typography>
            </div>
            <TextField
              required
              onChange={e => setUpdatedEndpoint({ ...updatedEndpoint, name: e.target.value })}
              className={classes.textField}
              id="outlined-basic"
              label="Name"
              variant="outlined"
              fullWidth
              margin="dense"
              value={updatedEndpoint.name}
            />
            <TextField
              required
              onChange={e => setUpdatedEndpoint({ ...updatedEndpoint, url: e.target.value })}
              className={classes.textField}
              id="outlined-basic"
              label="Url"
              variant="outlined"
              fullWidth
              margin="dense"
              value={updatedEndpoint.url}
            />
            <Typography>Endpoint requires authorisation:</Typography>
            <Switch
              disabled={checkSwitchIsDisabled(appInContext)}
              checked={updatedEndpoint.requiresAuth}
              name="needsAuthSwitch"
              onChange={handleAuthChange}
            />
            {checkSwitchIsDisabled(appInContext) && <Typography>{disabledSwitchExplanation}</Typography>}
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-helper-label">Method:</InputLabel>
              <Select
                required
                className={classes.select}
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={updatedEndpoint.method}
                disabled={updatedEndpoint.requiresAuth}
                onChange={(e: React.ChangeEvent<{ name?: string | undefined; value: any }>): void => handleChangeMethod(e.target.value)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={HttpMethodsEnum.get}>Get</MenuItem>
                <MenuItem value={HttpMethodsEnum.post}>Post</MenuItem>
                <MenuItem value={HttpMethodsEnum.patch}>Patch</MenuItem>
                <MenuItem value={HttpMethodsEnum.delete}>Delete</MenuItem>
              </Select>
              <FormHelperText>Select a HTTP Method from the list above</FormHelperText>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-mutiple-chip-label">Rejected Status Codes</InputLabel>
              <Select
                required
                className={classes.select}
                labelId="demo-mutiple-chip-label"
                id="demo-mutiple-chip"
                multiple
                value={updatedEndpoint.rejectedStatusCodes}
                onChange={(e: React.ChangeEvent<{ name?: string | undefined; value: any }>): void => handleChangeStatusCodes(e.target.value)}
                input={<Input id="select-multiple-chip" />}
                renderValue={(selected: any) => (
                  <div className={classes.chips}>
                    {selected.map((value: any) => (
                      <Chip key={value} label={value} className={classes.chip} />
                    ))}
                  </div>
                )}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  getContentAnchorEl: null,
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: 250
                  }
                }}
              >
                {statusCodes.map(code => (
                  <MenuItem key={code} value={code} style={getStyles(code, endpoint.rejectedStatusCodes, theme)}>
                    {code}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Select one or more status codes from the list above</FormHelperText>
            </FormControl>
            <Button type="submit" className={classes.button}>
              Submit
            </Button>
            <Button type="reset" onClick={handleCancel} className={classes.button}>
              Cancel
            </Button>
          </form>
        </Fade>
      </Modal>
      <ConfirmationSnackbar open={openConfirmation} setOpen={setOpenConfirmation} type={type} />
    </div>
  );
};
export default EditEndpointModal;
