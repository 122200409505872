import React, { useMemo, useState } from 'react';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import ConfirmationSnackbar from '../common/Confirmation/confirmationSnackbar';
import { createAccountHolder } from '../../api-queries/userManagement';
import { ValidateEmail } from '../../utils/helpers';
import { Controller, useForm } from 'react-hook-form';
import { CreateAccountHolderRequest } from '../../models/userModel';

interface Props {
  open: boolean;
  handleClose: () => void;
}

const CreateAccountHolder: React.FC<Props> = ({ open, handleClose }): JSX.Element => {
  // Success snackbar
  const [type, setType] = useState<string>();
  // Confirmation modal state
  const [openConfirm, setOpenConfirm] = useState(false);

  const emptyCreateAccountHolderRequest: CreateAccountHolderRequest = {
    email: ''
  };

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<CreateAccountHolderRequest>({
    defaultValues: useMemo(() => {
      return emptyCreateAccountHolderRequest;
    }, [emptyCreateAccountHolderRequest]),
    mode: 'onChange'
  });

  const onSubmit = async (createAccountHolderRequest: CreateAccountHolderRequest): Promise<void> => {
    try {
      const result = await createAccountHolder(createAccountHolderRequest.email);
      if (result.status === 200) {
        setOpenConfirm(true);
        setType('success');
        onClose();
      } else {
        setOpenConfirm(true);
        setType('error');
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onClose = () => {
    reset();
    handleClose();
  };

  const handleEmailValidation = (email: string): boolean => {
    return ValidateEmail(email.trim());
  };

  return (
    <div>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          <span>Please enter the email of the account holder you would like to add:</span>
        </DialogTitle>
        <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{ required: true, validate: email => handleEmailValidation(email) }}
              render={({ field }) => (
                <TextField
                  error={!!errors.email}
                  id="email"
                  label="Email *"
                  fullWidth
                  helperText={errors.email ? <span>Please enter a valid email address</span> : null}
                  {...field}
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary" disabled={!isValid}>
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <ConfirmationSnackbar open={openConfirm} setOpen={setOpenConfirm} type={type} />
    </div>
  );
};

export default CreateAccountHolder;
