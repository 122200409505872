import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TablePagination, Hidden } from '@material-ui/core';

export default function Paginator({ totalItems, page, setPage, rows, setRows }) {
  const [options] = useState([5, 10, 20, 50, 100]);
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState(options);

  useEffect(() => {
    setRowsPerPageOptions(options.filter(num => num <= totalItems));
  }, [totalItems, setPage, options]);

  const handleChangePage = (e, newPage) => {
    e.preventDefault();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRows(event.target.value);
  };

  return (
    <>
      <Hidden xsDown>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={totalItems}
          rowsPerPage={rows}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Hidden>
      <Hidden smUp>
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={totalItems}
          rowsPerPage={rows}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Hidden>
    </>
  );
}

Paginator.propTypes = {
  setRows: () => {},
  setPage: () => {},
  rows: PropTypes.number,
  page: PropTypes.number,
  totalItems: PropTypes.number
};

Paginator.defaultProps = {
  setRows: () => {},
  setPage: () => {},
  page: 0
};
