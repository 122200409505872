import React from 'react';
import { makeStyles } from '@material-ui/core';
import { CheckCircle as CheckCircleIcon, Error as ErrorIcon } from '@material-ui/icons';


interface Props {
  successRate: number;
}

const useStyles = makeStyles(theme => ({
	statusIcon: {
		margin: theme.spacing(0, 1, 0, 0)
	}
}));

const EndpointStatusIcon: React.FC<Props> = ({ successRate }) => {
	const classes = useStyles();
	if (successRate > 98) {
			return  <CheckCircleIcon style={{ color: 'green' }} className={classes.statusIcon}/>;
	} else if (successRate > 90) {
			return  <ErrorIcon style={{ color: 'orange' }} className={classes.statusIcon}/>;
	} else {
			return <ErrorIcon style={{ color: '#E30613' }} className={classes.statusIcon}/>;
	}
};

export default EndpointStatusIcon;
