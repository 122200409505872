import { Fade, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { GetAllUrls } from '../../api-queries/sslMonitoring';
import ProgressBar from '../common/ProgressBar';
import PageTitle from '../PageTitle';
import SslUrlListItem from './SslUrlListItem';

interface urlData {
  url: string;
  expiry: number;
  appName: string;
}

const SslOverview = () => {
  const [checked] = useState(true);
  const [urls, setUrls] = useState<urlData[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getURLs = async () => {
      try {
        const res = await GetAllUrls();
        const data = await res.json();
        setUrls(data.sslUrls);
        setLoading(false);
      } catch (e) {
        throw new Error(e);
      }
    };
    getURLs();
  }, []);

  let mappedUrls = urls.map(sslItem => {
    return {
      ...sslItem,
      expiry: new Date(sslItem.expiry),
      daysUntilExpiry: Math.floor((sslItem.expiry - Date.now()) / (1000 * 60 * 60 * 24))
    };
  });

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <PageTitle text={'SSL Overview'} />
      </Grid>
      <Fade in={checked} {...(checked ? { timeout: 300 } : {})}>
        <Grid item xs={12}>
          {urls.length > 0 &&
            mappedUrls
              .sort((a, b) => a.daysUntilExpiry - b.daysUntilExpiry)
              .map((item, idx) => (
                <SslUrlListItem key={idx} url={item.url} expiryDate={item.expiry} daysUntilExpiry={item.daysUntilExpiry} appName={item.appName} />
              ))}
          {loading && <ProgressBar />}
          {urls.length <= 0 && <p>There are no URLs being monitored currently</p>}
        </Grid>
      </Fade>
    </Grid>
  );
};

export default SslOverview;
