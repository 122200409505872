import React, { useContext } from 'react';
import { makeStyles, CircularProgress } from '@material-ui/core';
import { AppContext } from '../../contexts/AppContext';

const useStyles = makeStyles(() => ({
  appTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  appText: {
    marginRight: '10px'
  }
}));

export default function AppTitle() {
  const classes = useStyles();
  const { appInContext } = useContext(AppContext);

  return (
    <div className={classes.appTitle}>
      <h1 className={classes.appText}>{appInContext.appName.length > 0 ? appInContext.appName : <CircularProgress size={16} />}</h1>
    </div>
  );
}
