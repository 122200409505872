import React, { useState, useEffect, useContext } from 'react';
import { Fade, Grid, Button, Typography, useTheme, useMediaQuery, makeStyles } from '@material-ui/core';
import { SelectionRangeContext } from '../../contexts/SelectionRangeContext';
import PageTitle from '../PageTitle';
import EndpointList from './EndpointList';
import ApiStatsCard from './ApiStatsCard';
import { getApiReports } from '../../api-queries/apiMonitoring';
import { ApiEndpoint, ApiMonitoringResponse, EndpointBreakdown } from './Interface/ApiMonitoringResponse';
import ProgressBar from '../common/ProgressBar';
import AddEndpoint from './AddEndpoint';
import Paginator from '../common/Paginator';
import SubTitle from '../SubTitles';
import ConfigureMonitoringRateAndAlerts from './ConfigureMonitoringRateAndAlerts';
import ConfirmationSnackbar from '../common/Confirmation/confirmationSnackbar';
import { AppContext } from '../../contexts/AppContext';
import { UserLevels } from '../../utils/userLevelEnum';
import ConfigureAuthUser from './ConfigureAuthUser';

const useStyles = makeStyles({
  progress: {
    height: '100vh',
    width: '100%'
  },
  configureAlertsButton: {
    marginLeft: '10px'
  }
});

const ApiMonitoring: React.FC = () => {
  const classes = useStyles();
  const { selectionRange } = useContext(SelectionRangeContext);
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [apiData, setApiData] = useState<ApiMonitoringResponse | null>(null);
  const [endpointData, setEndpointData] = useState<EndpointBreakdown[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [checked] = useState(true);
  //modal State
  const [addEndpointModalOpen, setAddEndpointModalOpen] = useState<boolean>(false);
  const [alertsModalOpen, setAlertsModalOpen] = useState<boolean>(false);
  const [authUserModalOpen, setAuthUserModalOpen] = useState<boolean>(false);
  // Paginator
  const [page, setPage] = useState<number>(0);
  const [rows, setRows] = useState<number>(5);

  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [type, setType] = useState<string>();

  const { appInContext } = useContext(AppContext);

  const editPermission = appInContext.userLevel >= UserLevels.adminUser;

  const addEndpointModalToggleOpen = (): void => {
    setAddEndpointModalOpen(!addEndpointModalOpen);
  };

  const alertsModalToggleOpen = (): void => {
    setAlertsModalOpen(!alertsModalOpen);
  };

  const getReports = async (clearCache?: boolean) => {
    setLoading(true);
    try {
      const response = await getApiReports(appInContext.appId, selectionRange.startDateMS, selectionRange.endDateMS, clearCache);
      const data: ApiMonitoringResponse = await response.json();
      if (response.status === 200) {
        setApiData(data);
        setEndpointData(data.endpointBreakdowns);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (e) {
      throw new Error((e as unknown) as string);
    }
  };

  const handleGetReports = (clearCache?: boolean) => {
    getReports(clearCache);
  };

  useEffect(handleGetReports, []);

  const removeEndpointFromArray = (endpointId: string): void => {
    endpointData.splice(
      endpointData.findIndex(endpoint => endpoint.endpointDetails.endpointId === endpointId),
      1
    );
    setEndpointData([...endpointData]);
  };

  const editEndpointInArray = (updatedEndpoint: ApiEndpoint): void => {
    const endpointToEditIndex = endpointData.findIndex(endpoint => endpoint.endpointDetails.endpointId === updatedEndpoint.endpointId);
    endpointData[endpointToEditIndex].endpointDetails = updatedEndpoint;
    setEndpointData([...endpointData]);
  };

  const updateLoginEndpointUrl = (url: string): void => {
    const loginEndpointBreakdown = endpointData?.find(
      endpointBreakdown => endpointBreakdown.endpointDetails.endpointId === appInContext.monitoringAuth?.loginEndpointId
    );
    if (loginEndpointBreakdown) {
      loginEndpointBreakdown.endpointDetails.url = url;
      setEndpointData([...endpointData]);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <PageTitle text={'API Monitoring'} />
      </Grid>
      <Grid item container justify="flex-end" alignItems="flex-start" xs={12} md={6}>
        <Button onClick={() => handleGetReports(true)}>Refresh</Button>
      </Grid>
      {!loading && apiData?.endpointBreakdowns && (
        <Grid container spacing={isSm ? 3 : 6}>
          <Fade in={checked} {...(checked ? { timeout: 300 } : {})}>
            <Grid item xs={12}>
              <SubTitle text={'API Overall Health'} />
              <ApiStatsCard data={apiData} />
            </Grid>
          </Fade>
          <Fade in={checked} {...(checked ? { timeout: 300 } : {})}>
            <Grid item xs={12}>
              <SubTitle text={'Endpoints'} />
              <EndpointList
                appId={appInContext.appId}
                endpoints={endpointData.slice(rows * page, rows * page + rows)}
                removeEndpoint={removeEndpointFromArray}
                editEndpointInArray={editEndpointInArray}
                refresh={handleGetReports}
                loginEndpointId={appInContext.monitoringAuth?.loginEndpointId}
              />
            </Grid>
          </Fade>
          <Grid item lg={6}>
            <Button onClick={addEndpointModalToggleOpen} disabled={!editPermission}>
              Add Endpoint +
            </Button>
            <Button className={classes.configureAlertsButton} onClick={alertsModalToggleOpen} disabled={!editPermission}>
              Configure Monitoring Rate {'&'} Alerts ⚠
            </Button>
            <Button className={classes.configureAlertsButton} onClick={() => setAuthUserModalOpen(!authUserModalOpen)} disabled={!editPermission}>
              Configure Auth User
            </Button>
          </Grid>
          <Grid item lg={6}>
            <Paginator totalItems={apiData.endpointBreakdowns.length} page={page} setPage={setPage} rows={rows} setRows={setRows} />
          </Grid>
        </Grid>
      )}
      {!loading && apiData?.message && (
        <Grid container spacing={isSm ? 3 : 6}>
          <Grid item lg={12}>
            <Typography>
              It looks like you haven't added any endpoints yet or we havent begun collected data yet, please click 'Add Endpoint' below to start or check
              back in a short while to see your results
            </Typography>
          </Grid>
          <Grid item lg={6}>
            <Button onClick={addEndpointModalToggleOpen} disabled={!editPermission}>
              Add Endpoint +
            </Button>
            <Button className={classes.configureAlertsButton} onClick={alertsModalToggleOpen} disabled={!editPermission}>
              Configure Monitoring Rate {'&'} Alerts ⚠
            </Button>
            <Button className={classes.configureAlertsButton} onClick={() => setAuthUserModalOpen(!authUserModalOpen)} disabled={!editPermission}>
              Configure Auth User
            </Button>
          </Grid>
        </Grid>
      )}
      {loading && (
        <div className={classes.progress}>
          <ProgressBar />
        </div>
      )}
      <Grid item xs={12}>
        <AddEndpoint appId={appInContext.appId} open={addEndpointModalOpen} toggleOpen={addEndpointModalToggleOpen} refresh={handleGetReports} />
        <ConfigureMonitoringRateAndAlerts
          open={alertsModalOpen}
          onClose={alertsModalToggleOpen}
          onOpenConfirmationSnackbar={t => {
            setType(t);
            setOpenConfirmation(true);
          }}
        />

        <ConfigureAuthUser
          open={authUserModalOpen}
          toggleOpen={() => setAuthUserModalOpen(!authUserModalOpen)}
          setType={setType}
          setOpenConfirmation={setOpenConfirmation}
          updateLoginEndpointUrl={updateLoginEndpointUrl}
          refresh={handleGetReports}
        />
      </Grid>
      <ConfirmationSnackbar open={openConfirmation} setOpen={setOpenConfirmation} type={type} />
    </Grid>
  );
};

export default ApiMonitoring;
