import { Paper, Grid, Typography, makeStyles } from '@material-ui/core';
import React from 'react';

interface Props {
  url: string;
  expiryDate: Date;
  daysUntilExpiry: number;
  appName: string;
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    marginBottom: '12px',
    padding: theme.spacing(2, 3)
  },
  urlDetails: {
    '& p': {
      [theme.breakpoints.up('sm')]: {
        paddingLeft: '24px',
        marginLeft: '-24px',
        borderLeft: '1px solid',
        borderColor: theme.palette.grey[300]
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: '0',
        borderLeft: '0px transparent',
        marginTop: '12px'
      }
    }
  },
  red: {
    backgroundColor: '#D2222D'
  },
  amber: {
    backgroundColor: '#FFBF00'
  },
  green: {
    backgroundColor: '#2BB02B'
  },
  daysLeftCard: {
    minWidth: '52%',
    padding: '8px',
    borderRadius: '12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& p': {
      border: '0px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '8px',
      marginTop: '12px',
      '& p': {
        padding: '0px',
        margin: '0px'
      }
    }
  },
  daysLeftText: {
    color: 'white'
  }
}));

const SslUrlListItem: React.FC<Props> = ({ url, expiryDate, daysUntilExpiry, appName }) => {
  const classes = useStyles();

  let daysLeftClass = classes.green;

  if (daysUntilExpiry <= 30) {
    daysLeftClass = classes.red;
  } else if (daysUntilExpiry <= 60) {
    daysLeftClass = classes.amber;
  }

  return (
    <Paper className={classes.paper}>
      <Grid container item xs={12}>
        <Grid item container alignItems="center" xs={12} md={3}>
          <Typography noWrap>
            <strong>{url}</strong>
          </Typography>
        </Grid>

        <Grid item container alignItems="center" justify="center" xs={12} md={9} className={classes.urlDetails}>
          <Grid item xs={12} md={5}>
            <Typography noWrap>
              <strong>Project Name:</strong> {appName}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography>
              <strong>Expiry Date:</strong> {expiryDate.toDateString()}
            </Typography>
          </Grid>
          <Grid item container xs={12} md={3} justify="center">
            <div className={`${daysLeftClass} + ${classes.daysLeftCard}`}>
              <Typography className={classes.daysLeftText}>{daysUntilExpiry} Days Left </Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SslUrlListItem;
