/* eslint-disable max-len */
import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function ApiCallsIcon(props) {
  return (
    <SvgIcon viewBox="0 0 55 46" width="55px" height="46px" titleAccess="API Calls Icon" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group" transform="translate(3.000000, 0.000000)">
          <rect id="Rectangle" stroke="#23001E" x="0.5" y="0.5" width="47" height="39" rx="4"></rect>
          <rect id="Rectangle" fill="#FFFFFF" x="13" y="37" width="22" height="4"></rect>
        </g>
        <rect id="Rectangle" stroke="#23001E" x="4.5" y="9.5" width="45" height="1"></rect>
        <circle id="Oval" fillOpacity="0.2" fill="#23001E" cx="7" cy="5" r="1"></circle>
        <circle id="Oval" fillOpacity="0.2" fill="#23001E" cx="10" cy="5" r="1"></circle>
        <circle id="Oval" fillOpacity="0.2" fill="#23001E" cx="13" cy="5" r="1"></circle>
        <text id="API" fontFamily="Montserrat-SemiBold, Montserrat" fontSize="14" fontWeight="500" fill="#23001E">
          <tspan x="14.442" y="26">
            API
          </tspan>
        </text>
        <g id="Arrow-Up" transform="translate(17.696699, 31.196699)" fill="#D8D8D8" stroke="#979797">
          <rect id="Rectangle" x="3.80330086" y="1.30330086" width="1" height="12" rx="0.5"></rect>
          <rect
            id="Rectangle"
            transform="translate(5.828427, 2.828427) rotate(-45.000000) translate(-5.828427, -2.828427) "
            x="5.32842712"
            y="0.328427125"
            width="1"
            height="5"
            rx="0.5"
          ></rect>
          <rect
            id="Rectangle"
            transform="translate(2.828427, 2.828427) scale(-1, 1) rotate(-45.000000) translate(-2.828427, -2.828427) "
            x="2.32842712"
            y="0.328427125"
            width="1"
            height="5"
            rx="0.5"
          ></rect>
        </g>
        <g
          id="Arrow-Up"
          transform="translate(32.025126, 38.098350) scale(1, -1) translate(-32.025126, -38.098350) translate(27.696699, 31.196699)"
          fill="#D8D8D8"
          stroke="#979797"
        >
          <rect id="Rectangle" x="3.80330086" y="1.30330086" width="1" height="12" rx="0.5"></rect>
          <rect
            id="Rectangle"
            transform="translate(5.828427, 2.828427) rotate(-45.000000) translate(-5.828427, -2.828427) "
            x="5.32842712"
            y="0.328427125"
            width="1"
            height="5"
            rx="0.5"
          ></rect>
          <rect
            id="Rectangle"
            transform="translate(2.828427, 2.828427) scale(-1, 1) rotate(-45.000000) translate(-2.828427, -2.828427) "
            x="2.32842712"
            y="0.328427125"
            width="1"
            height="5"
            rx="0.5"
          ></rect>
        </g>
      </g>
    </SvgIcon>
  );
}

export default ApiCallsIcon;
