import { Backdrop, Button, makeStyles, Typography, useTheme } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import React, { useContext, useState } from 'react';

import ConfirmationSnackbar from '../common/Confirmation/confirmationSnackbar';
import { AppContext } from '../../contexts/AppContext';
import { AddNewCollection } from '../../api-queries/postmanMonitoring';
import NumberLinedTextArea from './NumberLinedTextArea';

interface Props {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[0],
    padding: theme.spacing(3),
    outline: 'none',
    border: 'solid 1px rgba(35, 0, 30, 0.2)',
    borderRadius: '5px',
    '&:focus': {
      outline: 'none'
    },
    minWidth: '80vw'
  },
  button: {
    marginTop: '1rem',
    marginRight: '1.5%'
  }
}));

const AddCollection: React.FC<Props> = ({ open, onClose, onSuccess }) => {
  const classes = useStyles();
  const theme = useTheme();

  const [collectionString, setCollectionString] = useState<string>('');
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [type, setType] = useState<string>();
  const { appInContext } = useContext(AppContext);

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    try {
      const response = await AddNewCollection(collectionString, appInContext.appId);
      const jsonRes = await response.json();

      if (response.status === 200) {
        setCollectionString('');
        appInContext.postmanMonitoringDetails = jsonRes.postmanMonitoringDetails;
        onClose();
        onSuccess();
      } else {
        setOpenConfirmation(true);
        setType('error');
      }
    } catch (e) {
      throw new Error(e);
    }
  };

  const handleClear = () => {
    onClose();
    setCollectionString('');
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <div className={classes.paper}>
        <Typography variant="h4">Add a New Collection</Typography>
        <form onSubmit={handleSubmit}>
          <NumberLinedTextArea value={collectionString} onChange={code => setCollectionString(code)} />
          <Button type="submit" className={classes.button} id="submit-collection">
            Submit
          </Button>
          <Button type="reset" onClick={handleClear} className={classes.button}>
            Cancel
          </Button>
          <ConfirmationSnackbar open={openConfirmation} setOpen={setOpenConfirmation} type={type} />
        </form>
      </div>
    </Modal>
  );
};

export default AddCollection;
