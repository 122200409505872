import * as React from 'react';
import iconSize from './iconSizes';

function SvgLogOutIcon(props) {
  return (
    <svg id="LogOutIcon_svg__Layer_1" data-name="Layer 1" viewBox="0 0 24 24" {...props} height={iconSize(props.size)} width={iconSize(props.size)}>
      <defs>
        <style>{'.LogOutIcon_svg__cls-1{fill:none;stroke:#3c3c3b;stroke-linecap:round;stroke-width:.75px;stroke-linejoin:round}'}</style>
      </defs>
      <path className="LogOutIcon_svg__cls-1" d="M12 12.04h8M17.84 10.07l2.15 1.96-2.09 1.9" />
      <path
        d="M16 10.07V4a2 2 0 00-2-2H6a2 2 0 00-2 2v16a2 2 0 002 2h8a2 2 0 002-2v-6.07"
        strokeMiterlimit={10}
        fill="none"
        stroke="#3c3c3b"
        strokeLinecap="round"
        strokeWidth={0.75}
      />
    </svg>
  );
}

export default SvgLogOutIcon;
