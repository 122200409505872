import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Backdrop, Button, Fade, makeStyles, MenuItem, Modal, Select, TextField, Typography } from '@material-ui/core';
import { AppContext } from '../../contexts/AppContext';
import { AppContextModel } from '../../contexts/interfaces/appContextModel';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { DeleteApiMonitoringAuth, MonitoringAuth, MonitoringAuthRequest } from './Interface/ApiMonitoringAuth';
import { deleteAppAuthUser, postAppAuthUser, testAuthUserConfig } from '../../api-queries/appManagement';
import clsx from 'clsx';
import { ApiContentTypeEnum } from '../../utils/apiContentTypeEnum';

interface Props {
  open: boolean;
  setType: Function;
  setOpenConfirmation: Function;
  updateLoginEndpointUrl: Function;
  toggleOpen(): void;
  refresh: Function;
}

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[0],
    padding: theme.spacing(3),
    outline: 'none',
    border: 'solid 1px rgba(35, 0, 30, 0.2)',
    borderRadius: '5px',
    '&:focus': {
      outline: 'none'
    },
    minWidth: '400px',
    maxWidth: '600px',
    maxHeight: '100vh',
    overflowX: 'hidden',
    overflowY: 'scroll'
  },
  formTitle: {
    marginBottom: '5%'
  },
  button: {
    marginRight: '2%'
  },
  testState: {
    display: 'flex',
    alignItems: 'center'
  },
  testStatePass: {
    color: '#00b173'
  },
  testStateFail: {
    color: '#E30613'
  },
  textField: {
    display: 'block',
    width: '100%'
  },
  inlineFieldWrapper: {
    display: 'flex'
  },
  note: {
    marginTop: '15px',
    marginBottom: '15px'
  },
  bottomButtons: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  bottomButtonsLayout: {
    display: 'flex'
  },
  examples: {
    backgroundColor: 'rgba(0, 0, 0, 0.1)'
  }
}));

enum testUserStateEnum {
  pass = 'pass',
  fail = 'fail'
}

const ConfigureAuthUser: React.FC<Props> = ({ open, toggleOpen, setOpenConfirmation, setType, updateLoginEndpointUrl, refresh }) => {
  const classes = useStyles();
  const { appInContext } = useContext<AppContextModel>(AppContext);
  const [testUserState, setTestUserState] = useState<testUserStateEnum | null>(null);

  const {
    control,
    reset,
    handleSubmit,
    watch,
    formState: { errors, isValid }
  } = useForm<MonitoringAuth>({
    defaultValues: useMemo(() => {
      const loginEndpoint = appInContext.apiEndpoints?.find(endpoint => endpoint.endpointId === appInContext.monitoringAuth?.loginEndpointId);
      if (loginEndpoint && appInContext.monitoringAuth) {
        appInContext.monitoringAuth.loginUrl = loginEndpoint.url;
      }
      return appInContext.monitoringAuth;
    }, [appInContext]),
    mode: 'onChange'
  });

  useEffect(() => {
    reset(appInContext.monitoringAuth);
  }, [appInContext.monitoringAuth, reset]);

  const onSubmit: SubmitHandler<MonitoringAuth> = async formValues => {
    const requestState: MonitoringAuthRequest = {
      appId: appInContext.appId,
      ...formValues
    };
    const showSnackbarError = () => {
      setOpenConfirmation(true);
      setType('error');
      setTestUserState(null);
    };
    try {
      const response = await postAppAuthUser(requestState);
      console.log(response);
      if (response.status === 200) {
        appInContext.monitoringAuth = formValues;
        if (!appInContext.monitoringAuth?.loginEndpointId) {
          refresh();
        }
        updateLoginEndpointUrl(formValues.loginUrl);
        setOpenConfirmation(true);
        setType('success');
        toggleOpen();
        setTestUserState(null);
      } else {
        const jsonBody = await response.json();
        console.error(jsonBody);
        showSnackbarError();
      }
    } catch (e) {
      console.error(e);
      showSnackbarError();
    }
  };

  const handleCancel = (): void => {
    reset();
    toggleOpen();
    setTestUserState(null);
  };

  const deleteUserConfig = async (): Promise<void> => {
    const requestState: DeleteApiMonitoringAuth = {
      appId: appInContext.appId
    };
    try {
      await deleteAppAuthUser(requestState);
      appInContext.monitoringAuth = {
        login: '',
        loginKey: '',
        loginUrl: '',
        loginEndpointId: '',
        password: '',
        passwordKey: '',
        tokenName: '',
        customParameter1: '',
        customValue1: '',
        customParameter2: '',
        customValue2: '',
        contentType: '',
        authTokenId: ''
      };
      setOpenConfirmation(true);
      setType('success');
      toggleOpen();
      setTestUserState(null);
    } catch (e) {
      console.error(e);
      setOpenConfirmation(true);
      setType('error');
      setTestUserState(null);
    }
  };

  const handleTestUserConfig = async () => {
    const formValues = watch();
    const requestState: MonitoringAuthRequest = {
      appId: appInContext.appId,
      ...formValues
    };
    try {
      const authConfigResponse = await testAuthUserConfig(requestState);
      console.log('authConfigResponse', authConfigResponse);
      setTestUserState(authConfigResponse.success ? testUserStateEnum.pass : testUserStateEnum.fail);

      setType('success');
    } catch (e) {
      console.error(e);
      setOpenConfirmation(true);
      setType('error');
    }
  };

  return (
    <Modal
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      className={classes.modal}
      open={open}
      onClose={handleCancel}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Fade in={open}>
        <form className={classes.paper} onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.formTitle}>
            <Typography variant="h5">Configure Auth User</Typography>
          </div>

          <Typography className={classes.note}>
            If you wish to configure an authorised user to test the GET endpoints configured for app {appInContext.appName} please enter the login url
          </Typography>

          <Controller
            name="loginUrl"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                className={classes.textField}
                label="Request Url"
                variant="outlined"
                fullWidth
                margin="dense"
                helperText={errors.loginUrl ? <span>The Login Url is required</span> : null}
                {...field}
              />
            )}
          />

          <Typography className={classes.note}>
            Add the parameter key that the login request uses with the login value. <br />
            E.g. parameter name could be <span className={classes.examples}>email</span> and the login{' '}
            <span className={classes.examples}>it@apadmi.com</span>
          </Typography>

          <span className={classes.inlineFieldWrapper}>
            <Controller
              name="loginKey"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  className={classes.textField}
                  label="Login param name"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  helperText={errors.loginKey ? <span>Please add your login key</span> : null}
                  {...field}
                />
              )}
            />

            <Controller
              name="login"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  className={classes.textField}
                  label="Login Info"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  helperText={errors.login ? <span>Please add your login username / email</span> : null}
                  {...field}
                />
              )}
            />
          </span>

          <Typography className={classes.note}>
            Add the parameter key that the password request uses with the password value. Examples for the param name could be{' '}
            <span className={classes.examples}>password</span> or <span className={classes.examples}>auth</span>
          </Typography>
          <span className={classes.inlineFieldWrapper}>
            <Controller
              name="passwordKey"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  className={classes.textField}
                  label="Password param name"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  {...field}
                  helperText={errors.passwordKey ? <span>Please add your password key</span> : null}
                />
              )}
            />

            <Controller
              name="password"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  className={classes.textField}
                  label="Password"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  type="password"
                  {...field}
                  helperText={errors.passwordKey ? <span>Please add your password value</span> : null}
                />
              )}
            />
          </span>

          <Typography className={classes.note}>If required you can add additional custom parameters to the login request</Typography>

          <span className={classes.inlineFieldWrapper}>
            <Controller
              name="customParameter1"
              control={control}
              defaultValue=""
              rules={{ required: false }}
              render={({ field }) => (
                <TextField
                  className={classes.textField}
                  label="Custom Param"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  {...field}
                  helperText={errors.customParameter1 ? <span>Please add your custom Param here</span> : null}
                />
              )}
            />

            <Controller
              name="customValue1"
              control={control}
              defaultValue=""
              rules={{ required: false }}
              render={({ field }) => (
                <TextField
                  className={classes.textField}
                  label="Custom Value"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  {...field}
                  helperText={errors.customValue1 ? <span>Please add your custom value here</span> : null}
                />
              )}
            />
          </span>

          <span className={classes.inlineFieldWrapper}>
            <Controller
              name="customParameter2"
              control={control}
              defaultValue=""
              rules={{ required: false }}
              render={({ field }) => (
                <TextField
                  className={classes.textField}
                  label="Custom Param"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  {...field}
                  helperText={errors.customParameter2 ? <span>Please add your custom parameter here</span> : null}
                />
              )}
            />

            <Controller
              name="customValue2"
              control={control}
              defaultValue=""
              rules={{ required: false }}
              render={({ field }) => (
                <TextField
                  className={classes.textField}
                  label="Custom Value"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  {...field}
                  helperText={errors.customValue2 ? <span>Please add your custom value here</span> : null}
                />
              )}
            />
          </span>

          <Typography className={classes.note}>
            Add the Auth token name. E.g. <span className={classes.examples}>id_token</span> or <span className={classes.examples}>access_token</span> etc
            and the token key E.g <span className={classes.examples}>Bearer</span>
          </Typography>
          <span className={classes.inlineFieldWrapper}>
            <Controller
              name="tokenName"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  className={classes.textField}
                  label="Token name"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  {...field}
                  helperText={errors.tokenName ? <span>Please add your token name</span> : null}
                />
              )}
            />

            <Controller
              name="authTokenId"
              control={control}
              defaultValue=""
              rules={{ required: false }}
              render={({ field }) => (
                <TextField
                  className={classes.textField}
                  label="Auth Token id"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  {...field}
                  helperText={errors.authTokenId ? <span>Please add your auth Token Id</span> : null}
                />
              )}
            />
          </span>

          <Typography className={classes.note}>Select the content type</Typography>

          <Controller
            name="contentType"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field }) => (
              <Select className={classes.textField} margin="dense" fullWidth label="Content Type" variant="outlined" {...field}>
                {Object.values(ApiContentTypeEnum).map((contentType: string, index) => (
                  <MenuItem key={index} value={contentType}>
                    {contentType}
                  </MenuItem>
                ))}
              </Select>
            )}
          />

          <div className={classes.bottomButtons}>
            <Button disabled={!isValid} onClick={deleteUserConfig} className={classes.button}>
              Delete
            </Button>
            <Button disabled={!isValid} onClick={handleTestUserConfig} className={classes.button}>
              Test Config
            </Button>

            {testUserState === testUserStateEnum.pass && <Typography className={clsx(classes.testState, classes.testStatePass)}>Success</Typography>}
            {testUserState === testUserStateEnum.fail && <Typography className={clsx(classes.testState, classes.testStateFail)}>Failed</Typography>}
            <span className={classes.bottomButtonsLayout}>
              <Button type="reset" onClick={handleCancel} className={classes.button}>
                Cancel
              </Button>
              <Button disabled={!isValid} type="submit" className={classes.button}>
                Submit
              </Button>
            </span>
          </div>
        </form>
      </Fade>
    </Modal>
  );
};

export default ConfigureAuthUser;
