import { Company, CreateCompanyRequest } from '../components/Manage/Models/CompanyModel';
import { HttpMethodsEnum } from '../models/enums/httpMethodsEnum';
import { LocalStorageItemEnum } from '../models/enums/localStorageItemEnum';

const { REACT_APP_BACKEND_API_KEY, REACT_APP_BACKEND_API_URL } = process.env;

const getOptions = (method: string) => {
  const idToken = localStorage.getItem(LocalStorageItemEnum.idToken);
  const options = {
    method: `${method}`,
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': REACT_APP_BACKEND_API_KEY,
      Authorization: idToken
    }
  };

  return options as any;
};

const getCompanyList = (): Promise<Response> => {
  const options = getOptions(HttpMethodsEnum.get);
  return fetch(`${REACT_APP_BACKEND_API_URL}/companies`, options);
};

const getCompany = (companyId: string): Promise<Response> => {
  const options = getOptions(HttpMethodsEnum.get);
  return fetch(`${REACT_APP_BACKEND_API_URL}/companies?companyId=${companyId}`, options)
}

const createCompany = (companyDetails: CreateCompanyRequest) => {
  const options = getOptions(HttpMethodsEnum.post);
  return fetch(`${REACT_APP_BACKEND_API_URL}/companies`, { ...options, body: JSON.stringify(companyDetails) })
};

const updateCompany = (companyDetails: Company) => {
  const options = getOptions(HttpMethodsEnum.patch);
  return fetch(`${REACT_APP_BACKEND_API_URL}/companies`, { ...options, body: JSON.stringify(companyDetails) }).then(response => {
    if (!response.ok) return { status: response.status };
    return response.json().then(data => ({ status: response.status, data }));
  });
};

const deleteCompany = (companyId: string) => {
  const options = getOptions(HttpMethodsEnum.delete);
  return fetch(`${REACT_APP_BACKEND_API_URL}/companies`, { ...options, body: JSON.stringify({ companyId }) }).then(response => response.status);
};

export { getCompanyList, createCompany, updateCompany, deleteCompany, getCompany };
