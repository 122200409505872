import React, { useState, useEffect, useContext } from 'react';
import { Grid, Button, makeStyles } from '@material-ui/core';
import { getCompanyList } from '../../api-queries/companyManagement';
import CompanyList from './companyList';
import PageTitle from '../PageTitle';
import { Company } from './Models/CompanyModel';
import Paginator from '../common/Paginator';
import CreateCompany from './createCompany';
import CreateAccountHolder from './createAccountHolder';
import { UserContext } from '../../contexts/UserContext';
import { minimumAccessRequirements } from '../../utils/userLevelEnum';

const useStyles = makeStyles(() => ({
  buttonFlex: {
    display: 'flex',
    flexDirection: 'column'
  },
  buttonWidth: {
    maxWidth: '15%',
    marginTop: '0.5%',
    marginLeft: '2.5%'
  }
}));

export default function Create() {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [addCompany, setAddCompany] = useState<boolean>(false);
  const [addAccountHolder, setAddAccountHolder] = useState<boolean>(false);
  // Paginator
  const [page, setPage] = useState<number>(0);
  const [rows, setRows] = useState<number>(10);

  const classes = useStyles();
  const { loggedInUser } = useContext<any>(UserContext);

  const toggleAddCompany = (): void => {
    setAddCompany(!addCompany);
  };

  const toggleAddAccountHolder = (): void => setAddAccountHolder(!addAccountHolder);

  const fetchCompanies = async (): Promise<void> => {
    try {
      const response = await getCompanyList();
      const data = await response.json();
      if (response.status === 200) {
        setCompanies(data.companies);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fetchCompanies();
  }, [setCompanies]);

  return (
    <Grid container spacing={3}>
      <Grid item>
        <PageTitle text={'Company Management'} />
      </Grid>
      <Grid item xs={12}>
        {companies && companies.length ? (
          <CompanyList companies={companies.slice(rows * page, rows * page + rows)} setCompanies={setCompanies} fullCompanies={companies} />
        ) : null}
        <div className={classes.buttonFlex}>
          {loggedInUser.userLevel === minimumAccessRequirements.companyManage && (
            <Button className={classes.buttonWidth} onClick={toggleAddCompany}>
              Add Company
            </Button>
          )}
          {loggedInUser.userLevel === minimumAccessRequirements.addUser && (
            <Button className={classes.buttonWidth} onClick={toggleAddAccountHolder}>
              Add Account Holder
            </Button>
          )}
        </div>
        <CreateAccountHolder open={addAccountHolder} handleClose={toggleAddAccountHolder} />
        {companies && companies.length ? <Paginator totalItems={companies.length} page={page} setPage={setPage} rows={rows} setRows={setRows} /> : null}
        <CreateCompany open={addCompany} companies={companies} setCompanies={setCompanies} handleClose={toggleAddCompany} />
      </Grid>
    </Grid>
  );
}
