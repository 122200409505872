import { makeStyles, Card } from '@material-ui/core';
import React, { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';
import { convertTimestamp } from '../../utils/convertTimestamp';
import { FailedReportsOverview } from './Interface/ApiMonitoringResponse';

const useStyles = makeStyles(theme => ({
  card: {
    padding: '5px',
    marginBottom: '20px'
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  header: {
    backgroundColor: '#f4984f',
    color: 'white'
  },
  boldText: { fontWeight: 700 },
  headerItem: {
    fontWeight: 700
  },
  overviewColumn: {
    display: 'inline-block',
    width: '50%'
  },
  borderRight: {
    borderRight: '1px solid white'
  },
  paddingLeft: {
    paddingLeft: '5px'
  },
  cellParent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingRight: '10px'
  },
  collapseBlock: {
    paddingTop: '10px'
  },
  statusCodeBreakdown: {
    width: '100%',
    height: '20px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}));

interface Props {
  data: FailedReportsOverview;
}

export const FailedReportsHeader = (props: Props) => {
  const data = props.data;
  const classes = useStyles();

  const { appInContext } = useContext(AppContext);

  const statusCodes = Object.keys(data.statusCodeBreakdown);
  statusCodes.map(statusCode => console.log(data.statusCodeBreakdown[statusCode]));

  const headerNonGrid = (
    <div className={classes.container}>
      <div className={`${classes.overviewColumn} + ${classes.borderRight}`}>
        <div className={classes.cellParent}>
          <span className={classes.headerItem}>Earliest Failure: </span>
          {convertTimestamp(data.earliestFailure)}
        </div>
        <div className={classes.cellParent}>
          <span className={classes.headerItem}>Maximum Response Time: </span>
          {data.maxResponseTime.toFixed(2)}ms
        </div>
        <div className={classes.cellParent}>
          <span className={classes.headerItem}>Minimum Response Time: </span> {data.minResponseTime.toFixed(2)}ms
        </div>
        <div className={classes.cellParent}>
          <span className={classes.headerItem}>Average Response Time: </span>
          {data.avgResponseTime.toFixed(2)}ms
        </div>
      </div>
      <div className={`${classes.overviewColumn} + ${classes.paddingLeft}`}>
        <div className={classes.cellParent}>
          <span className={classes.headerItem}>Latest Failure: </span>
          {convertTimestamp(data.latestFailure)}
        </div>
        <div>
          <span className={classes.headerItem}>Number of Timeouts: </span>
          {data.timeouts}
        </div>
        <div>
          <span className={classes.headerItem}>Polling Interval: </span>
          {appInContext.monitoringConfig?.monitoringRate}m
        </div>
        <div className={classes.statusCodeBreakdown}>
          {statusCodes.length > 0 ? (
            statusCodes.map((key, index) => (
              <>
                <span className={classes.headerItem}>{key}: </span>
                {data.statusCodeBreakdown[key]}
                {index < statusCodes.length - 1 && ', '}
              </>
            ))
          ) : (
            <span>-</span>
          )}
        </div>
      </div>
    </div>
  );

  return <Card className={`${classes.card} + ${classes.header}`}>{headerNonGrid}</Card>;
};
