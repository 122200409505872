import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

interface Props {
  title: string;
  colorClass: string;
  value: string;
}

const useStyles = makeStyles(theme => ({
  ragCard: {
    minWidth: '22%',
    padding: '8px',
    fontSize: '14px',
    borderRadius: '12px',
    display: 'flex',
    justifyContent: 'center',
    marginLeft: '10px',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      minWidth: '18%'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '6px',
      marginTop: '12px',
      '& p': {
        padding: '0px',
        margin: '0px'
      }
    }
  },
  ragCardText: {
    color: 'white',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px'
    }
  },
  leftBorder: {
    fontSize: '14px',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px'
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '24px',
      marginLeft: '-24px',
      borderLeft: '1px solid',
      borderColor: theme.palette.grey[300]
    },

    [theme.breakpoints.down('sm')]: {
      marginLeft: '0',
      fontSize: '12px',
      borderLeft: '0px transparent',
      marginTop: '12px'
    }
  }
}));

const DataCard: React.FC<Props> = ({ title, value, colorClass }) => {
  const classes = useStyles();

  return (
    <Grid item container xs={12} md={4} alignItems="center" justify="center">
      <Typography noWrap className={classes.leftBorder}>
        <strong>{title} </strong>
      </Typography>
      <div className={`${colorClass} + ${classes.ragCard}`}>
        <Typography noWrap className={classes.ragCardText}>
          {value}
        </Typography>
      </div>
    </Grid>
  );
};

export default DataCard;
