export default function areArraysShallowEqual<T extends any[]>(a: T, b: T): boolean {
  if (a.length !== b.length) {
    return false;
  }

  if (a.length === 0) {
    return true;
  }

  return a.every((value, i) => b[i] === value);
}
