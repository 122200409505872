import { Backdrop, Button, makeStyles, Modal, TextField, Typography } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { AddURL } from '../../api-queries/sslMonitoring';
import { AppContext } from '../../contexts/AppContext';
import ConfirmationSnackbar from '../common/Confirmation/confirmationSnackbar';

interface Props {
  open: boolean;
  onClose: () => void;
  update: (newUrl: string) => void;
  onSuccess: () => void;
}

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[0],
    padding: theme.spacing(3),
    outline: 'none',
    border: 'solid 1px rgba(35, 0, 30, 0.2)',
    borderRadius: '5px',
    '&:focus': {
      outline: 'none'
    },
    minWidth: '30vw'
  },
  title: {
    marginBottom: '16px'
  },
  textField: {
    display: 'block',
    width: '100%'
  },
  button: {
    marginRight: '2%',
    marginTop: '1rem'
  }
}));

const AddUrl: React.FC<Props> = ({ open, onClose, update, onSuccess }) => {
  const classes = useStyles();

  const [newUrl, setNewUrl] = useState('');
  const { appInContext } = useContext(AppContext);

  //Confirmation Snackbar State
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [type, setType] = useState<string>();
  const [errorText, setErrorText] = useState<string>('Something went wrong');

  const validateInput = () => {
    let url = newUrl.trim();
    if (url.startsWith('www.') || url.startsWith('https://www.')) {
      return false;
    } else {
      return true;
    }
  };

  const checkIfUrlAlreadyExists = () => {
    let url = newUrl.trim();
    if (!url.startsWith('https://')) {
      url = 'https://' + url;
    }
    let currentUrls = appInContext.sslInformation!.urls;
    if (currentUrls.find(item => item.url == url)) {
      return true;
    }
    return false;
  };

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    let validInput = validateInput();
    if (!validInput) {
      setOpenConfirmation(true);
      setErrorText('Please ensure the URL does not start with www.');
      setType('error');
      return;
    }
    let urlAlreadyExists = checkIfUrlAlreadyExists();
    if (urlAlreadyExists) {
      setOpenConfirmation(true);
      setErrorText('URL already exists');
      setType('error');
      return;
    }
    try {
      const res = await AddURL(newUrl, appInContext.appId);
      const jsonRes = await res.json();
      if (res.status == 200) {
        appInContext.sslInformation!.urls.push(jsonRes.sslUrl);
        onClose();
        update(jsonRes.sslUrl.url);
        onSuccess();
        setNewUrl('');
      } else {
        setOpenConfirmation(true);
        setErrorText(jsonRes.errorMessage.message);
        setType('error');
      }
    } catch (e) {
      throw new Error(e);
    }
  };

  const handleClear = () => {
    setNewUrl('');
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <form className={classes.paper} onSubmit={handleSubmit}>
        <Typography variant="h4" className={classes.title}>
          Add a new URL
        </Typography>
        <TextField
          required
          onChange={e => setNewUrl(e.currentTarget.value)}
          className={classes.textField}
          id="outlined-basic"
          label="URL"
          variant="outlined"
          fullWidth
          margin="dense"
          value={newUrl}
        />
        <Button type="submit" className={classes.button}>
          Submit
        </Button>
        <Button type="reset" onClick={handleClear} className={classes.button}>
          Cancel
        </Button>
        <ConfirmationSnackbar open={openConfirmation} setOpen={setOpenConfirmation} type={type} text={errorText} />
      </form>
    </Modal>
  );
};

export default AddUrl;
