import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Backdrop, Button, Fade, makeStyles, Modal, TextField, Typography } from '@material-ui/core';
import { editApiMonitoringRateAndAlerts } from '../../api-queries/appManagement';
import { ValidateEmail, ValidateUrl } from '../../utils/helpers';
import { AlertsData, EditMonitoringRateAndAlertsRequest } from './Interface/AlertsData';
import { AppContext } from '../../contexts/AppContext';
import { Controller, useForm } from 'react-hook-form';
import areArraysShallowEqual from '../../utils/areArraysShallowEqual';
import clsx from 'clsx';

interface Props {
  open: boolean;
  onOpenConfirmationSnackbar: (type: string) => void;

  onClose(): void;
}

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[0],
    padding: theme.spacing(3),
    outline: 'none',
    border: 'solid 1px rgba(35, 0, 30, 0.2)',
    borderRadius: '5px',
    '&:focus': {
      outline: 'none'
    },
    maxWidth: '500px',
    maxHeight: '95%',
    overflowY: 'auto',
  },
  formTitle: {
    marginBottom: '5%'
  },
  button: {
    marginRight: '2%'
  },
  textField: {
    display: 'block',
    width: '100%'
  },
  input: {
    width: '100%',
    height: '30pX'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    display: 'block'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  select: {
    width: '100%'
  },
  note: {
    marginTop: '15px',
    marginBottom: '15px'
  },
  status: {
    marginTop: '15px',
    marginBottom: '15px',
    color: 'green',
    display: 'inline-block'
  },
  statusActive: {
    color: 'green'
  },
  statusInactive: {
    marginTop: '15px',
    marginBottom: '15px',
    display: 'inline-block'
  },
  bottomButtons: {
    marginTop: '10px'
  },
  horizontalRule: {
    border: `1px solid ${theme.palette.divider}`,
  },
  consecutiveFailureRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  consecutiveFailureSecondField: {
    marginLeft: theme.spacing(1),
  },
}));

interface MonitoringAndAlertsFields {
  monitoringRate: string;
  webhookUrl: string;
  emails: string;
  consecutiveWarningThreshold: string;
  consecutiveCriticalThreshold: string;
}

const defaultMonitoringRate = 10;
const defaultWarningThreshold = 3;
const defaultCriticalThreshold = 5;

const thresholdMin = 1;
const thresholdMax = 10;

const monitoringRateMin = 1;
const monitoringRateMax = 30;

const ConfigureMonitoringRateAndAlerts: React.FC<Props> = ({ open, onClose, onOpenConfirmationSnackbar }) => {
  const classes = useStyles();

  const { appInContext, setAppInContext } = useContext(AppContext);
  const [activeTogglesInProgress, setActiveTogglesInProgress] = useState(0);

  const initialFieldValues = useMemo((): MonitoringAndAlertsFields => ({
    monitoringRate: (appInContext.monitoringConfig?.monitoringRate ?? defaultMonitoringRate).toString(),
    webhookUrl: appInContext.alertsData?.slackAlerts.slackWebhookUrl ?? '',
    emails: appInContext.alertsData?.emailAlerts.emails.join('\n') ?? '',
    consecutiveWarningThreshold: (
      appInContext.consecutiveFailureAlertRules?.find(r => r.alertName === 'Warning')?.threshold ?? defaultWarningThreshold
    ).toString(),
    consecutiveCriticalThreshold: (
      appInContext.consecutiveFailureAlertRules?.find(r => r.alertName === 'Critical')?.threshold ?? defaultCriticalThreshold
    ).toString(),
  }), [appInContext]);

  const {
    control,
    handleSubmit,
    clearErrors,
    trigger,
    reset,
    formState: {isValid, isDirty, isSubmitting},
  } = useForm<MonitoringAndAlertsFields>({
    mode: 'onChange',
  });

  useEffect(() => {
    if (open) {
      reset();
      clearErrors();
      trigger();
    }
  }, [open]);

  function isEmailListValid(value: string): boolean {
    const emailValues = value.split('\n').map(e => e.trim());

    return (
      value === '' ||
      (
        emailValues.length <= 10 &&
        emailValues.every(e => ValidateEmail(e))
      )
    );
  }

  function isWebhookUrlValid(value: string): boolean {
    return value === '' || ValidateUrl(value);
  }

  function isValidInteger(value: string): true | string {
    // Note: parseFloat rather than parseInt is intentional.
    // parseInt silently strips away fractions e.g. 1.5 - we want to
    // know about fractions in the value so we can display an error for them.
    const numberValue = Number.parseFloat(value);
    if (Number.isNaN(numberValue) || !Number.isInteger(numberValue)) {
      return 'Please enter a number';
    }

    return true;
  }

  const handleCancel = (): void => {
    onClose();
  };

  const onSubmit = async (data: MonitoringAndAlertsFields, event?: React.BaseSyntheticEvent) => {
    event?.preventDefault();

    const emailValues = data.emails.split('\n').map(e => e.trim());

    const areEmailValuesEmpty = emailValues.length === 0 || emailValues.every(e => e === '');

    let slackAlertsActive: boolean;
    if (!data.webhookUrl) {
      slackAlertsActive = false;
    } else if (data.webhookUrl !== appInContext.alertsData?.slackAlerts.slackWebhookUrl) {
      slackAlertsActive = true;
    } else {
      // The value is non-empty and unchanged, so just keep the current value from app state
      slackAlertsActive = !!appInContext.alertsData?.slackAlerts.isActive;
    }

    let emailAlertsActive: boolean;
    if (areEmailValuesEmpty) {
      emailAlertsActive = false;
    } else if (
      !areArraysShallowEqual(emailValues, appInContext.alertsData?.emailAlerts.emails ?? [])
    ) {
      emailAlertsActive = true;
    } else {
      // The value is non-empty and unchanged, so just keep the current value from app state
      emailAlertsActive = !!appInContext.alertsData?.emailAlerts.isActive;
    }

    const updatedAlertsData: AlertsData = {
      ...appInContext.alertsData,
      slackAlerts: {
        ...appInContext.alertsData?.slackAlerts,
        slackWebhookUrl: data.webhookUrl,
        isActive: slackAlertsActive,
      },
      emailAlerts: {
        ...appInContext.alertsData?.emailAlerts,
        emails: emailValues,
        isActive: emailAlertsActive
      }
    };

    const editRequest: EditMonitoringRateAndAlertsRequest = {
      appId: appInContext.appId,
      alertsData: updatedAlertsData,
      monitoringRate: Number.parseInt(data.monitoringRate),
      warningThreshold: Number.parseInt(data.consecutiveWarningThreshold),
      criticalThreshold: Number.parseInt(data.consecutiveCriticalThreshold),
    };

    try {
      const response = await editApiMonitoringRateAndAlerts(editRequest);

      onOpenConfirmationSnackbar('success');
      setAppInContext(response.app);
      onClose();
    } catch (e) {
      onOpenConfirmationSnackbar('error');
    }
  };

  const onClickToggleSlackAlerts = () => {
    if (!appInContext.alertsData) {
      return;
    }

    updateAlertStatus({
      ...appInContext.alertsData,
      slackAlerts: {
        ...appInContext.alertsData.slackAlerts,
        isActive: !appInContext.alertsData.slackAlerts.isActive,
      }
    });
  };

  const onClickToggleEmailAlerts = () => {
    if (!appInContext.alertsData) {
      return;
    }

    updateAlertStatus({
      ...appInContext.alertsData,
      emailAlerts: {
        ...appInContext.alertsData.emailAlerts,
        isActive: !appInContext.alertsData.emailAlerts.isActive
      }
    });
  };

  const updateAlertStatus = async (updatedAlertsData: AlertsData) => {
    const editRequest: EditMonitoringRateAndAlertsRequest = {
      appId: appInContext.appId,
      alertsData: updatedAlertsData,
      monitoringRate: appInContext.monitoringConfig?.monitoringRate ?? defaultMonitoringRate,
      warningThreshold: appInContext.consecutiveFailureAlertRules?.find(r => r.alertName === 'Warning')?.threshold ?? defaultWarningThreshold,
      criticalThreshold: appInContext.consecutiveFailureAlertRules?.find(r => r.alertName === 'Critical')?.threshold ?? defaultCriticalThreshold,
    };

    try {
      setActiveTogglesInProgress(prev => prev + 1);
      const response = await editApiMonitoringRateAndAlerts(editRequest);

      onOpenConfirmationSnackbar('success');
      setAppInContext(response.app);
    } catch (e) {
      onOpenConfirmationSnackbar('error');
    } finally {
      setActiveTogglesInProgress(prev => prev - 1);
    }
  };

  const asyncOperationPending = activeTogglesInProgress > 0 || isSubmitting;

  const renderAlertToggle = (isActive: boolean, buttonHandler: () => void) => (
    <div>
      <Button className={classes.button} onClick={buttonHandler} disabled={asyncOperationPending}>
        {isActive ? 'Pause' : 'Resume'}
      </Button>
      <Typography className={isActive ? `${classes.status} ${classes.statusActive}` : classes.statusInactive}>
        {isActive ? 'Alerts Active' : 'Alerts Inactive'}
      </Typography>
    </div>
  );

  return (
    <Modal
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      className={classes.modal}
      open={open}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Fade in={open}>
        <form className={classes.paper} onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.formTitle}>
            <Typography variant="h5">Configure Monitoring Rate &amp; Alerts</Typography>
          </div>

          <Typography variant="h6">API Monitoring Rate</Typography>
          <Typography className={classes.note}>
            Please enter how often you want monitoring requests to be sent to your API endpoints.
            The rate can be between {monitoringRateMin} and {monitoringRateMax} minutes
          </Typography>
          <Controller
            control={control}
            name='monitoringRate'
            rules={{
              required: 'Please specify a value',
              validate: isValidInteger,
              min: {
                value: monitoringRateMin,
                message: `The monitoring rate must be between ${monitoringRateMin} and ${monitoringRateMax}`,
              },
              max: {
                value: monitoringRateMax,
                message: `The monitoring rate must be between ${monitoringRateMin} and ${monitoringRateMax}`,
              }
            }}
            defaultValue={initialFieldValues.monitoringRate}
            render={({
              field: { onBlur, onChange, value, ref, name },
              fieldState: { invalid, error }
            }) =>
              <TextField
                type="number"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                name={name}
                error={invalid}
                disabled={asyncOperationPending}
                id="outlined-basic"
                label="Monitoring Rate (minutes)"
                variant="outlined"
                fullWidth
                margin="dense"
                className={classes.textField}
                helperText={error?.message}
              />
            }
          />
          <hr className={classes.horizontalRule} />

          <Typography variant="h6">Consecutive Failure Alerts</Typography>
          <Typography className={classes.note}>
            The number of consecutive failed API calls that should trigger a warning or critical alert.
            Consecutive failures are tracked on a per-endpoint basis.
          </Typography>
          <div className={classes.consecutiveFailureRow}>
            <Controller
              control={control}
              name='consecutiveWarningThreshold'
              rules={{
                validate: isValidInteger,
                required: 'Please specify a value',
                min: {
                  value: thresholdMin,
                  message: `Must be between ${thresholdMin} and ${thresholdMax}`,
                },
                max: {
                  value: thresholdMax,
                  message: `Must be between ${thresholdMin} and ${thresholdMax}`,
                },
              }}
              defaultValue={initialFieldValues.consecutiveWarningThreshold}
              render={({
                field: { onBlur, onChange, value, ref, name },
                fieldState: { invalid, error }
              }) =>
                <TextField
                  type="number"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  inputRef={ref}
                  name={name}
                  error={invalid}
                  disabled={asyncOperationPending}
                  id="outlined-basic"
                  label="Warning Threshold"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  className={classes.textField}
                  helperText={error?.message}
                />
              }
            />
            <Controller
              control={control}
              name='consecutiveCriticalThreshold'
              rules={{
                validate: isValidInteger,
                required: 'Please specify a value',
                min: {
                  value: thresholdMin,
                  message: `Must be between ${thresholdMin} and ${thresholdMax}`,
                },
                max: {
                  value: thresholdMax,
                  message: `Must be between ${thresholdMin} and ${thresholdMax}`,
                },
              }}
              defaultValue={initialFieldValues.consecutiveCriticalThreshold}
              render={({
                field: { onBlur, onChange, value, ref, name },
                fieldState: { invalid, error }
              }) => 
                <TextField
                  type="number"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  inputRef={ref}
                  name={name}
                  error={invalid}
                  disabled={asyncOperationPending}
                  id="outlined-basic"
                  label="Critical Threshold"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  className={clsx(classes.textField, classes.consecutiveFailureSecondField)}
                  helperText={error?.message}
                />
              }
            />
          </div>
          <hr className={classes.horizontalRule} />

          <Typography variant="h6">Slack Alerts</Typography>
          <Typography className={classes.note}>
            If you wish to have alerts sent to a Slack channel when an endpoint fails, please enter a Slack webhook URL below
          </Typography>
          <Controller
            control={control}
            name='webhookUrl'
            rules={{
              validate: isWebhookUrlValid,
            }}
            defaultValue={initialFieldValues.webhookUrl}
            render={({
              field: { onBlur, onChange, value, ref, name },
              fieldState: { invalid }
            }) =>
              <TextField
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                name={name}
                error={invalid}
                disabled={asyncOperationPending}
                id="outlined-basic"
                label="Slack Webhook URL"
                variant="outlined"
                fullWidth
                margin="dense"
                multiline
                className={classes.textField}
                helperText={invalid ? 'The Webhook URL looks incorrect' : ''}
              />
            }
          />
          {
            !!appInContext.alertsData &&
            appInContext.alertsData.slackAlerts.slackWebhookUrl.length > 0 &&
            renderAlertToggle(appInContext.alertsData.slackAlerts.isActive, onClickToggleSlackAlerts)
          }
          <hr className={classes.horizontalRule} />

          <Typography variant="h6">Email Alerts</Typography>
          <Typography className={classes.note}>
            If you wish to have alert emails sent when an endpoint fails, please enter at least one email address below
          </Typography>
          <Controller
            control={control}
            name='emails'
            rules={{
              validate: isEmailListValid,
            }}
            defaultValue={initialFieldValues.emails}
            render={({
              field: { onBlur, onChange, value, ref, name },
              fieldState: { invalid }
            }) =>
              <TextField
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                name={name}
                error={invalid}
                disabled={asyncOperationPending}
                id="outlined-basic"
                label="Email group"
                variant="outlined"
                fullWidth
                margin="dense"
                multiline
                className={classes.textField}
                helperText={
                  invalid
                    ? 'The email list provided looks incorrect (maximum 10 email addresses)'
                    : 'For multiple emails, enter each on a new line (10 max)'
                }
              />
            }
          />
          {
            !!appInContext.alertsData &&
            appInContext.alertsData.emailAlerts.emails.length > 0 &&
            appInContext.alertsData.emailAlerts.emails[0] !== '' &&
            renderAlertToggle(appInContext.alertsData.emailAlerts.isActive, onClickToggleEmailAlerts)
          }
          <hr className={classes.horizontalRule} />

          <div className={classes.bottomButtons}>
            <Button type="submit" className={classes.button} disabled={!isValid || !isDirty || asyncOperationPending}>
              Submit
            </Button>
            <Button type="reset" onClick={handleCancel} className={classes.button}>
              Cancel
            </Button>
          </div>
        </form>
      </Fade>
    </Modal>
  );
};

export default ConfigureMonitoringRateAndAlerts;
