/* eslint-disable max-len */
import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function ApiMaxResTimeIcon(props) {
  return (
    <SvgIcon viewBox="0 0 55 46" width="55px" height="46px" titleAccess="API Maximum Response Time Icon" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M27.5,0.5 C34.9548286,0.5 41.7034849,3.5773143 46.5887124,8.55136413 C51.4771713,13.5287041 54.5,20.4050397 54.5,28 C54.5,34.5157277 52.2752715,40.5025289 48.5562603,45.2150081 C43.4244981,39.2586655 35.8931158,35.5 27.5,35.5 C19.1063842,35.5 11.5746005,39.259115 6.44222163,45.2137249 C2.72444516,40.501777 0.5,34.5153177 0.5,28 C0.5,20.4050397 3.5228287,13.5287041 8.4112876,8.55136413 C13.2965151,3.5773143 20.0451714,0.5 27.5,0.5 Z"
          id="Combined-Shape"
          stroke="#23001E"
          fill="#FFFFFF"
        ></path>
        <path
          d="M37.4677267,21.8418042 L37.4965556,21.8441089 L37.501195,21.854081 L38.4404864,38.3115547 C38.4483538,38.4494015 38.399603,38.5773873 38.3144236,38.6728783 C38.2316128,38.765714 38.1143711,38.8278368 37.9812506,38.8384491 L37.9812506,38.8384491 L37.0570976,38.8400452 C36.9190264,38.8400452 36.7940264,38.7840808 36.7035442,38.6935986 C36.6155835,38.605638 36.5602436,38.4850566 36.5572275,38.3515575 L36.5572275,38.3515575 L37.4722531,21.8533668 L37.34,21.924 L37.375039,21.900927 C37.4101814,21.8754335 37.443436,21.8501413 37.4677267,21.8418042 Z"
          id="Rectangle"
          stroke="#23001E"
          fill="#23001E"
          transform="translate(37.500000, 30.340045) rotate(100.000000) translate(-37.500000, -30.340045) "
        ></path>
        <circle id="Oval" stroke="#23001E" fill="#FFFFFF" cx="27.5" cy="28.5" r="3"></circle>
        <path
          d="M47.8006273,40.1270348 C50.2700328,35.9815481 51.3291124,31.264843 50.977866,25.9769195"
          id="Path-6"
          strokeOpacity="0.4"
          stroke="#23001E"
          strokeWidth="2"
        ></path>
        <path
          d="M44.2497463,23.1152953 C47.0334798,18.4912279 48.0904159,13.2418238 47.4205545,7.36708317"
          id="Path-6"
          strokeOpacity="0.4"
          stroke="#23001E"
          strokeWidth="2"
          transform="translate(45.932595, 15.241189) rotate(-45.000000) translate(-45.932595, -15.241189) "
        ></path>
        <path
          d="M4,40.1270348 C6.46940554,35.9815481 7.5284851,31.264843 7.17723869,25.9769195"
          id="Path-6"
          strokeOpacity="0.4"
          stroke="#23001E"
          strokeWidth="2"
          transform="translate(5.621424, 33.051977) scale(-1, 1) translate(-5.621424, -33.051977) "
        ></path>
        <path
          d="M7.19717127,22.9604274 C9.92734548,18.2579431 10.99642,13.2270323 10.4043948,7.86769517"
          id="Path-6"
          strokeOpacity="0.4"
          stroke="#23001E"
          strokeWidth="2"
          transform="translate(8.879908, 15.414061) scale(-1, 1) rotate(-45.000000) translate(-8.879908, -15.414061) "
        ></path>
        <path
          d="M24.7031156,15.9062881 C29.4744488,10.1698861 31.247591,3.6606726 30.0225421,-3.62135232"
          id="Path-6"
          strokeOpacity="0.4"
          stroke="#23001E"
          strokeWidth="2"
          transform="translate(27.550535, 6.142468) rotate(-105.000000) translate(-27.550535, -6.142468) "
        ></path>
      </g>
    </SvgIcon>
  );
}

export default ApiMaxResTimeIcon;
