import { useRef, useState } from "react";

export enum PromiseStatus {
  NotStarted,
  Pending,
  Resolved,
  Rejected,
}

interface PromiseWatchState<TValue> {
  status: PromiseStatus;
  value?: TValue;
  error?: any;
  firstTimeSettled: boolean;
}

interface PromiseWatchHook<TValue> extends PromiseWatchState<TValue> {
  /**
   * Watch a promise and track its lifecycle in the state object.
   * @param promise Promise to be watched
   * @param onResolve
   * A callback that runs when the promise resolves, but only if it's still the current promise.
   * Use this for modifying the data or applying side-effects.
   */
  watch(
    promise: Promise<TValue>,
    onResolve?: (value: TValue) => TValue,
  ): Promise<TValue | void>;
  resolve(
    value: TValue,
    onResolve?: (value: TValue) => TValue,
  ): void;
}

/**
 * This hook watches the lifecycle of promises passed to its 'watch function', and stores the info about the promise's lifecycle.
 * If the watch function is called more than once, only the most newly-watched promise takes effect, even if a previous promise resolves later.
 * 
 * A typical use case for this hook would be to display data from e.g. Fetch API:
 * - display a loading indicator when the promise is pending
 * - display the value when resolved
 * - display an error when the promise failed and there is no data to display
 * @returns {PromiseWatchHook<TValue>} Array of promise status and watch function
 */
export default function usePromiseWatcher<TValue>(): PromiseWatchHook<TValue> {
  const [state, setState] = useState<PromiseWatchState<TValue>>({
    status: PromiseStatus.NotStarted,
    firstTimeSettled: false,
  });
  const currentPromiseRef = useRef<Promise<TValue>>();

  function resolve(
    value: TValue,
    onResolve?: (value: TValue) => TValue,
  ) {
    setState(prevState => ({
      ...prevState,
      status: PromiseStatus.Resolved,
      value: onResolve ? onResolve(value) : value,
      firstTimeSettled: true,
    }));
  }

  function watch(
    promise: Promise<TValue>,
    onResolve?: (value: TValue) => TValue,
  ): Promise<TValue | void> {

    currentPromiseRef.current = promise;
    setState(prevState => ({
      ...prevState,
      status: PromiseStatus.Pending,
    }));

    return (
      promise
      .then(
        value => {
          if (currentPromiseRef.current === promise) {
            resolve(value, onResolve);
          }
          
          return value;
        },
        reason => {
          if (currentPromiseRef.current === promise) {
            setState(prevState => ({
              ...prevState,
              status: PromiseStatus.Rejected,
              error: reason,
              firstTimeSettled: true
            }));
          }
        }
      )
    );
  }

  return {
    ...state,
    watch,
    resolve,
  };
}
