import React, { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { Avatar, Drawer, List, ListItem, ListItemIcon, ListItemText, CssBaseline, makeStyles, MenuItem } from '@material-ui/core';
import { ApadmiPlus, ApadmiSmall } from '../../../../Icons';
import { UserContext } from '../../../../contexts/UserContext';
import { MenuItems } from '../Models/MenuItemModel';
import { NavigationModal } from '../NavigationModal';
import { AppContext } from '../../../../contexts/AppContext';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.complex
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.complex
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '15px'
  },
  toggleIcon: {
    margin: 'auto',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  bottomMenu: {
    position: 'absolute',
    bottom: 0,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  linkText: {
    color: '#000'
  },
  listItem: {
    margin: 'auto',
    paddingLeft: '10px'
  },
  menuItem: {
    padding: '5px'
  },
  avatar: {
    margin: 'auto',
    backgroundColor: '#f4984f'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  notSelected: {
    width: '100%',
    color: '#000000',
    borderRadius: '5px',
    textDecoration: 'none'
  },
  selected: {
    width: '100%',
    backgroundColor: '#00b173',
    color: '#FFFFFF',
    borderRadius: '5px',
    textDecoration: 'none'
  }
}));

interface Props {
  showMenu: boolean;
  menuItems: MenuItems[];
}

const DesktopNav: React.FC<Props> = ({ menuItems, showMenu }) => {
  const { loggedInUser } = useContext(UserContext);
  const { appInContext } = useContext(AppContext);
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const classes = useStyles();

  const toggleDrawer = (): void => {
    setOpen(!open);
  };

  const toggleModal = (): void => {
    setModal(!modal);
  };

  return (
    <div>
      <CssBaseline />
      <Drawer
        variant="permanent"
        open={open}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })
        }}
      >
        <div className={classes.container}>
          {!open ? (
            <ApadmiSmall className={classes.toggleIcon} onClick={toggleDrawer} />
          ) : (
            <ApadmiPlus className={classes.toggleIcon} onClick={toggleDrawer} />
          )}
        </div>
        {showMenu &&
          menuItems.map((option, idx) => (
            <MenuItem
              disableGutters
              className={classes.menuItem}
              key={idx}
              button
            >
              <NavLink
                to={`/app/${appInContext.appId}/${option.url}`}
                activeClassName={classes.selected}
                className={classes.notSelected}
              >
                <ListItem
                  className={classes.listItem}
                  disableGutters
                  component='div'
                >
                  <ListItemIcon>{option.icon}</ListItemIcon>
                  <ListItemText primary={option.name} />
                </ListItem>
              </NavLink>
            </MenuItem>
          ))
        }
        <List className={classes.bottomMenu}>
          <MenuItem disableGutters>
            <ListItem disableGutters onClick={toggleModal} component='div'>
              <ListItemIcon>
                <Avatar className={classes.avatar}>
                  {loggedInUser!.firstName.charAt(0)}
                  {loggedInUser!.lastName.charAt(0)}
                </Avatar>
              </ListItemIcon>
              <ListItemText className={classes.linkText}>
                {loggedInUser!.firstName} {loggedInUser!.lastName}
              </ListItemText>
            </ListItem>
          </MenuItem>
        </List>
      </Drawer>
      <NavigationModal open={modal} toggleModal={toggleModal} />
    </div>
  );
};

export default DesktopNav;
