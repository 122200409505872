import { LocalStorageItemEnum } from '../models/enums/localStorageItemEnum';

const { REACT_APP_BACKEND_API_KEY, REACT_APP_BACKEND_API_URL } = process.env;

const login = ({ email, password, setNewPasswordRequired, setUserError, setUser, setLoggedInUser, setProfileUpdateRequired }) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': REACT_APP_BACKEND_API_KEY
    },
    body: JSON.stringify({ email, password })
  };

  fetch(`${REACT_APP_BACKEND_API_URL}/login`, options)
    .then(response => {
      if (response.status === 404) {
        setUserError(true);
        throw new Error('User not found');
      }
      return response.json().then(data => ({ status: response.status, body: data }));
    })
    .then(obj => {
      const { status, body } = obj;
      if (status === 200 && body.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setUser({ email, body });
        setNewPasswordRequired(true);
      }
      if (status === 400) {
        setUserError(true);
      }
      if (status === 200 && body.accessToken) {
        localStorage.setItem('accessToken', body.accessToken);
        localStorage.setItem('idToken', body.idToken);
        localStorage.setItem('refreshToken', body.refreshToken);

        if (body.userDetails.profileUpdateRequired) {
          setProfileUpdateRequired(true);
        }

        setLoggedInUser(body.userDetails);
      }
    })
    .catch(e => {
      console.log(e.message);
    });
};

const challengeLogin = ({ user, newPassword, setLoggedInUser, setNewPasswordRequired, setUserError, setProfileUpdateRequired }) => {
  const { email } = user;
  const { session } = user.body;
  const options = {
    method: 'POST',
    'Access-Control-Request-Method': 'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': REACT_APP_BACKEND_API_KEY
    },
    body: JSON.stringify({ email, newPassword, session })
  };
  fetch(`${REACT_APP_BACKEND_API_URL}/login-challenge`, options)
    .then(response => response.json().then(data => ({ status: response.status, body: data })))
    .then(obj => {
      const { status, body } = obj;
      if (status === 400) {
        setUserError(true);
      }
      if (status === 200) {
        localStorage.setItem('accessToken', body.accessToken);
        localStorage.setItem('idToken', body.idToken);
        localStorage.setItem('refreshToken', body.refreshToken);
        setLoggedInUser(body.userDetails);
        setNewPasswordRequired(null);
        if (body.userDetails.profileUpdateRequired) {
          setProfileUpdateRequired(true);
        }
      }
    })
    .catch(e => {
      throw new Error(e.message);
    });
};

const authenticateUser = () => {};

const logout = setLoggedInUser => {
  const options = {
    method: 'GET',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${localStorage.getItem(LocalStorageItemEnum.accessToken)}`
    }
  };
  fetch(`${REACT_APP_BACKEND_API_URL}/logout`, options).then(response =>
    response
      .json()
      .then(() => {
        setLoggedInUser(null);
        localStorage.removeItem('idToken');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
      })
      .catch(e => {
        throw new Error(e.message);
      })
  );
};

export { login, challengeLogin, logout, authenticateUser };
