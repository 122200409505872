import { Container, Grid, Fade, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import AppCard from './AppCard/index';
import ProgressBar from '../common/ProgressBar';
import { fetchAppList } from '../../api-queries/appList';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    padding: theme.spacing(3),
    paddingTop: theme.spacing(12)
  }
}));

const AppList = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [apps, setApps] = useState();
  const [checked] = useState(true);

  useEffect(() => {
    const getApps = async () => {
      try {
        const response = await fetchAppList();
        const data = await response.json();
        if (response.status === 200) {
          setApps(data.apps);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (e) {
        console.error(e);
      }
    };
    getApps();
  }, []);

  return (
    <>
      {loading && <ProgressBar />}
      {!loading && apps && (
        <Container className={classes.root} maxWidth={false}>
          <Grid container spacing={3} direction="row" alignItems="flex-start">
            {apps.map((app, idx) => (
              <Fade key={idx} in={checked} {...(checked ? { timeout: 250 * (idx + 1) } : {})}>
                <AppCard app={app} />
              </Fade>
            ))}
          </Grid>
        </Container>
      )}
      {!loading && !apps && (
        <Container className={classes.root} maxWidth={false}>
          <Grid container spacing={3} direction="row" alignItems="flex-start"></Grid>
        </Container>
      )}
    </>
  );
};

export default AppList;
