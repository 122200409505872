export class ResponseNotOkError extends Error {
  constructor(public readonly response: Response) {
    super(`Fetch API response was not ok, with status: ${response.status} (${response.statusText})`);
  }
}

export function throwIfResponseNotOk(response: Response): Response {
  if (!response.ok) {
    const error = new ResponseNotOkError(response);
    console.error(error);
    throw error;
  }

  return response;
}
