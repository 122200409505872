import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Home from '../containers/Home';
import SignIn from '../components/auth/SignIn';
import NewPassword from '../components/auth/NewPassword';
import ResetPassword from '../components/auth/ResetPassword';
import NotFound from '../containers/404';

// Spread appProps to make them available to the rendered Component
function UnauthorisedRoute({ component: Component, appProps, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => {
        if (!appProps.loggedInUser && !appProps.newPasswordRequired) {
          return <Component {...props} {...appProps} />;
        }
        if (appProps.newPasswordRequired && !appProps.loggedInUser) {
          return <NewPassword {...props} {...appProps} />;
        }
        if (appProps.resetPassword) {
          return <ResetPassword {...props} {...appProps} />;
        }
        return <Redirect to="/" />;
      }}
    />
  );
}

// Spread appProps to make them available to the rendered Component
function AuthorisedRoute({ component: Component, appProps, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => {
        if (!appProps.loggedInUser && !appProps.newPasswordRequired) return <Redirect to="/signin" />;
        if (appProps.newPasswordRequired) return <Redirect to="/new-password" />;
        return <Component {...props} {...appProps} />;
      }}
    />
  );
}

export default function AuthRoutes({ appProps }) {
  return (
    <Switch>
      <UnauthorisedRoute path="/signin" component={SignIn} appProps={appProps} />
      <UnauthorisedRoute path="/new-password" component={NewPassword} appProps={appProps} />
      <UnauthorisedRoute path="/reset-password" component={ResetPassword} appProps={appProps} />
      <AuthorisedRoute path="/" component={Home} appProps={appProps} />
      <Route component={NotFound} />
    </Switch>
  );
}
