import { Modal, Backdrop, Typography, makeStyles, Button, Grid, Switch, TextField } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { EditOpsgenieConfig } from '../../api-queries/opsgenieConfig';
import { AppContext } from '../../contexts/AppContext';
import { AppContextModel } from '../../contexts/interfaces/appContextModel';
import ConfirmationSnackbar from '../common/Confirmation/confirmationSnackbar';

interface Props {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[0],
    padding: theme.spacing(3),
    outline: 'none',
    border: 'solid 1px rgba(35, 0, 30, 0.2)',
    borderRadius: '5px',
    '&:focus': {
      outline: 'none'
    },
    minWidth: '30vw'
  },
  title: {
    marginBottom: '16px'
  },
  bodyText: { marginBottom: '12px' },
  button: {
    marginTop: '16px',
    marginRight: '1.8%'
  },
  statusActive: {
    color: 'green'
  },
  statusInactive: {
    color: '#a33'
  },
  unsavedText: { marginTop: '16px', marginRight: '1.8%', color: 'red', fontWeight: 'bold' },
  textField: {
    display: 'block',
    width: '100%',
    marginTop: '22px',
    marginBottom: '22px'
  },
  toggleText: {
    fontSize: '18px',
    fontWeight: 600
  }
}));

const OpsgenieConfigModal: React.FC<Props> = ({ open, onClose, onSuccess }) => {
  const classes = useStyles();

  const { appInContext, setAppInContext } = useContext<AppContextModel>(AppContext);

  const [alertsEnabled, setAlertsEnabled] = useState(appInContext.opsgenieConfig!.alertsEnabled);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [newApiKey, setNewApiKey] = useState(appInContext.opsgenieConfig!.opsgenieApiKey);
  const initAlertStatus = appInContext.opsgenieConfig!.alertsEnabled;
  const initApiKey = appInContext.opsgenieConfig!.opsgenieApiKey;

  //Confirmation Snackbar State
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [type, setType] = useState<string>();
  const [errorText, setErrorText] = useState<string>('Something went wrong');

  useEffect(() => {
    if (initAlertStatus != alertsEnabled || initApiKey != newApiKey) {
      setUnsavedChanges(true);
    } else {
      setUnsavedChanges(false);
    }
  }, [alertsEnabled, newApiKey]);

  const handleOpsGenieToggle = () => {
    setAlertsEnabled(prev => !prev);
  };

  const handleClose = () => {
    setNewApiKey(initApiKey);
    setAlertsEnabled(initAlertStatus);
    setUnsavedChanges(false);
    onClose();
  };

  const handleSubmit = async () => {
    if (unsavedChanges) {
      try {
        const response = await EditOpsgenieConfig(newApiKey, alertsEnabled, appInContext.appId);
        const jsonRes = await response.json();
        if (response.status == 200) {
          setUnsavedChanges(false);
          setAppInContext({ ...appInContext, opsgenieConfig: jsonRes.opsgenieConfig });
          onClose();
          onSuccess();
        } else {
          setOpenConfirmation(true);
          setErrorText(jsonRes.errorMessage);
          setType('error');
        }
      } catch (e) {
        throw new Error(e);
      }
    } else {
      onClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <div className={classes.paper}>
        <Typography variant="h4" className={classes.title}>
          Configure Opsgenie Integration
        </Typography>
        <Typography variant="body1">
          Instructions on how to generate an API key can be found{' '}
          <a href="https://apadmi.atlassian.net/wiki/spaces/OpSuccess/pages/874545181/API+Monitoring+Apadmi+Integration+Guide" target="_blank">
            here
          </a>
        </Typography>
        <TextField
          required
          onChange={e => {
            setNewApiKey(e.target.value);
          }}
          className={classes.textField}
          id="outlined-basic"
          label="API Key"
          variant="outlined"
          fullWidth
          margin="dense"
          value={newApiKey}
        />{' '}
        <Typography variant="body1" className={classes.toggleText}>
          Opsgenie Alerting: <span className={alertsEnabled ? classes.statusActive : classes.statusInactive}>{alertsEnabled ? 'Enabled' : 'Disabled'}</span>
          <Switch checked={alertsEnabled} color="primary" onClick={handleOpsGenieToggle} />
        </Typography>
        <Grid item container alignItems="center" justify="flex-end" xs={12}>
          {unsavedChanges && (
            <Typography variant="body1" className={classes.unsavedText}>
              Unsaved Changes
            </Typography>
          )}
          <Button type="reset" onClick={handleClose} className={classes.button}>
            Cancel
          </Button>
          <Button type="submit" className={classes.button} onClick={handleSubmit}>
            Confirm
          </Button>
          <ConfirmationSnackbar open={openConfirmation} setOpen={setOpenConfirmation} type={type} text={errorText} />
        </Grid>
      </div>
    </Modal>
  );
};

export default OpsgenieConfigModal;
