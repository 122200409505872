import React, { useState, useContext } from 'react';
import { format, endOfDay, startOfDay, endOfToday } from 'date-fns';
import { Button, ClickAwayListener, makeStyles, useTheme, useMediaQuery } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { DateRangePicker } from 'react-date-range';
import { SelectionRangeContext } from '../../../contexts/SelectionRangeContext';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import './index.css'; // custom css file

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    marginTop: '10px'
  },
  dropdown: {
    position: 'absolute',
    top: 28,
    right: 0,
    zIndex: 1,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper
  }
}));
export default function DatePicker() {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
  const { selectionRange, setSelectionRange, setComparisonRange } = useContext(SelectionRangeContext);
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(prev => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const handleSelect = ranges => {
    const start = startOfDay(ranges.selection.startDate);
    const end = endOfDay(ranges.selection.endDate);
    const range = end - start;
    setSelectionRange({
      startDate: start,
      endDate: end,
      startDateMS: start.getTime(),
      endDateMS: end.getTime(),
      key: 'selection'
    });
    setComparisonRange({
      range,
      offset: range,
      startMS: start.getTime() - range,
      endMS: start.getTime()
    });
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={classes.root}>
        <Button onClick={handleClick}>
          {selectionRange.selection ? format(selectionRange.selection.startDate, 'do MMM, yy') : format(selectionRange.startDate, 'do MMM, yy')}
          &nbsp;-&nbsp;
          {selectionRange.selection ? format(selectionRange.selection.endDate, 'do MMM, yy') : format(selectionRange.endDate, 'do MMM, yy')}
          <DateRangeIcon />
        </Button>
        {open ? (
          <div className={classes.dropdown}>
            <div className={matchesSm ? 'mobile' : 'desktop'}>
              <DateRangePicker
                weekStartsOn={1}
                months={matchesSm ? 1 : 2}
                direction={matchesSm ? 'vertical' : 'horizontal'}
                ranges={[selectionRange]}
                showDateDisplay={true}
                onChange={handleSelect}
                maxDate={endOfToday()}
                startDatePlaceholder={
                  selectionRange.selection ? format(selectionRange.selection.startDate, 'do MMM, yy') : format(selectionRange.startDate, 'do MMM, yy')
                }
                endDatePlaceholder={
                  selectionRange.selection ? format(selectionRange.selection.endDate, 'do MMM, yy') : format(selectionRange.endDate, 'do MMM, yy')
                }
              />
            </div>
          </div>
        ) : null}
      </div>
    </ClickAwayListener>
  );
}

DatePicker.propTypes = {};

DatePicker.defaultProps = {};
