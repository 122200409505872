const convertPlatformReadable: Record<string, string> = {
	'android': 'Android',
  'ios': 'iOS'
};

const convertPlatformBackend: Record<string, string> = {
  'Android': 'android',
  'iOS': 'ios'
}

enum PlatformsReadable {
  android = 'Android',
  ios = 'iOS'
}

enum Platforms {
  android = 'android',
  ios = 'ios'
}

export {Platforms, convertPlatformBackend, PlatformsReadable, convertPlatformReadable};