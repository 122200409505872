import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  text: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: '32px',
    [theme.breakpoints.down('sm')]: {
      // scale font-size from a minimum of 18px (at a 320px viewport) to a maximum of 32px (at a 1000px viewport).
      // font-size: calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width])));
      fontSize: 'calc(18px + 14 * ((100vw - 320px) / 600))'
    }
  }
}));

export default function SubTitle({ text }) {
  const classes = useStyles();
  return <Typography className={classes.text}>{text}</Typography>;
}
