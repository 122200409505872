import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

// We confine this effect to a specific component, to avoid re-rendering App every time pathname changes
export default function ResetScrollOnPathChange() {
  const history = useHistory();

  const prevPathnameRef = useRef(history.location.pathname);
  useEffect(() => {
    const unlisten = history.listen(location => {
      if (location.pathname !== prevPathnameRef.current) {
        prevPathnameRef.current = location.pathname;
        window.scrollTo(0, 0);
      }
    });

    return () => {
      unlisten();
    };
  }, []);

  return null;
}
