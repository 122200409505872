import Prism, { Grammar } from 'prismjs';
import React from 'react';
import Editor from 'react-simple-code-editor';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css';
import { makeStyles, useTheme } from '@material-ui/core';

interface Props {
  value: string;
  onChange: (code: string) => void;
}

const useStyles = makeStyles(theme => ({
  editor: {
    marginTop: '1.5rem',
    maxHeight: '60vh',
    maxWidth: '80vw',
    overflow: 'scroll',
    counterReset: 'line',
    border: '1px solid #ced4da',
    '& pre': {
      paddingLeft: ' 60px !important'
    },
    '& .editorLineNumber': {
      position: 'absolute',
      left: '0px',
      color: '#cccccc',
      textAlign: 'right',
      width: '40px',
      fontWeight: '100'
    },
    '& #codeArea': {
      outline: 'none',
      paddingLeft: '60px !important'
    }
  }
}));

const NumberLinedTextArea: React.FC<Props> = ({ value, onChange }) => {
  const classes = useStyles();
  const theme = useTheme();

  const hightlightWithLineNumbers = (input: string, language: Grammar) =>
    Prism.highlight(input, language, 'javascript')
      .split('\n')
      .map((line: string, i: number) => `<span class='editorLineNumber'>${i + 1}</span>${line}`)
      .join('\n');

  return (
    <div className={classes.editor}>
      <Editor
        required
        placeholder="PASTE IN JSON"
        value={value}
        onValueChange={onChange}
        highlight={code => hightlightWithLineNumbers(code, Prism.languages.js)}
        padding={10}
        textareaId="codeArea"
        className="editor"
        style={{
          fontFamily: '"Fira code", "Fira Mono", monospace',
          fontSize: 18,
          outline: 0
        }}
      />
    </div>
  );
};

export default NumberLinedTextArea;
