const { REACT_APP_BACKEND_API_URL } = process.env;

const getOptions = method => {
  const options = {
    method: `${method}`,
    headers: {
      'Content-Type': 'application/json'
    }
  };
  return options;
};

const forgottenPasswordRequest = async email => {
  const baseOptions = getOptions('POST');
  const options = {
    ...baseOptions,
    body: JSON.stringify({ email })
  };
  return fetch(`${REACT_APP_BACKEND_API_URL}/forgotten-password-request`, options).then(response => {
    if (!response.ok) return { status: response.status };
    return response.json().then(data => ({ status: response.status, data }));
  });
};

const forgottenPasswordConfirmation = async (email, code, newPassword) => {
  const baseOptions = getOptions('POST');
  const options = {
    ...baseOptions,
    body: JSON.stringify({ email, code, newPassword })
  };
  return fetch(`${REACT_APP_BACKEND_API_URL}/forgotten-password-confirmation`, options).then(response => {
    if (!response.ok) return { status: response.status };
    return response.json().then(data => ({ status: response.status, data }));
  });
};

export { forgottenPasswordRequest, forgottenPasswordConfirmation };
