import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { EndpointData } from './Interface/EndpointData';
import { CheckCircle as CheckCircleIcon, Error as ErrorIcon } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    marginBottom: '12px',
    padding: theme.spacing(2, 3)
  },
  endpointNameLogo: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '30px',
      marginLeft: '0'
    },
    '& p': {
      [theme.breakpoints.up('sm')]: {
        marginLeft: '24px'
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: '0'
      }
    }
  },
  endpointDetails: {
    '& p': {
      [theme.breakpoints.up('sm')]: {
        paddingLeft: '24px',
        marginLeft: '-24px',
        borderLeft: '1px solid',
        borderColor: theme.palette.grey[300]
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: '0',
        paddingLeft: '32px',
        borderLeft: '0px transparent',
        marginTop: '12px'
      }
    }
  }
}));

const EndpointCard: React.FC<EndpointData> = ({ name, failing, method, url, failCount }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <Grid container item xs={12}>
        <Grid item container alignItems="center" xs={12} md={6} className={classes.endpointNameLogo}>
          {failing ? <ErrorIcon style={{ color: 'red' }} /> : <CheckCircleIcon style={{ color: 'green' }} />}
          <Typography noWrap>
            <strong>Name: {name}</strong>
          </Typography>
        </Grid>
        <Grid item container alignItems="center" xs={12} md={6} className={classes.endpointDetails}>
          <Grid item xs={12} md={4}>
            <Typography>
              <strong>Method:</strong> {method}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography>
              <strong>Failure Count:</strong> {failCount}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography>
              <strong>URL:</strong> {url}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default EndpointCard;
