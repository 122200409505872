import React from 'react';
import { CardContent } from '@material-ui/core';

export default function NoData({ category }) {
  return (
    <CardContent>
      <h1>Currently no {category} to display</h1>
      <p>Once {category} have been added to the system you will be able to manage them here</p>
    </CardContent>
  );
}
