import React, { useState, useContext } from 'react';
import { makeStyles, Grid, Paper, Typography, Collapse, IconButton, Menu, MenuItem, Divider, Box } from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { DownArrow } from '../../Icons';
import { ApiEndpoint, EndpointBreakdown } from './Interface/ApiMonitoringResponse';
import EndpointBreakdownCard from './EndpointBreakdownCard';
import Confirm from '../common/Confirmation';
import ConfirmationSnackbar from '../common/Confirmation/confirmationSnackbar';
import { deleteEndpoint } from '../../api-queries/apiMonitoring';
import EditEndpointModal from './EditEndpointModal';
import EndpointStatusIcon from './EndpointStatusIcon';
import { AppContext } from '../../contexts/AppContext';
import { AppContextModel } from '../../contexts/interfaces/appContextModel';
import { UserLevels } from '../../utils/userLevelEnum';
import LockIcon from '@material-ui/icons/Lock';

interface Props {
  endpoint: EndpointBreakdown;
  index: number;
  appId: string;
  removeEndpoint: (endpointId: string) => void;
  editEndpointInArray: (updatedEndpoint: ApiEndpoint) => void;
  refresh: Function;
  isLoginEndpoint: boolean;
}

const useStyles = makeStyles(theme => ({
  close: {
    transitionDuration: '0.5s'
  },
  open: {
    transitionDuration: '0.5s',
    transform: 'rotate(180deg)'
  },
  paper: {
    position: 'relative',
    marginBottom: '12px',
    padding: theme.spacing(2, 3)
  },
  endpointDetails: {
    '& p': {
      [theme.breakpoints.up('sm')]: {
        paddingLeft: '24px',
        marginLeft: '-24px',
        borderLeft: '1px solid',
        borderColor: theme.palette.grey[300]
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: '0',
        paddingLeft: '32px',
        borderLeft: '0px transparent'
      }
    }
  },
  divider: {
    margin: theme.spacing(2, -3)
  },
  statusIcon: {
    margin: theme.spacing(0, 1, 0, 0)
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: theme.spacing(1) / 2,
    right: '12px',
    [theme.breakpoints.down('sm')]: {
      top: theme.spacing(3) / 2
    }
  },
  collapsible: {
    width: '100%'
  },
  lockIcon: {
    margin: '12px'
  }
}));

const ITEM_HEIGHT = 48;

const EndpointCard: React.FC<Props> = ({ endpoint, index, appId, removeEndpoint, editEndpointInArray, refresh, isLoginEndpoint }) => {
  const classes = useStyles();
  const [confirm, setConfirm] = useState<boolean>(false);
  const [checkedId, setCheckedId] = useState<number>(-1);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  // snackbar state
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [type, setType] = useState<string>('');

  const { appInContext } = useContext(AppContext) as AppContextModel;

  const editPermission = appInContext.userLevel >= UserLevels.adminUser;

  const handleExpand = (idx: number) => {
    setCheckedId(checkedId === idx ? -1 : idx);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleDelete = (): void => {
    setAnchorEl(null);
    setConfirm(true);
  };

  const handleEdit = (): void => {
    setAnchorEl(null);
    setEditModalOpen(true);
  };

  const submitDeleteEndpoint = async () => {
    try {
      const result = await deleteEndpoint({ appId, endpointId: endpoint.endpointDetails.endpointId });
      if (result.status === 204) {
        setOpenSnackbar(true);
        setType('success');
        removeEndpoint(endpoint.endpointDetails.endpointId);
      } else {
        setOpenSnackbar(true);
        setType('error');
      }
    } catch (e) {
      throw new Error(e as string);
    }
  };

  return (
    <Paper className={classes.paper}>
      <Grid container item xs={12}>
        <Grid item container alignItems="center" xs={12} md={6}>
          <EndpointStatusIcon successRate={endpoint.successRate} />
          <Typography noWrap>
            <strong>Name:</strong> {endpoint.endpointDetails.name}
          </Typography>
        </Grid>
        <Grid item container alignItems="center" xs={12} md={6} className={classes.endpointDetails}>
          <Grid item xs={12} md={6}>
            <Typography>
              <strong>Method:</strong> {endpoint.endpointDetails.method}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>
              <strong>Success Rate:</strong> {endpoint.successRate.toFixed(2)}%
            </Typography>
          </Grid>
          <Box className={classes.controls}>
            <DownArrow size="sm" className={checkedId === index ? classes.open : classes.close} onClick={() => handleExpand(index)} />
            {isLoginEndpoint ? (
              <LockIcon className={classes.lockIcon} />
            ) : (
              editPermission && (
                <>
                  <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch'
                      }
                    }}
                  >
                    <MenuItem onClick={handleEdit}>Edit</MenuItem>
                    <MenuItem onClick={handleDelete}>Delete</MenuItem>
                  </Menu>
                </>
              )
            )}
          </Box>
        </Grid>
      </Grid>

      <Collapse in={checkedId === index} className={classes.collapsible}>
        <Divider className={classes.divider} />
        <EndpointBreakdownCard endpoint={endpoint} appId={appId} />
      </Collapse>

      <Confirm process={'delete'} resource={'endpoint'} openConfirm={confirm} setOpenConfirm={setConfirm} action={submitDeleteEndpoint} />
      <ConfirmationSnackbar open={openSnackbar} setOpen={setOpenSnackbar} type={type} />
      <EditEndpointModal
        appId={appId}
        endpoint={endpoint.endpointDetails}
        open={editModalOpen}
        setOpen={setEditModalOpen}
        editEndpointInArray={editEndpointInArray}
        refresh={refresh}
      />
    </Paper>
  );
};

export default EndpointCard;
