import React, { PropsWithChildren, useContext, useEffect, useMemo } from 'react';
import { Grid, useTheme, makeStyles, useMediaQuery, CssBaseline } from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';
import AppTitle from '../../components/AppTitle';
import MobileNav from '../../components/common/Navigation/MobileNav';
import DesktopNav from '../../components/common/Navigation/DesktopNav';
import TabletNav from '../../components/common/Navigation/TabletNav';
import { SelectionRangeProvider } from '../../contexts/SelectionRangeContext';
import DatePicker from '../../components/common/DatePicker';
import { AppContext } from '../../contexts/AppContext';
import { UserContext } from '../../contexts/UserContext';
import AppLocationState from '../../models/appLocationState';
import appMenuItems from '../Home/menus/appMenuItems';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
      display: 'flex'
    }
  },
  content: {
    height: '100%',
    width: '100vw',
    margin: 'auto',
    padding: theme.spacing(0, 12),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 6)
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 3)
    }
  },
  height: {
    height: '100%'
  },
  container: {
    width: '100%',
    margin: 'auto'
  }
}));

interface Props {}

function Dashboard({ children }: PropsWithChildren<Props>) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation<AppLocationState | undefined>();
  const { profileUpdateRequired } = useContext(UserContext);
  const { appInContext } = useContext(AppContext);

  useEffect(() => {
    if (profileUpdateRequired) {
      history.push('/profile');
    }
  }, [profileUpdateRequired, history]);

  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  const ResponsiveNav = ({
    renderMobile,
    renderTablet,
    renderDesktop
  }: {
    renderMobile: () => JSX.Element;
    renderTablet: () => JSX.Element;
    renderDesktop: () => JSX.Element;
  }) => (isMd ? (isSm ? renderMobile() : renderTablet()) : renderDesktop());

  const displayDatePicker = ['/api-monitoring', 'api-overview'].some(page => location.pathname.search(page) >= 0);

  const isAppRoute = !!appInContext.appId;

  const menuItemsForApp = useMemo(() => {
    if (appInContext.servicesActive) {
      return appMenuItems.filter(item => !item.service || !!appInContext.servicesActive[item.service]);
    } else {
      return appMenuItems;
    }
  }, [appInContext]);

  return (
    <SelectionRangeProvider>
      <section>
        <ResponsiveNav
          renderDesktop={() => <DesktopNav menuItems={menuItemsForApp} showMenu={!!isAppRoute} />}
          renderTablet={() => <TabletNav menuItems={menuItemsForApp} showMenu={!!isAppRoute} />}
          renderMobile={() => <MobileNav menuItems={menuItemsForApp} showMenu={!!isAppRoute} />}
        />
        <main className={classes.content}>
          <Grid container>
            <Grid item xs={2}>
              {isAppRoute && <AppTitle />}
            </Grid>
            {displayDatePicker && (
              <Grid container item xs={10} direction="row" justify="flex-end" alignItems="center">
                <DatePicker />
              </Grid>
            )}
          </Grid>
          <div className={classes.container}>
            <CssBaseline />
            {children}
          </div>
        </main>
      </section>
    </SelectionRangeProvider>
  );
}

export default Dashboard;
