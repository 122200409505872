import React from 'react';
import { makeStyles } from '@material-ui/core';
import EndpointCard from './EndpointCard';
import { ApiEndpoint, EndpointBreakdown } from './Interface/ApiMonitoringResponse';

interface Props {
  appId: string;
  endpoints: EndpointBreakdown[];
  removeEndpoint: (endpointId: string) => void;
  editEndpointInArray: (updatedEndpoint: ApiEndpoint) => void;
  refresh: Function;
  loginEndpointId?: string;
}

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: '10px'
  }
}));

const EndpointList: React.FC<Props> = ({ appId, endpoints, removeEndpoint, editEndpointInArray, refresh, loginEndpointId }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {endpoints.map((endpoint: EndpointBreakdown, idx) => (
        <EndpointCard
          key={idx}
          endpoint={endpoint}
          index={idx}
          appId={appId}
          removeEndpoint={removeEndpoint}
          editEndpointInArray={editEndpointInArray}
          refresh={refresh}
          isLoginEndpoint={endpoint.endpointDetails.endpointId === loginEndpointId}
        />
      ))}
    </div>
  );
};

export default EndpointList;
