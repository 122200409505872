import { createMuiTheme, Theme, ThemeOptions } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { Palette, PaletteOptions } from '@material-ui/core/styles/createPalette';
import { CSSProperties } from '@material-ui/styles';

interface AppPaletteOptions extends PaletteOptions {
  rag: {
    red: string;
    amber: string;
    green: string;
  };
  graphDataSets: string[];
}

export interface AppThemeOptions extends ThemeOptions {
  pageTitle: CSSProperties;
  statistic: CSSProperties;
  button: CSSProperties;
  buttonGreen: CSSProperties;
  codeView: CSSProperties;
  palette: AppPaletteOptions;
}

interface AppPalette extends Palette {
  rag: {
    red: string;
    amber: string;
    green: string;
  };
  graphDataSets: string[];
}

export interface AppTheme extends Theme {
  pageTitle: CSSProperties;
  statistic: CSSProperties;
  button: CSSProperties;
  buttonGreen: CSSProperties;
  codeView: CSSProperties;
  palette: AppPalette;
}

const breakpoints = createBreakpoints({});

// A dummy theme just so we can access helper functions and default properties
const baseTheme = createMuiTheme();

const options: AppThemeOptions = {
  typography: {
    fontFamily: 'Nunito Sans, sans-serif'
  },
  palette: {
    primary: {
      main: '#00b173'
    },
    rag: {
      red: '#e30613',
      amber: '#f9b233',
      green: '#3aaa35',
    },
    graphDataSets: [
      '#00b173',
      '#b1003e',
      '#F9AD48',
      '#4794CC',
      '#9149B7',
      '#FFA8FF',
      '#DBDB00',
    ]
  },
  codeView: {
    backgroundColor: '#272c34',
    padding: baseTheme.spacing(1),
    borderRadius: baseTheme.shape.borderRadius,
    fontSize: baseTheme.typography.body2.fontSize,
    color: baseTheme.palette.getContrastText('#272c34'),
    fontFamily: 'Monospace',
    whiteSpace: 'pre-wrap',
    overflowWrap: 'anywhere',
  },
  pageTitle: {
    fontSize: '40px'
  },
  statistic: {
    fontSize: '50px',
    fontWeight: 'bold'
  },
  button: {
    backgroundcolor: '#fff',
    border: 'solid 1px rgba(35, 0, 30, 0.2)',
    textTransform: 'none',
    boxShadow: 'none',
    '&:hover': {
      background: '#DCDCDC',
      transition: '0.3s',
      transitionTimingFunction: 'ease-in-out'
    }
  },
  buttonGreen: {
    backgroundcolor: '#00b173',
    color: '#ffffff'
  },
  props: {
    MuiPaper: {
      elevation: 0,
      variant: 'outlined',
    },
    MuiCard: {
      elevation: 0,
      variant: 'outlined',
    },
    MuiButton: {
      disableElevation: true,
      variant: 'outlined'
    }
  },
  overrides: {
    MuiTypography: {
      h1: {
        fontSize: '40px',
        fontWeight: 400,
        marginBottom: '20px',
        [breakpoints.only('xs')]: {
          fontSize: '2rem'
        }
      },
      h2: {
        fontSize: '32px',
        fontWeight: 400,
        [breakpoints.down('sm')]: {
          fontSize: 'calc(18px + 14 * ((100vw - 320px) / 600))'
        }
      },
      h3: {
        fontSize: '26px',
        fontWeight: 400,
        [breakpoints.down('sm')]: {
          fontSize: 'calc(16px + 8 * ((100vw - 320px) / 600))'
        }
      }
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.3)'
      }
    },
    MuiButton: {
      root: {
        backgroundColor: '#fff',
        border: 'solid 1px rgba(35, 0, 30, 0.1)',
        textTransform: 'none',
        '&:hover': {
          backgroundColor: '#DCDCDC',
          transition: '0.3s',
          transitionTimingFunction: 'ease-in-out'
        }
      }
    },
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: 'rgba(229, 194, 192, 0.1)',
          width: '100%',
          overflowX: 'hidden'
        }
      }
    }
  }
};

const theme = createMuiTheme(options) as AppTheme;

export default theme;
