import React, { useMemo, useState } from 'react';
import { Backdrop, Fade, Button, makeStyles, TextField, Modal, Typography } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import { createApp } from '../../api-queries/appManagement';
import { CreateAppRequest, App } from '../AppList/AppModel';
import { Controller, useForm } from 'react-hook-form';
import { convertPlatformBackend, PlatformsReadable } from '../../utils/platforms';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  form: {
    width: '100%'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[0],
    padding: theme.spacing(3),
    outline: 'none',
    border: 'solid 1px rgba(35, 0, 30, 0.2)',
    borderRadius: '5px',
    '&:focus': {
      outline: 'none'
    },
    minWidth: '400px',
    maxWidth: '600px',
    maxHeight: '100vh'
  },
  title: {
    marginBottom: '10px'
  },
  cardContent: {
    width: '100%'
  },
  inputs: {
    display: 'block'
  },
  textField: {
    width: '90%',
    height: '40px',
    marginTop: '5%',
    marginBottom: '5%'
  },
  actionsContainer: {
    display: 'flex',
    marginTop: '5%'
  },
  platformsDropdown: {
    width: '100%'
  },
  review: {
    marginBottom: '5%'
  },
  apiMonitoringForm: {
    width: '90%',
    height: '100px',
    marginTop: '30px'
  },
  apiMonitoringMenuItem: {
    marginTop: '10px',
    marginBottom: '30px'
  },
  bottomButtons: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  buttonMargin: {
    marginRight: '8px'
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const availablePlatforms: string[] = ['Android', 'iOS'];

interface Props {
  open: boolean;
  companyId: string;
  handleClose(): void;
  apps: App[];
  setApps: React.Dispatch<React.SetStateAction<any>>;
  setSdkSetupOpen: React.Dispatch<React.SetStateAction<any>>;
  setCreatedApp: React.Dispatch<React.SetStateAction<any>>;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<any>>;
  setType: React.Dispatch<React.SetStateAction<any>>;
}

const CreateApp: React.FC<Props> = ({
  open,
  companyId,
  handleClose,
  apps,
  setApps,
  setSdkSetupOpen,
  setCreatedApp,
  setOpenSnackbar,
  setType
}): JSX.Element => {
  const classes = useStyles();
  const defaultServicesActive = {
    apiMonitoring: false
  };
  const emptyApp = {
    appName: '',
    packageNames: { android: '', ios: '' },
    platforms: [],
    companyId,
    servicesActive: defaultServicesActive
  };

  const [appDetails, setAppDetails] = useState<CreateAppRequest>(emptyApp);

  const {
    control,
    reset,
    handleSubmit,
    watch,
    formState: { errors, isValid }
  } = useForm<CreateAppRequest>({
    defaultValues: useMemo(() => {
      return appDetails;
    }, [appDetails]),
    mode: 'onChange'
  });

  const handleCancel = (): void => {
    reset();
    handleClose();
  };

  const onSubmit = async (formValues: CreateAppRequest) => {
    const appDetailsToSubmit = { ...formValues, platforms: formValues.platforms.map(platform => convertPlatformBackend[platform]) };
    submitApp(appDetailsToSubmit);
  };

  const submitApp = async (appDetailsToSubmit: CreateAppRequest) => {
    try {
      const result = await createApp(appDetailsToSubmit);
      const resultJson = await result.json();
      if (result.status === 200) {
        setApps([...apps, resultJson.app]);
        setType('success');
        setOpenSnackbar(true);
        setSdkSetupOpen(true);
        setCreatedApp({ name: resultJson.app.appName, appId: resultJson.app.appId });
        handleClose();
        setAppDetails(emptyApp);
        reset();
      } else {
        setType('error');
        setOpenSnackbar(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      className={classes.modal}
      open={open}
      onClose={handleCancel}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Fade in={open}>
        <form className={classes.paper} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Typography className={classes.title} variant="h6">
            Please enter the details of the app you would like to add to this organisation below:
          </Typography>
          <div>
            <Controller
              name="appName"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field }) => <TextField label="App Name" variant="outlined" margin="dense" fullWidth required {...field} />}
            />
            <Controller
              name="platforms"
              control={control}
              defaultValue={undefined}
              rules={{ required: false }}
              render={({ field }) => (
                <div className={classes.textField}>
                  <InputLabel id="demo-mutiple-checkbox-label">Platform(s)</InputLabel>
                  <Select
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    multiple
                    input={<Input />}
                    renderValue={(selected: any) => selected.join(', ')}
                    MenuProps={MenuProps}
                    className={classes.platformsDropdown}
                    {...field}
                  >
                    {availablePlatforms.map(availablePlatform => (
                      <MenuItem key={availablePlatform} value={availablePlatform}>
                        <Checkbox checked={watch().platforms.indexOf(availablePlatform) > -1} />
                        <ListItemText primary={availablePlatform} />
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              )}
            />
            {watch().platforms.includes(PlatformsReadable.android) && (
              <Controller
                name="packageNames.android"
                control={control}
                defaultValue={undefined}
                rules={{ required: watch().platforms.includes(PlatformsReadable.android) }}
                shouldUnregister
                render={({ field }) => (
                  <>
                    <TextField label="Android Package Name" variant="outlined" margin="dense" fullWidth required {...field} />
                  </>
                )}
              />
            )}
            {watch().platforms.includes(PlatformsReadable.ios) && (
              <Controller
                name="packageNames.ios"
                control={control}
                defaultValue={undefined}
                rules={{ required: watch().platforms.includes(PlatformsReadable.ios) }}
                shouldUnregister
                render={({ field }) => (
                  <>
                    <TextField label="iOS Package Name" variant="outlined" margin="dense" fullWidth required {...field} />
                  </>
                )}
              />
            )}
            <div className={classes.apiMonitoringForm}>
              <span>Do you want to enable API monitoring for this app?</span>
              <Controller
                name="servicesActive.apiMonitoring"
                control={control}
                defaultValue={undefined}
                rules={{
                  required: false,
                  validate: apiMonitoring => watch().platforms.length > 0 || apiMonitoring
                }}
                render={({ field }) => (
                  <MenuItem
                    className={classes.apiMonitoringMenuItem}
                    disableGutters={true}
                    key={'apiMonitoring'}
                    value={'API Monitoring'}
                    onClick={() => {
                      field.onChange(!field.value);
                    }}
                  >
                    <Checkbox checked={watch().servicesActive.apiMonitoring} {...field} />
                    <ListItemText primary={'Enable API Monitoring'} />
                  </MenuItem>
                )}
              />
            </div>
          </div>
          <div className={classes.bottomButtons}>
            <Button className={classes.buttonMargin} onClick={handleCancel} color="primary">
              Cancel
            </Button>
            <Button disabled={!isValid} type="submit" color="primary">
              Submit
            </Button>
          </div>
        </form>
      </Fade>
    </Modal>
  );
};

export default CreateApp;
