import React, { createContext, useState } from 'react';
import { startOfDay, endOfToday } from 'date-fns';
import { SelectionRangeContextModel, SelectionRange, ComparisonRange } from './interfaces/selectionRangeContextModel';

const emptySelectionRange: SelectionRange = {
  startDate: new Date(),
  endDate: new Date(),
  startDateMS: 0,
  endDateMS: 0,
  key: ''
};

const emptyComparisonRange: ComparisonRange = {
  range: 0,
  offset: 0,
  startMS: 0,
  endMS: 0
};

const SelectionRangeContext = createContext<SelectionRangeContextModel>({
  selectionRange: emptySelectionRange,
  setSelectionRange: () => {},
  comparisonRange: emptyComparisonRange,
  setComparisonRange: () => {}
});

const defaultPeriod = 7;
const today = new Date();
const startDate = startOfDay(new Date().setDate(today.getDate() - defaultPeriod));
const endDate = endOfToday();

const SelectionRangeProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [selectionRange, setSelectionRange] = useState<SelectionRange>({
    startDate: startDate,
    endDate: endDate,
    startDateMS: startDate.getTime(),
    endDateMS: endDate.getTime(),
    key: 'selection'
  });

  const range = selectionRange.endDateMS - selectionRange.startDateMS;

  const [comparisonRange, setComparisonRange] = useState<ComparisonRange>({
    range,
    offset: range,
    startMS: selectionRange.startDateMS - range,
    endMS: selectionRange.startDateMS
  });

  return (
    <SelectionRangeContext.Provider value={{ selectionRange, setSelectionRange, comparisonRange, setComparisonRange }}>
      {children}
    </SelectionRangeContext.Provider>
  );
};

SelectionRangeProvider.defaultProps = {
  children: []
};

export { SelectionRangeProvider, SelectionRangeContext };
