import React, { useContext, useState } from 'react';
import PageTitle from '../PageTitle';
import { AppContext } from '../../contexts/AppContext';
import { Fade, Grid, Button, Typography, useTheme, useMediaQuery, makeStyles, Card } from '@material-ui/core';
import SubTitle from '../SubTitles';
import PostmanConfigCards from './PostmanConfigCards';
import EndpointList from './EndpointList';
import AddCollection from './AddCollection';
import ConfirmationSnackbar from '../common/Confirmation/confirmationSnackbar';
import AddEnvironment from './AddEnvironment';
import EditMonitoringInterval from './EditMonitoringInterval';
import { UserLevels } from '../../utils/userLevelEnum';

const useStyles = makeStyles(theme => ({
  subtitle: {
    marginTop: '32px'
  },
  buttonContainer: {
    marginTop: '32px',
    '& button': {
      marginRight: '16px',
      padding: '10px'
    },
    [theme.breakpoints.down('sm')]: {
      '& button': {
        width: '100%',
        marginRight: '0px',
        marginTop: '12px',
        padding: '10px'
      }
    }
  }
}));

const PostmanMonitoring: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [checked] = useState(true);

  //Modal State
  const [addCollectionModalOpen, setAddCollectionModalOpen] = useState<boolean>(false);
  const [addEnvironmentModalOpen, setAddEnvironmentModalOpen] = useState<boolean>(false);
  const [editMonitoringRateModalOpen, setEditMonitoringRateModalOpen] = useState<boolean>(false);

  //Confirmation SnackBar State
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [type, setType] = useState<string>();

  const { appInContext } = useContext(AppContext);
  const editPermission = appInContext.userLevel >= UserLevels.adminUser;
  const postmanMonitoring = appInContext.postmanMonitoringDetails;

  const addSuccess = () => {
    setOpenConfirmation(true);
    setType('success');
  };

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <PageTitle text={'Postman Monitoring'} />
      </Grid>
      {postmanMonitoring && (
        <>
          <Fade in={checked} {...(checked ? { timeout: 300 } : {})}>
            <Grid item xs={12}>
              <PostmanConfigCards config={postmanMonitoring} />
            </Grid>
          </Fade>
          <Grid item xs={12} className={classes.buttonContainer}>
            <Button onClick={() => setAddCollectionModalOpen(prevState => !prevState)} disabled={!editPermission}>
              Add Postman Collection
            </Button>
            <Button onClick={() => setAddEnvironmentModalOpen(prevState => !prevState)} disabled={!editPermission}>
              Add Postman Environment
            </Button>
            <Button onClick={() => setEditMonitoringRateModalOpen(prevState => !prevState)} disabled={!editPermission}>
              Configure Monitoring Interval
            </Button>
          </Grid>
          <Grid item xs={12} className={classes.subtitle}>
            <SubTitle text={'Endpoints'} />
          </Grid>
          <Fade in={checked} {...(checked ? { timeout: 300 } : {})}>
            <Grid item xs={12}>
              {postmanMonitoring.endpoints && <EndpointList endpoints={postmanMonitoring.endpoints} />}
            </Grid>
          </Fade>
          <Grid item xs={12}>
            <AddCollection open={addCollectionModalOpen} onClose={() => setAddCollectionModalOpen(false)} onSuccess={addSuccess} />

            <AddEnvironment open={addEnvironmentModalOpen} onClose={() => setAddEnvironmentModalOpen(false)} onSuccess={addSuccess} />
            <EditMonitoringInterval open={editMonitoringRateModalOpen} onClose={() => setEditMonitoringRateModalOpen(false)} onSuccess={addSuccess} />
          </Grid>
          <Grid item xs={12}>
            <AddCollection open={addCollectionModalOpen} onClose={() => setAddCollectionModalOpen(false)} onSuccess={addSuccess} />
            <AddEnvironment open={addEnvironmentModalOpen} onClose={() => setAddEnvironmentModalOpen(false)} onSuccess={addSuccess} />
            <EditMonitoringInterval open={editMonitoringRateModalOpen} onClose={() => setEditMonitoringRateModalOpen(false)} onSuccess={addSuccess} />
          </Grid>
          <ConfirmationSnackbar open={openConfirmation} setOpen={setOpenConfirmation} type={type} timeout={3000} />
        </>
      )}
      {!postmanMonitoring && <h2>Postman Monitoring has not been configured.</h2>}
    </Grid>
  );
};

export default PostmanMonitoring;
