import * as React from 'react';
import iconSize from './iconSizes';

function SvgDownArrow(props) {
  return (
    <svg {...props} height={iconSize(props.size)} width={iconSize(props.size)}>
      <path d="M8 10l4 4 4-4" stroke={props.stroke || '#000'} strokeWidth={2} fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default SvgDownArrow;
