import { Button, IconButton, Input, Menu, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { editCompanyUser, removeCompanyUser } from '../../../api-queries/userManagement';
import { User } from '../../../models/userModel';
import { getUserLevelAsString, minimumAccessRequirements } from '../../../utils/userLevelEnum';
import Confirm from '../../common/Confirmation';
import CreateUser from '../createUser';
import ConfirmationSnackbar from '../../common/Confirmation/confirmationSnackbar';
import Paginator from '../../common/Paginator';
import { UserContext } from '../../../contexts/UserContext';
import { Company } from '../Models/CompanyModel';

interface Props {
  users: User[];
  setUsers: React.Dispatch<React.SetStateAction<any>>;
  companyId: string;
  company: Company;
}

const UsersTable: React.FC<Props> = ({ users, setUsers, companyId, company }): JSX.Element => {
  const [addUser, setAddUser] = useState<boolean>(false);
  const [userAnchorEl, setUserAnchorEl] = useState<null | HTMLElement>(null);
  const editUser = Boolean(userAnchorEl);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [userToEdit, setUserToEdit] = useState<User | null>(null);
  const [newUserLevel, setNewUserLevel] = useState<number>(0);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [type, setType] = useState<string>('');
  const { loggedInUser } = useContext<any>(UserContext);
  const [userIdToRemove, setUserIdToRemove] = useState<string>();

  //Paginator
  const [page, setPage] = useState<number>(0);
  const [rows, setRows] = useState<number>(5);

  const handleAddUser = (): void => {
    setAddUser(!addUser);
  };

  const handleEditUserMenu = (event: React.MouseEvent<HTMLElement>, user: User): void => {
    setUserAnchorEl(event.currentTarget);
    if (user) setSelectedUser(user);
  };

  const handleEditUser = () => {
    setUserToEdit(selectedUser);
    setUserAnchorEl(null);
  };

  const toggleEditUser = (): void => {
    setUserAnchorEl(null);
  };

  const handleRemoveUser = () => {
    setUserIdToRemove(selectedUser?.userId);
  };

  const handleConfirmRemoveUser = async (): Promise<void> => {
    if (userIdToRemove) {
      try {
        const response = await removeCompanyUser(userIdToRemove, companyId);
        if (response.status === 200) {
          const usersCopy = [...users];
          const indexOfUser = usersCopy.findIndex(user => user.userId === userIdToRemove);
          usersCopy.splice(indexOfUser, 1);
          setUsers([...usersCopy]);
          toggleEditUser();
          setOpenSnackbar(true);
          setType('success');
        } else {
          setOpenSnackbar(true);
          setType('error');
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleLevelChange = (level: number): void => {
    setNewUserLevel(level);
  };

  const handleConfirmUpdateUserLevel = async (): Promise<void> => {
    if (userToEdit && userToEdit.userId) {
      try {
        const response = await editCompanyUser({ companyId, userId: userToEdit?.userId, userLevel: newUserLevel });
        if (response.status === 200) {
          const { user } = await response.json();
          const usersCopy = [...users];
          const indexOfUser = usersCopy.findIndex(user => user.userId === userToEdit.userId);
          usersCopy[indexOfUser] = { ...user, companyUserLevel: newUserLevel };

          setUsers(usersCopy);
          setOpenSnackbar(true);
          setType('success');
          setUserToEdit(null);
          setNewUserLevel(0);
        } else {
          setOpenSnackbar(true);
          setType('error');
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const findUserLevel = (level: number) => {
    const foundUserLevel = userLevels.find(userLevel => userLevel.level === level);
    if (foundUserLevel) return foundUserLevel.level;
  };

  const userLevels = [
    { name: 'Basic User', level: 1 },
    { name: 'Admin User', level: 2 },
    { name: 'Account Holder', level: 3 }
  ];

  const getUserLevelsForDropdown = (): { name: string; level: number }[] => {
    const userPermission = company.userPermissions.find(userPermission => userPermission.userId === loggedInUser.userId);
    if (userPermission) {
      return userLevels.filter(userLevel => userLevel.level <= userPermission.userLevel);
    }
    return [];
  };

  const userLevelsForUser = getUserLevelsForDropdown();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  };

  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>User Name</TableCell>
              <TableCell>User Level</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {users.slice(rows * page, rows * page + rows).map(
              (user, idx): JSX.Element => (
                <TableRow key={idx}>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    {userToEdit && userToEdit.userId === user.userId ? (
                      <Select
                        labelId="demo-mutiple-checkbox-label"
                        id="demo-mutiple-checkbox"
                        variant="outlined"
                        value={newUserLevel > 0 ? findUserLevel(newUserLevel) : user.companyUserLevel}
                        input={<Input />}
                        MenuProps={MenuProps}
                      >
                        {loggedInUser.userLevel === minimumAccessRequirements.accessToAllUserLevels
                          ? userLevels.map(userLevel => (
                              <MenuItem key={userLevel.level} value={userLevel.level} onClick={() => handleLevelChange(userLevel.level)}>
                                {userLevel.name}
                              </MenuItem>
                            ))
                          : userLevelsForUser.map(userLevel => (
                              <MenuItem key={userLevel.level} value={userLevel.level} onClick={() => handleLevelChange(userLevel.level)}>
                                {userLevel.name}
                              </MenuItem>
                            ))}
                      </Select>
                    ) : user.companyUserLevel ? (
                      getUserLevelAsString(user.companyUserLevel)
                    ) : (
                      getUserLevelAsString(1)
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={e => {
                        handleEditUserMenu(e, user);
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      key={idx}
                      id="long-menu"
                      anchorEl={userAnchorEl}
                      keepMounted
                      open={editUser}
                      onClose={toggleEditUser}
                      PaperProps={{
                        style: {
                          maxHeight: ITEM_HEIGHT * 4.5,
                          width: '20ch'
                        }
                      }}
                    >
                      <MenuItem button onClick={handleEditUser}>
                        Edit
                      </MenuItem>
                      <MenuItem button onClick={handleRemoveUser}>
                        Remove
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Paginator totalItems={users.length} page={page} setPage={setPage} rows={rows} setRows={setRows} />
      <Button onClick={handleAddUser}>Add User</Button>
      {newUserLevel ? (
        <Confirm
          process={'update'}
          resource={'user level'}
          openConfirm={Boolean(newUserLevel)}
          setOpenConfirm={() => setNewUserLevel(0)}
          action={handleConfirmUpdateUserLevel}
        />
      ) : null}
      <Confirm
        process={'remove'}
        resource={'user'}
        openConfirm={Boolean(userIdToRemove)}
        setOpenConfirm={() => setUserIdToRemove('')}
        action={handleConfirmRemoveUser}
      />
      <CreateUser open={addUser} companyId={companyId} handleClose={handleAddUser} users={users} setUsers={setUsers} />
      <ConfirmationSnackbar open={openSnackbar} setOpen={setOpenSnackbar} type={type} />
    </div>
  );
};

export default UsersTable;
