import React, { ComponentType, useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import { Route, Switch } from 'react-router-dom';
import AppList from '../../components/AppList';
import Create from '../../components/Manage';
import CompanyManagement from '../../components/Manage/Company';
import UserProfile from '../../components/Profile';
import RoutedAppProvider from '../../contexts/RoutedAppProvider';
import NotFound from '../404';
import Dashboard from '../Dashboard';
import { AppContext } from '../../contexts/AppContext';
import ProgressBar from '../../components/common/ProgressBar';
import { PromiseStatus } from '../../utils/usePromiseWatcher';
import ApiMonitoring from '../../components/ApiMonitoring';
import AppOverview from '../../components/AppOverview';
import PostmanMonitoring from '../../components/PostmanMonitoring';
import SslMonitoring from '../../components/SslMonitoring';
import SslOverview from '../../components/SslOverview';
import ApiMonitoringOverview from '../../components/ApiMonitoringOverview';

function AppLoadedRouteGuard({ component }: { component: ComponentType<{}> }) {
  const { status } = useContext(AppContext);
  const Component = component;

  return status === PromiseStatus.Resolved || status === PromiseStatus.Rejected ? <Component /> : <ProgressBar />;
}

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      display: 'flex'
    }
  },
  content: {
    paddingTop: theme.spacing(12),
    [theme.breakpoints.up('sm')]: {
      flexGrow: 1,
      padding: theme.spacing(3),
      paddingTop: theme.spacing(12)
    }
  },
  overlay: {
    [theme.breakpoints.down('sm')]: {
      zIndex: theme.zIndex.drawer - 1,
      display: 'flex',
      position: 'fixed',
      background: 'rgba(0, 0, 0, 0.7)',
      width: '100%',
      height: '100%'
    }
  }
}));

function Home() {
  const classes = useStyles();
  return (
    <section className={classes.root}>
      <RoutedAppProvider>
        <Dashboard>
          <Switch>
            <Route exact path="/" component={AppList} />
            <Route path="/manage" component={Create} />
            <Route path="/ssl-overview" component={SslOverview} />
            <Route path="/api-overview" component={ApiMonitoringOverview} />
            <Route path="/:companyId/manage" component={CompanyManagement} />
            <Route exact path="/profile" component={UserProfile} />
            <Route path="/app/:appId/api-monitoring" render={() => <AppLoadedRouteGuard component={ApiMonitoring} />} />
            <Route path="/app/:appId/app-overview" render={() => <AppLoadedRouteGuard component={AppOverview} />} />
            <Route path="/app/:appId/postman-monitoring" render={() => <AppLoadedRouteGuard component={PostmanMonitoring} />} />
            <Route path="/app/:appId/ssl-monitoring" render={() => <AppLoadedRouteGuard component={SslMonitoring} />} />
            <Route component={NotFound} />
          </Switch>
        </Dashboard>
      </RoutedAppProvider>
    </section>
  );
}

export default Home;
