import React, { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import {
  Avatar,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  AppBar,
  Toolbar,
  IconButton,
  CssBaseline,
  MenuItem
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { ApadmiPlus } from '../../../../Icons';
import { UserContext } from '../../../../contexts/UserContext';
import { MenuItems } from '../Models/MenuItemModel';
import { NavigationModal } from '../NavigationModal';
import { AppContext } from '../../../../contexts/AppContext';

const drawerWidth = 300;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  appBar: {
    backgroundColor: '#FFFFFF',
    border: 'solid 1px rgba(35, 0, 30, 0.1)',
    zIndex: theme.zIndex.appBar
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.complex
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.complex
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1
  },
  bottomMenu: {
    position: 'absolute',
    bottom: 0,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  linkText: {
    color: '#000'
  },
  listItem: {
    padding: 0
  },
  avatar: {
    margin: 'auto',
    backgroundColor: '#f4984f'
  },
  title: {
    flexGrow: 1
  },
  apadmiLogo: {
    height: '36px'
  },
  logoContainer: {
    marginTop: '15px'
  },
  hiddenAppBar: {
    minHeight: '64px'
  },
  notSelected: {
    width: '100%',
    color: '#000000',
    borderRadius: '5px',
    textDecoration: 'none'
  },
  selected: {
    width: '100%',
    backgroundColor: '#00b173',
    color: '#FFFFFF',
    borderRadius: '5px',
    textDecoration: 'none'
  }
}));

interface Props {
  menuItems: MenuItems[];
  showMenu: boolean;
}

const TabletNav: React.FC<Props> = ({ menuItems, showMenu }) => {
  const { loggedInUser } = useContext(UserContext);
  const { appInContext } = useContext(AppContext);
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const classes = useStyles();

  const toggleDrawer = (): void => {
    setOpen(!open);
  };

  const toggleModal = (): void => {
    setModal(!modal);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="static" className={classes.appBar} elevation={0}>
        <Toolbar>
          <div className={classes.title}>
            <div className={classes.logoContainer}>
              <ApadmiPlus className={classes.apadmiLogo}></ApadmiPlus>
            </div>
          </div>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer}>
            {!open ? <MenuIcon /> : <CloseIcon />}
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor={'right'}
        open={open}
        onClose={toggleDrawer}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })
        }}
      >
        <div className={classes.hiddenAppBar}></div>
        {showMenu &&
          menuItems.map((option, idx) => (
            <MenuItem
              key={idx}
              button
            >
              <NavLink
                to={`/app/${appInContext.appId}/${option.url}`}
                activeClassName={classes.selected}
                className={classes.notSelected}
              >
                <ListItem
                  button
                  component='div'
                >
                  <ListItemIcon>{option.icon}</ListItemIcon>
                  <ListItemText primary={option.name} />
                </ListItem>
              </NavLink>
            </MenuItem>
          ))}
        <List className={classes.bottomMenu}>
          <MenuItem>
            <ListItem className={classes.listItem} onClick={toggleModal} component={'div'}>
              <ListItemIcon>
                <Avatar className={classes.avatar}>
                  {loggedInUser!.firstName.charAt(0)}
                  {loggedInUser!.lastName.charAt(0)}
                </Avatar>
              </ListItemIcon>
              <ListItemText className={classes.linkText}>
                {loggedInUser!.firstName} {loggedInUser!.lastName}
              </ListItemText>
            </ListItem>
          </MenuItem>
        </List>
      </Drawer>
      <NavigationModal open={modal} toggleModal={toggleModal} />
    </div>
  );
};

export default TabletNav;
