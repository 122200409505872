import { UserLevels } from '../../utils/userLevelEnum';
import { AlertsData } from '../ApiMonitoring/Interface/AlertsData';
import { ApiEndpoint } from '../ApiMonitoring/Interface/ApiMonitoringResponse';
import { MonitoringAuth } from '../ApiMonitoring/Interface/ApiMonitoringAuth';
import { Platforms } from '../../utils/platforms';
import { ConsecutiveFailureAlertRule } from '../ApiMonitoring/Interface/ConsecutiveFailureAlertRule';
import { PostmanMonitoringConfig } from '../PostmanMonitoring/Interface/PostmanMonitoringConfig';
import { SslData } from '../SslMonitoring/interface/SslData';
import { OpsgenieData } from '../AppOverview/interface/OpsgenieData';

export interface ActiveServices {
  [apiMonitoring: string]: boolean;
}

export interface CoreConfig {
  jira_name: string;
  prometheus_name: string;
  sheetId: string;
}

export interface AndroidConfig {
  bucket_name: string;
  package_name: string;
}

export interface IosConfig {
  apple_id: string;
  apple_user: string;
  apple_team_id: string;
  apple_team_name: string;
  bundle_id: string;
}

export interface App {
  appId: string;
  appName: string;
  packageNames: PackageNames;
  platforms: Platforms[];
  servicesActive: ActiveServices;
  apiEndpoints?: ApiEndpoint[];
  alertsData?: AlertsData;
  userLevel: UserLevels;
  monitoringAuth?: MonitoringAuth;
  monitoringConfig?: MonitoringConfig;
  postmanMonitoringDetails?: PostmanMonitoringConfig;
  sslInformation?: SslData;
  opsgenieConfig?: OpsgenieData;
  consecutiveFailureAlertRules?: ConsecutiveFailureAlertRule[];
}

export interface MonitoringConfig {
  lastRan: number;
  monitoringRate: number;
}

export const emptyApp: App = {
  appId: '',
  appName: '',
  packageNames: {
    android: '',
    ios: ''
  },
  platforms: [],
  servicesActive: {
    apiMonitoring: false
  },
  userLevel: UserLevels.basicUser
};

export interface CreateAppRequest {
  appName: string;
  packageNames: PackageNames;
  platforms: string[];
  servicesActive: ActiveServices;
  apiEndpoints?: ApiEndpoint[];
}

export interface PackageNames {
  android?: string;
  ios?: string;
}

export interface EditPlatformsAndPackageNames {
  appId: string;
  platforms: string[];
  packageNames: PackageNames;
}
