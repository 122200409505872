export default function iconSize(size) {
  switch (size) {
    case 'sm':
      return '24px';
    case 'md':
      return '48px';
    case 'lg':
      return '64px';
    default:
      return '24px';
  }
}
