import {LocalStorageItemEnum} from '../models/enums/localStorageItemEnum';

const { REACT_APP_BACKEND_API_URL } = process.env;

const getOptions = (method: string): Object => {
  const idToken = localStorage.getItem(LocalStorageItemEnum.idToken);
  const options = {
    method: `${method}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: idToken
    }
  };
  return options;
};

export const fetchAppList = (): Promise<Response> => {
  const options = getOptions('GET');
  return fetch(`${REACT_APP_BACKEND_API_URL}/apps`, options);
};
