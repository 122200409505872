import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import ConfirmationSnackbar from '../common/Confirmation/confirmationSnackbar';
import { Company, CreateCompanyRequest } from './Models/CompanyModel';
import { createCompany } from '../../api-queries/companyManagement';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

interface Props {
  open: boolean;
  companies: Company[];
  setCompanies: Dispatch<SetStateAction<any>>;
  handleClose(): void;
}

const CreateCompany: React.FC<Props> = ({ open, companies, setCompanies, handleClose }): JSX.Element => {
  const emptyCompany: CreateCompanyRequest = {
    companyContactEmail: '',
    companyContactName: '',
    companyDescription: '',
    companyName: ''
  };

  // Success snackbar
  const [type, setType] = useState<string>();
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);

  const {
    control,
    reset,
    handleSubmit,
    watch,
    formState: { errors, isValid }
  } = useForm<CreateCompanyRequest>({
    defaultValues: useMemo(() => {
      return emptyCompany;
    }, [emptyCompany]),
    mode: 'onChange'
  });

  const onSubmit: SubmitHandler<CreateCompanyRequest> = async formValues => {
    try {
      const result = await createCompany(formValues);
      const resultJson = await result.json();
      if (result.status === 200) {
        setCompanies([...companies, resultJson.company]);
        setOpenConfirm(true);
        setType('success');
        onClose();
      } else {
        setOpenConfirm(true);
        setType('error');
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onClose = () => {
    reset();
    handleClose();
  }

  return (
    <div>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          <span>Please enter the details of the company you would like to add below:</span>
        </DialogTitle>
        <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Controller
              name="companyName"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  id="CompanyName"
                  label="Company Name *"
                  fullWidth
                  helperText={errors.companyName ? <span>The Company name is required</span> : null}
                  {...field}
                />
              )}
            />

            <Controller
              name="companyContactName"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  id="CompanyContactName"
                  label="Company Contact Name *"
                  fullWidth
                  helperText={errors.companyContactName ? <span>The name of the company contact is required</span> : null}
                  {...field}
                />
              )}
            />

            <Controller
              name="companyContactEmail"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  id="companyContactEmail"
                  label="Company Contact Email *"
                  fullWidth
                  helperText={errors.companyContactEmail ? <span>The email address of the company contact is required</span> : null}
                  {...field}
                />
              )}
            />
            <Controller
              name="companyDescription"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  id="companyDescription"
                  label="Company Description *"
                  fullWidth
                  helperText={errors.companyDescription ? <span>A description of the company is required</span> : null}
                  {...field}
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button disabled={!isValid} type="submit" color="primary">
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <ConfirmationSnackbar open={openConfirm} setOpen={setOpenConfirm} type={type} />
    </div>
  );
};

export default CreateCompany;
