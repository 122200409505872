import { LocalStorageItemEnum } from '../models/enums/localStorageItemEnum';
import { throwIfResponseNotOk } from '../utils/fetchNotOkUtils';
import { RefreshSessionResponse } from './models/refreshSessionResponse';
import { AppsResponse } from './models/appsResponse';

const { REACT_APP_BACKEND_API_KEY, REACT_APP_BACKEND_API_URL } = process.env;

const getOptions = (): RequestInit => {
  const idToken = localStorage.getItem(LocalStorageItemEnum.idToken)!;

  return {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': REACT_APP_BACKEND_API_KEY!,
      Authorization: idToken
    }
  };
};

const fetchAppById = (appId: string): Promise<AppsResponse> => {
  const url = new URL(`${REACT_APP_BACKEND_API_URL}/apps`);

  url.searchParams.set('appId', appId);

  return fetch(url.toString(), getOptions())
    .then(throwIfResponseNotOk)
    .then(resp => resp.json() as Promise<AppsResponse>);
};

const refreshSession = (idToken: string, accessToken: string): Promise<RefreshSessionResponse> => {
  const options: RequestInit = {
    method: 'GET',
    mode: 'cors',
    headers: {
      Authorization: `${idToken}`,
      AccessToken: `${accessToken}`
    }
  };
  return fetch(`${REACT_APP_BACKEND_API_URL}/refresh`, options)
    .then(throwIfResponseNotOk)
    .then(resp => resp.json() as Promise<RefreshSessionResponse>);
};

export { fetchAppById, refreshSession };
