import { Card, Grid, makeStyles, Switch, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { EditPostmanMonitoringStatus } from '../../api-queries/postmanMonitoring';
import { AppContext } from '../../contexts/AppContext';
import { UserLevels } from '../../utils/userLevelEnum';
import { PostmanMonitoringConfig } from './Interface/PostmanMonitoringConfig';

interface Props {
  config: PostmanMonitoringConfig;
}

const useStyles = makeStyles(theme => ({
  monitoringStatus: {
    padding: theme.spacing(5, 3),
    textAlign: 'center',
    height: '100%',
    '& h3': {
      marginTop: theme.spacing(2)
    }
  },
  statusActive: { color: 'green' },
  statusInactive: {
    color: '#a33'
  }
}));

const PostmanConfigCards: React.FC<Props> = ({ config }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  const [monitoringEnabled, setmonitoringEnabled] = useState<boolean>(config.enabled);
  const { appInContext } = useContext(AppContext);

  const editPermission = appInContext.userLevel >= UserLevels.adminUser;

  const changeMonitoringStatus = async () => {
    try {
      let response = await EditPostmanMonitoringStatus(!monitoringEnabled, appInContext.appId);
      if (response.status == 200) {
        setmonitoringEnabled(prev => !prev);
      }
    } catch (e) {
      throw new Error(e);
    }
  };
  return (
    <Grid item container direction="row" alignItems="stretch" spacing={isSm ? 3 : 6}>
      <Grid item xs={12} sm={6} md={4}>
        <Card className={classes.monitoringStatus}>
          <Typography variant="h2" component="h3" className={monitoringEnabled ? classes.statusActive : classes.statusInactive}>
            {monitoringEnabled ? 'Enabled' : 'Disabled'}
          </Typography>
          <Typography variant="h3">API Monitoring </Typography>
          <Switch checked={monitoringEnabled} color="primary" onClick={changeMonitoringStatus} disabled={!editPermission} />
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Card className={classes.monitoringStatus}>
          <Typography variant="h2" component="h3">
            {config.monitoringRate} {config.monitoringRate != 1 ? 'mins' : 'min'}
          </Typography>
          <Typography variant="h3">Monitoring Interval</Typography>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Card className={classes.monitoringStatus}>
          {config.environmentUploaded ? (
            <Typography variant="h2" component="h3" className={classes.statusActive}>
              Yes
            </Typography>
          ) : (
            <Typography variant="h2" component="h3" className={classes.statusInactive}>
              No
            </Typography>
          )}
          <Typography variant="h3">Environment Uploaded</Typography>
        </Card>
      </Grid>
    </Grid>
  );
};

export default PostmanConfigCards;
