import React, { createContext, useState } from 'react';
import { User } from '../models/userModel';
import { UserContextModel } from './interfaces/userContextModel';

const UserContext = createContext<UserContextModel>({
  defaultChart: false,
  setDefaultChart: () => {/* Empty */},
  loggedInUser: null,
  setLoggedInUser: () => {/* Empty */},
  user: null,
  setUser: () => {/* Empty */},
  newPasswordRequired: false,
  setNewPasswordRequired: () => {/* Empty */},
  profileUpdateRequired: false,
  setProfileUpdateRequired: () => {/* Empty */},
  resetPassword: false,
  setResetPassword: () => {/* Empty */},
});

const UserProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [loggedInUser, setLoggedInUser] = useState<User | null>(null);
  const [newPasswordRequired, setNewPasswordRequired] = useState<boolean | null>(null);
  const [profileUpdateRequired, setProfileUpdateRequired] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [defaultChart, setDefaultChart] = useState(true);

  return (
    <UserContext.Provider
      value={{
        loggedInUser,
        setLoggedInUser,
        newPasswordRequired,
        setNewPasswordRequired,
        resetPassword,
        setResetPassword,
        user,
        setUser,
        defaultChart,
        setDefaultChart,
        profileUpdateRequired,
        setProfileUpdateRequired
      }}
    >
      {children}
    </UserContext.Provider>
  );
};



export { UserProvider, UserContext };
