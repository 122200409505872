import { Modal, Backdrop, Typography, makeStyles, Button, Grid } from '@material-ui/core';
import React from 'react';

interface Props {
  open: boolean;
  onClose: () => void;
  confirmDelete: () => void;
  urlName: string;
}

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[0],
    padding: theme.spacing(3),
    outline: 'none',
    border: 'solid 1px rgba(35, 0, 30, 0.2)',
    borderRadius: '5px',
    '&:focus': {
      outline: 'none'
    },
    minWidth: '30vw'
  },
  title: {
    marginBottom: '16px'
  },
  bodyText: { marginBottom: '8px' },
  button: {
    marginTop: '1rem',
    marginRight: '1.8%'
  }
}));

const ConfirmationModal: React.FC<Props> = ({ open, onClose, urlName, confirmDelete }) => {
  const classes = useStyles();
  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <div className={classes.paper}>
        <Typography variant="h4" className={classes.title}>
          Confirm Delete URL
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          Are you sure you want to delete the following url: <strong>{urlName}</strong>
        </Typography>
        <Grid item container alignItems="center" justify="flex-end" xs={12}>
          <Button type="reset" onClick={onClose} className={classes.button}>
            Cancel
          </Button>
          <Button type="submit" className={classes.button} onClick={confirmDelete}>
            Confirm
          </Button>
        </Grid>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
