const timeoutBatchMs = 1000000000;

/**
 * Helper function to set a timeout for a potentially extremely large delay.
 * setTimeout has a limitation on delays which amounts to around 24 days:
 * https://stackoverflow.com/questions/3468607/why-does-settimeout-break-for-large-millisecond-delay-values
 * @param callback 
 * @param delayMs 
 * @returns Cancel function
 */
export function setLongTimeout(callback: () => void, delayMs: number): () => void {
  let currentHandle: number | undefined;
  const timeFinishMs = Date.now() + delayMs;

  function startTimeout() {
    const timeRemainingMs = timeFinishMs - Date.now();
    const nextDelayMs = Math.min(timeRemainingMs, timeoutBatchMs);

    console.log(`Long timeout: ${timeRemainingMs}ms remaining, next timeout for ${nextDelayMs}ms`);

    currentHandle = setTimeout(() => onTimeoutComplete(), nextDelayMs) as unknown as number;
  }

  function onTimeoutComplete() {
    if (Date.now() >= timeFinishMs) {
      callback();
    } else {
      startTimeout();
    }
  }

  startTimeout();

  return function cancel() {
    clearTimeout(currentHandle);
  }
}

export class ScheduledTimer {
  private _time: number | undefined;
  private _cancelLongTimeout: (() => void) | undefined;

  public constructor(
    private readonly _callback: () => void
  ) {}

  public get time() {
    return this._time;
  }
  public set time(value: number | undefined) {
    if (value === this._time) {
      return;
    }

    this._time = value;

    this._cancelLongTimeout?.();

    if (this._time) {
      this._cancelLongTimeout = setLongTimeout(
        () => this._callback(),
        this._time - Date.now()
      );
    }
  }
}