import React, { useState, useContext, useEffect } from 'react';
import {
  Backdrop,
  Button,
  Chip,
  Fade,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  makeStyles,
  MenuItem,
  Modal,
  Select,
  TextField,
  Theme,
  Typography,
  useTheme,
  Switch
} from '@material-ui/core';
import { AddEndpointRequest } from './Interface/AddEndpointRequest';
import { AddNewEndpoint } from '../../api-queries/apiMonitoring';
import ConfirmationSnackbar from '../common/Confirmation/confirmationSnackbar';
import statusCodes from './statusCodes';
import { HttpMethodsEnum } from '../../models/enums/httpMethodsEnum';
import { AppContext } from '../../contexts/AppContext';
import { disabledSwitchExplanation, checkSwitchIsDisabled } from '../../utils/authEndpointSwitchUtils';

interface Props {
  appId: string;
  open: boolean;
  refresh: Function;
  toggleOpen(): void;
}

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[0],
    padding: theme.spacing(3),
    outline: 'none',
    border: 'solid 1px rgba(35, 0, 30, 0.2)',
    borderRadius: '5px',
    '&:focus': {
      outline: 'none'
    },
    maxWidth: '400px'
  },
  formTitle: {
    marginBottom: '5%'
  },
  button: {
    marginRight: '2%'
  },
  textField: {
    display: 'block',
    width: '100%'
  },
  input: {
    width: '100%',
    height: '30pX'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    display: 'block'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  select: {
    width: '100%'
  },
  note: {
    marginTop: '15px',
    marginBottom: '15px'
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
  variant: 'menu' as const
};

function getStyles(code: number, statusCode: number[], theme: Theme) {
  return {
    fontWeight: statusCode.indexOf(code) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightBold
  };
}

const AddEndpoint: React.FC<Props> = ({ appId, open, toggleOpen, refresh }) => {
  const { appInContext } = useContext<any>(AppContext);
  const defaultEndpoint: AddEndpointRequest = {
    appId,
    name: '',
    url: '',
    method: '',
    rejectedStatusCodes: statusCodes,
    requiresAuth: false
  };
  const classes = useStyles();
  const theme = useTheme();
  const [endpoint, setEndpoint] = useState<AddEndpointRequest>(defaultEndpoint);
  //confirmation snackbar state
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [type, setType] = useState<string>();

  const handleChangeMethod = (event: any): void => {
    setEndpoint({ ...endpoint, method: event.target.value });
  };

  const handleChangeStatusCodes = (event: any): void => {
    setEndpoint({ ...endpoint, rejectedStatusCodes: event.target.value });
  };

  const handleClear = (): void => {
    setEndpoint(defaultEndpoint);
    toggleOpen();
  };

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    try {
      const response = await AddNewEndpoint(endpoint);
      if (response.status === 200) {
        setOpenConfirmation(true);
        setType('success');
        handleClear();
        refresh(true);
      } else {
        setOpenConfirmation(true);
        setType('error');
      }
    } catch (e) {
      throw new Error(e);
    }
  };

  const handleAuthChange = () => {
    setEndpoint({ ...endpoint, method: HttpMethodsEnum.get, requiresAuth: !endpoint.requiresAuth });
  };

  return (
    <Modal
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      className={classes.modal}
      open={open}
      onClose={toggleOpen}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Fade in={open}>
        <form className={classes.paper} onSubmit={handleSubmit}>
          <div className={classes.formTitle}>
            <Typography variant="h5">Add a new endpoint</Typography>
          </div>
          <TextField
            required
            onChange={e => setEndpoint({ ...endpoint, name: e.target.value })}
            className={classes.textField}
            id="outlined-basic"
            label="Name"
            variant="outlined"
            fullWidth
            margin="dense"
            value={endpoint.name}
          />
          <TextField
            required
            onChange={e => setEndpoint({ ...endpoint, url: e.target.value })}
            className={classes.textField}
            id="outlined-basic"
            label="Url"
            variant="outlined"
            fullWidth
            margin="dense"
            value={endpoint.url}
          />
          <Typography>Endpoint requires authorisation:</Typography>
          <Switch disabled={checkSwitchIsDisabled(appInContext)} checked={endpoint.requiresAuth} name="needsAuthSwitch" onChange={handleAuthChange} />
          {checkSwitchIsDisabled(appInContext) && <Typography>{disabledSwitchExplanation}</Typography>}
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-helper-label">Method:</InputLabel>
            <Select
              required
              className={classes.select}
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={endpoint.method}
              onChange={handleChangeMethod}
              disabled={endpoint.requiresAuth}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={HttpMethodsEnum.get}>Get</MenuItem>
              <MenuItem value={HttpMethodsEnum.post}>Post</MenuItem>
              <MenuItem value={HttpMethodsEnum.patch}>Patch</MenuItem>
              <MenuItem value={HttpMethodsEnum.delete}>Delete</MenuItem>
            </Select>
            <FormHelperText>Select a HTTP Method from the list above</FormHelperText>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-mutiple-chip-label">Rejected Status Codes</InputLabel>
            <Select
              required
              className={classes.select}
              labelId="demo-mutiple-chip-label"
              id="demo-mutiple-chip"
              multiple
              value={endpoint.rejectedStatusCodes}
              onChange={handleChangeStatusCodes}
              input={<Input id="select-multiple-chip" />}
              renderValue={(selected: any) => (
                <div className={classes.chips}>
                  {selected.map((value: any) => (
                    <Chip key={value} label={value} className={classes.chip} />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {statusCodes.map(code => (
                <MenuItem key={code} value={code} style={getStyles(code, endpoint.rejectedStatusCodes, theme)}>
                  {code}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>Select one or more status codes from the list above</FormHelperText>
          </FormControl>
          <Button type="submit" className={classes.button}>
            Submit
          </Button>
          <Button type="reset" onClick={handleClear} className={classes.button}>
            Cancel
          </Button>
          <ConfirmationSnackbar open={openConfirmation} setOpen={setOpenConfirmation} type={type} />
        </form>
      </Fade>
    </Modal>
  );
};

export default AddEndpoint;
