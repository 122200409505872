import Amplify from 'aws-amplify';
import React from 'react';
import ReactDOM from 'react-dom';
// import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { UserProvider } from './contexts/UserContext';
import './styles/index.css';

const {
  cognito: { region, userPoolId, identityPoolId, userPoolWebClientId }
} = require('./config/variables.json');

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region,
    userPoolId,
    identityPoolId,
    userPoolWebClientId
  }
});

ReactDOM.render(
  <UserProvider>
    <Router>
      <App />
    </Router>
  </UserProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
