import * as React from 'react';
import iconSize from './iconSizes';

function SvgProfileIcon(props) {
  return (
    <svg id="ProfileIcon_svg__Layer_1" data-name="Layer 1" viewBox="0 0 24 24" {...props} height={iconSize(props.size)} width={iconSize(props.size)}>
      <defs>
        <style>{'.ProfileIcon_svg__cls-2{fill:none;stroke:#3c3c3b;stroke-miterlimit:10;stroke-width:.75px;stroke-linecap:round}'}</style>
      </defs>
      <circle cx={12} cy={12} r={10} fill="none" stroke="#3c3c3b" strokeMiterlimit={10} strokeWidth={0.75} />
      <circle className="ProfileIcon_svg__cls-2" cx={12} cy={10} r={3} />
      <path className="ProfileIcon_svg__cls-2" d="M8 17a4 4 0 018 0" />
    </svg>
  );
}

export default SvgProfileIcon;
