import { Paper, Grid, makeStyles, Typography } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import React, { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';
import { UserLevels } from '../../utils/userLevelEnum';

interface Props {
  url: string;
  expiryDate: Date;
  daysUntilExpiry: number;
  deleteUrl: (url: string) => void;
  newUrl: boolean;
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    marginBottom: '12px',
    padding: theme.spacing(2, 3)
  },
  urlDetails: {
    '& p': {
      [theme.breakpoints.up('sm')]: {
        paddingLeft: '24px',
        marginLeft: '-24px',
        borderLeft: '1px solid',
        borderColor: theme.palette.grey[300]
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: '0',
        borderLeft: '0px transparent',
        marginTop: '12px'
      }
    }
  },
  daysLeftCard: {
    minWidth: '35%',
    padding: '8px',
    borderRadius: '12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& p': {
      border: '0px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '8px',
      marginTop: '12px',
      '& p': {
        padding: '0px',
        margin: '0px'
      }
    }
  },
  daysLeftText: {
    color: 'white'
  },
  deleteBtn: {
    color: '#D2222D',
    cursor: 'pointer'
  },
  red: {
    backgroundColor: '#D2222D'
  },
  amber: {
    backgroundColor: '#FFBF00'
  },
  green: {
    backgroundColor: '#2BB02B'
  },
  newUrl: {
    backgroundColor: 'white',
    animation: '$newUrlAnimation 1s ease-out'
  },
  '@keyframes newUrlAnimation': {
    '0%': { backgroundColor: 'orange' },
    '100%': { backgroundColor: 'white' }
  }
}));

const UrlListItem: React.FC<Props> = ({ url, expiryDate, daysUntilExpiry, deleteUrl, newUrl }) => {
  const classes = useStyles();
  let daysLeftClass = classes.green;
  let backgroundClass = `${classes.paper}`;

  const { appInContext } = useContext(AppContext);
  const editPermission = appInContext.userLevel >= UserLevels.adminUser;

  if (daysUntilExpiry <= 30) {
    daysLeftClass = classes.red;
  } else if (daysUntilExpiry <= 60) {
    daysLeftClass = classes.amber;
  }

  const handleDeleteUrl = () => {
    deleteUrl(url);
  };

  if (newUrl) {
    backgroundClass = `${classes.paper} ${classes.newUrl}`;
  }

  return (
    <Paper className={backgroundClass}>
      <Grid container item xs={12}>
        <Grid item container alignItems="center" xs={12} md={4}>
          <Typography noWrap>
            <strong>{url}</strong>
          </Typography>
        </Grid>
        <Grid item container alignItems="center" justify="flex-end" xs={12} md={8} className={classes.urlDetails}>
          <Grid item xs={12} md={5}>
            <Typography>
              <strong>Expiry Date:</strong> {expiryDate.toDateString()}
            </Typography>
          </Grid>
          <Grid item container xs={12} md={5} justify="center">
            <div className={`${daysLeftClass} + ${classes.daysLeftCard}`}>
              <Typography className={classes.daysLeftText}>{daysUntilExpiry} Days Left </Typography>
            </div>
          </Grid>
          {editPermission && (
            <Grid item container xs={12} md={1} justify="flex-end" alignItems="center">
              <Delete className={classes.deleteBtn} onClick={handleDeleteUrl} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default UrlListItem;
