const userLevels: Record<string, number> = {
	'Super User': 4,
	'Account Holder': 3,
	'Admin User': 2,
	'Basic User': 1
};

export enum UserLevels {
	superUser = 4,
	accountHolder = 3,
	adminUser = 2,
	basicUser = 1
};

const getUserLevelAsString = (value?: number) => {
	//default display as basic user
	if (!value) return Object.keys(userLevels).find(key => userLevels[key] === 1);
	return Object.keys(userLevels).find(key => userLevels[key] === value);
};

const minimumAccessRequirements = {
	manage: userLevels['Admin User'],
	appDelete: userLevels['Account Holder'],
	companyManage: userLevels['Account Holder'],
	endpointManage: userLevels['Admin User'],
	addUser: userLevels['Super User'],
	viewCompanyPage: userLevels['Admin User'],
	accessToAllUserLevels: userLevels['Super User']
};

export {userLevels, getUserLevelAsString, minimumAccessRequirements};
