import React, { useState, useEffect } from 'react';
import { getCompany } from '../../../api-queries/companyManagement';
import { Company } from '../Models/CompanyModel';
import ApartmentIcon from '@material-ui/icons/Apartment';
import { makeStyles, Theme, Typography, Card, CardContent, Chip, Grid } from '@material-ui/core';
import { getUsersByCompanyId } from '../../../api-queries/userManagement';
import { getAppsByCompanyId } from '../../../api-queries/appManagement';
import { User } from '../../../models/userModel';
import { App } from '../../AppList/AppModel';
import AppsTable from './appsTable';
import UsersTable from './usersTable';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  companyName: {
    display: 'flex',
    alignItems: 'center'
  },
  root: {
    flexGrow: 1,
    width: '100%',
    padding: theme.spacing(3),
    paddingTop: theme.spacing(6)
  }
}));

const CompanyManagement = () => {
  const classes = useStyles();
  const emptyCompany: Company = {
    appIds: [],
    companyId: '',
    companyContactEmail: '',
    companyContactName: '',
    companyDescription: '',
    companyName: '',
    userPermissions: []
  };
  const [company, setCompany] = useState<Company>(emptyCompany);
  const [users, setUsers] = useState<User[]>([]);
  const [apps, setApps] = useState<App[]>([]);
  const { companyId } = useParams<{ companyId: string; }>();

  useEffect(() => {
    const fetchCompany = async (): Promise<void> => {
      try {
        const response = await getCompany(companyId);
        const data = await response.json();
        if (response.status === 200) {
          setCompany(data.companies[0]);
        }
      } catch (e) {
        console.log(e);
      }
    };
    const fetchUsers = async (): Promise<void> => {
      try {
        const response = await getUsersByCompanyId(companyId);
        const data = await response.json();
        if (response.status === 200) {
          setUsers(data.users);
        }
      } catch (e) {
        console.log(e);
      }
    };
    const fetchApps = async (): Promise<void> => {
      try {
        const response = await getAppsByCompanyId(companyId);
        const data = await response.json();
        if (response.status === 200) {
          setApps(data.apps);
        }
      } catch (e) {
        console.log(e);
      }
    };

    fetchUsers();
    fetchApps();
    fetchCompany();
  }, [setCompany, companyId]);

  return (
    <div className={classes.root}>
      <>
        <div className={classes.companyName}>
          <ApartmentIcon />
          <Typography variant="h3">{company.companyName}</Typography>
        </div>
        <Chip label={company.companyDescription} />
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item lg={4}>
                <div>Account Holder: {company.companyContactName}</div>
              </Grid>
              <Grid item lg={4}>
                <div>Total Users: {company.userPermissions.length}</div>
              </Grid>
              <Grid item lg={4}>
                <div>Number of Apps: {company.appIds.length}</div>
              </Grid>
              <Grid item lg={6} md={12}>
                <UsersTable users={users} setUsers={setUsers} companyId={companyId} company={company} />
              </Grid>
              <Grid item lg={6} md={12}>
                <AppsTable apps={apps} setApps={setApps} companyId={companyId} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </>
    </div>
  );
};
export default CompanyManagement;
