import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}));

export default function ConfirmationSnackbar({ timeout, text, open, setOpen, type }) {
  const classes = useStyles();

  const messages = {
    success: 'Success',
    error: 'Something went wrong'
  };

  return (
    <div className={classes.root}>
      <Snackbar
        open={open}
        autoHideDuration={timeout}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Alert severity={type}>{text || messages[type] || null}</Alert>
      </Snackbar>
    </div>
  );
}

ConfirmationSnackbar.defaultProps = {
  text: null,
  timeout: 6000
};
