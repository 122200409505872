import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
      display: 'flex'
    }
  },
  content: {
    paddingTop: theme.spacing(12),
    [theme.breakpoints.up('sm')]: {
      flexGrow: 1,
      padding: theme.spacing(3),
      paddingTop: theme.spacing(12)
    }
  }
}));

export default function NotFound() {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <main className={classes.content}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h1>Sorry, page not found!</h1>
          </Grid>
        </Grid>
      </main>
    </section>
  );
}
