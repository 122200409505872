import { SiPostman } from 'react-icons/si';
import { useTheme } from '@material-ui/core';
import { IconContext } from 'react-icons';
import * as React from 'react';
import iconSize from './iconSizes';

function PostmanIcon(props) {
  const theme = useTheme();
  const size = props.size ? props.size : '24px';

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <IconContext.Provider value={{ size: `${size}` }}>
        <SiPostman />
      </IconContext.Provider>
    </div>
  );
}

export default PostmanIcon;
