import React, { useContext, useEffect, useState } from 'react';
import PageTitle from '../PageTitle';
import { Button, Fade, Grid, makeStyles, useTheme } from '@material-ui/core';
import SubTitle from '../SubTitles';
import URLsList from './URLsList';
import AddUrl from './AddUrl';
import ConfirmationModal from './ConfirmationModal';
import { UrlObject } from './interface/UrlObject';
import { AppContext } from '../../contexts/AppContext';
import { SslData } from './interface/SslData';
import ConfirmationSnackbar from '../common/Confirmation/confirmationSnackbar';
import { DeleteURL } from '../../api-queries/sslMonitoring';
import { UserLevels } from '../../utils/userLevelEnum';
import ProgressBar from '../common/ProgressBar';

const useStyles = makeStyles(theme => ({
  subtitle: {
    marginTop: '32px',
    marginBottom: '8px'
  },
  button: {
    marginTop: '1rem'
  }
}));

const SslMonitoring: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();

  const [checked] = useState(true);
  const { appInContext } = useContext(AppContext);

  //Confirm Delete Modal State
  const [deleteUrlModalOpen, setDeleteUrlModalOpen] = useState<boolean>(false);

  //Add URL Modal State
  const [addUrlModalOpen, setAddUrlModalOpen] = useState<boolean>(false);

  const [urlToDelete, setUrlToDelete] = useState<string>('');
  const [newUrl, setNewUrl] = useState('');

  const [sslMonitoring, setSslMonitoring] = useState<SslData>(appInContext.sslInformation!);
  const [urlsList, setUrlsList] = useState<UrlObject[]>([]);

  //Snackbar State
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [type, setType] = useState<string>();

  const editPermission = appInContext.userLevel >= UserLevels.adminUser;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (sslMonitoring) {
      setUrlsList(
        sslMonitoring.urls.map(sslItem => {
          return {
            ...sslItem,
            expiry: new Date(sslItem.expiry),
            daysUntilExpiry: Math.floor((sslItem.expiry - Date.now()) / (1000 * 60 * 60 * 24))
          };
        })
      );
      setLoading(false);
    }
  }, [sslMonitoring]);

  const openDeleteUrlModal = (url: string) => {
    setUrlToDelete(url);
    setDeleteUrlModalOpen(true);
  };

  const deleteURL = async () => {
    setDeleteUrlModalOpen(false);
    try {
      const response = await DeleteURL(urlToDelete, appInContext.appId);
      if (response.ok) {
        setUrlsList(urlsList.filter(item => item.url != urlToDelete));
        appInContext.sslInformation!.urls = appInContext.sslInformation!.urls.filter(item => item.url != urlToDelete);
        showSuccessMessage();
      } else {
        setType('error');
        setOpenConfirmation(true);
      }
    } catch (e) {
      throw new Error(e);
    }
  };

  const handleNewUrl = (urlName: string) => {
    setNewUrl(urlName);
    setSslMonitoring({ ...appInContext.sslInformation! });
  };

  const showSuccessMessage = () => {
    setType('success');
    setOpenConfirmation(true);
  };

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <PageTitle text={'SSL Monitoring'} />
      </Grid>
      <Grid item xs={12}>
        <Button onClick={() => setAddUrlModalOpen(true)} className={classes.button} disabled={!editPermission}>
          Add URL +
        </Button>
      </Grid>
      <Grid item xs={12} className={classes.subtitle}>
        <SubTitle text={'URLs'} />
      </Grid>
      <Fade in={checked} {...(checked ? { timeout: 300 } : {})}>
        <Grid item xs={12}>
          {urlsList && <URLsList urls={urlsList} deleteUrl={openDeleteUrlModal} newUrlName={newUrl} />}
          {urlsList.length <= 0 && <p>It looks like you haven't added any URLs yet, please click 'Add URL' above to to start</p>}
          {loading && <ProgressBar />}
        </Grid>
      </Fade>
      <Grid item xs={12}>
        <ConfirmationModal open={deleteUrlModalOpen} onClose={() => setDeleteUrlModalOpen(false)} urlName={urlToDelete} confirmDelete={deleteURL} />
        <AddUrl open={addUrlModalOpen} onClose={() => setAddUrlModalOpen(false)} update={handleNewUrl} onSuccess={showSuccessMessage} />
        <ConfirmationSnackbar open={openConfirmation} setOpen={setOpenConfirmation} type={type} />
      </Grid>
    </Grid>
  );
};

export default SslMonitoring;
