import { Options } from './models/optionsModel';
import { LocalStorageItemEnum } from '../models/enums/localStorageItemEnum';

const { REACT_APP_BACKEND_API_URL } = process.env;

const getOptions = (method: string): Options => {
  const idToken = localStorage.getItem(LocalStorageItemEnum.idToken) as string;
  const options: Options = {
    method: `${method}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: idToken
    }
  };

  return options;
};

export const AddNewCollection = (collection: string, appId: string): Promise<Response> => {
  const options = getOptions('POST');
  return fetch(`${REACT_APP_BACKEND_API_URL}/monitoring/${appId}/collection`, { ...options, body: collection });
};

export const AddNewEnvironment = (environment: string, appId: string): Promise<Response> => {
  const options = getOptions('POST');
  return fetch(`${REACT_APP_BACKEND_API_URL}/monitoring/${appId}/environment`, { ...options, body: environment });
};

export const EditMonitoringRate = (newInterval: number, appId: string): Promise<Response> => {
  const options = getOptions('POST');
  return fetch(`${REACT_APP_BACKEND_API_URL}/monitoring/${appId}/rate`, {
    ...options,
    body: JSON.stringify({
      monitoringRate: newInterval
    })
  });
};

export const EditPostmanMonitoringStatus = (status: boolean, appId: string): Promise<Response> => {
  const options = getOptions('POST');
  return fetch(`${REACT_APP_BACKEND_API_URL}/monitoring/${appId}/rate`, {
    ...options,
    body: JSON.stringify({
      enabled: status
    })
  });
};
