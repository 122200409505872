import * as React from 'react';
import iconSize from './iconSizes';

function SvgUploadButton(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={iconSize(props.size)} height={iconSize(props.size)} {...props}>
      <defs>
        <filter id="uploadButton_svg__a">
          <feColorMatrix
            in="SourceGraphic"
            type="matrix"
            values="0   0   0   0   0
                     0   0   0   0   0
                     0   0   0   0   0
                     1   1   1   1   0"
          />
        </filter>
      </defs>
      <g filter="url(#uploadButton_svg__a)" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <g stroke="#3C3C3B">
          <path d="M11.927 15.875V3.817m2.803 2.87l-2.799-3.058-2.708 2.967M21.875 16.8v2.85c0 .78-.637 1.414-1.422 1.414H3.297a1.419 1.419 0 01-1.422-1.414V16.8" />
        </g>
      </g>
    </svg>
  );
}

export default SvgUploadButton;
