import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Card, CardContent, Button, Typography, makeStyles } from '@material-ui/core';
import { App } from '../AppModel';
import AppLocationState from '../../../models/appLocationState';
import { ApiMonitoringIcon, PostmanIcon } from '../../../Icons';
import SslIcon from '../../../Icons/SslIcon';

const useStyles = makeStyles(theme => ({
  card: {
    height: 250
  },
  icon: {
    marginTop: 20
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  boldText: {
    fontWeight: 700
  },
  text: {
    marginBottom: '0.5rem',
    marginRight: '2%'
  },
  title: {
    [theme.breakpoints.only('xs')]: {
      fontSize: '1.5rem',
      fontWeight: 600,
      marginBottom: '5%'
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '1.8rem',
      fontWeight: 600,
      marginBottom: '2%'
    }
  },
  servicesActiveCard: {
    padding: '0.15rem 0.5rem',
    maxWidth: '160px',
    borderRadius: '0.4rem',
    marginBottom: '0.5rem',
    border: '1px solid grey',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-center'
  },
  servicesActiveText: {
    fontSize: '0.8rem',
    marginLeft: '0.3rem'
  },
  servicesActiveDisabled: { opacity: 0.3 },
  linkStyle: { textDecoration: 'none', color: 'black' }
}));

interface Props {
  app: App;
}

const AppCard: React.FC<Props> = ({ app }) => {
  const classes = useStyles();
  const { appName, appId } = app;

  const appHomepage = app.servicesActive.apiMonitoring ? `/app/${appId}/api-monitoring` : `/app/${appId}/app-overview`;

  const apiMonitoringActive = app.servicesActive.apiMonitoring && app.apiEndpoints && app.apiEndpoints.length > 0;
  const postmanMonitoringActive =
    app.postmanMonitoringDetails &&
    app.postmanMonitoringDetails.enabled &&
    app.postmanMonitoringDetails.environmentUploaded &&
    app.postmanMonitoringDetails.endpoints.length > 0;
  const sslMonitoringActive = app.sslInformation && app.sslInformation.urls.length > 0;

  return (
    <Grid item xs={12} sm={6} lg={4}>
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <div>
            <Typography variant="h4" component="h2" noWrap={true} className={classes.title}>
              {appName}
            </Typography>
            <div>
              {app.servicesActive && (
                <Typography className={classes.text}>
                  <span className={classes.boldText}>Active Services: </span>
                </Typography>
              )}
              <div style={{ marginBottom: '1rem' }}>
                <Link
                  to={{ pathname: `/app/${appId}/api-monitoring`, state: new AppLocationState(app) }}
                  className={`${classes.servicesActiveCard} ${!apiMonitoringActive && classes.servicesActiveDisabled} ${classes.linkStyle}`}
                >
                  <ApiMonitoringIcon style={{ width: '16px', height: '16px' }} />
                  <Typography noWrap className={classes.servicesActiveText}>
                    API Monitoring
                  </Typography>
                </Link>
                <Link
                  to={{ pathname: `/app/${appId}/postman-monitoring`, state: new AppLocationState(app) }}
                  className={`${classes.servicesActiveCard} ${!postmanMonitoringActive && classes.servicesActiveDisabled} ${classes.linkStyle}`}
                >
                  <PostmanIcon size={'16px'} />
                  <Typography noWrap className={classes.servicesActiveText}>
                    Postman Monitoring
                  </Typography>
                </Link>
                <Link
                  to={{ pathname: `/app/${appId}/ssl-monitoring`, state: new AppLocationState(app) }}
                  className={`${classes.servicesActiveCard} ${!sslMonitoringActive && classes.servicesActiveDisabled} ${classes.linkStyle}`}
                >
                  <SslIcon size={'16px'} />
                  <Typography noWrap className={classes.servicesActiveText}>
                    SSL Monitoring
                  </Typography>
                </Link>
              </div>
            </div>
          </div>

          <div>
            <Button
              component={Link}
              to={{
                pathname: appHomepage,
                state: new AppLocationState(app)
              }}
            >
              <Typography>View</Typography>
            </Button>
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default AppCard;
