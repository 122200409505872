import React from 'react';
import { ApiMonitoringIcon, PostmanIcon } from '../../../Icons';
import SettingsIcon from '@material-ui/icons/Settings';
import { MenuItems } from '../../../components/common/Navigation/Models/MenuItemModel';
import SslIcon from '../../../Icons/SslIcon';

// Application dashboard menu items
const appMenuItems: MenuItems[] = [
  { icon: <ApiMonitoringIcon />, name: 'API Monitoring', url: 'api-monitoring', service: 'apiMonitoring' },
  { icon: <PostmanIcon />, name: 'Postman Monitoring', url: 'postman-monitoring', service: 'apiMonitoring' },
  { icon: <SslIcon />, name: 'SSL Monitoring', url: 'ssl-monitoring', service: 'apiMonitoring' },
  { icon: <SettingsIcon />, name: 'App Overview', url: 'app-overview', service: '' }
  // { icon: <SupportIcon />, name: 'Support', url: 'support' }
];

export default appMenuItems;
