import { makeStyles, useTheme, Modal, Backdrop, Typography, Button } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { EditMonitoringRate } from '../../api-queries/postmanMonitoring';
import { AppContext } from '../../contexts/AppContext';
import ConfirmationSnackbar from '../common/Confirmation/confirmationSnackbar';

interface Props {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const useStyles = makeStyles(theme => ({
  subtitle: {
    marginTop: '32px'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[0],
    padding: theme.spacing(3),
    outline: 'none',
    border: 'solid 1px rgba(35, 0, 30, 0.2)',
    borderRadius: '5px',
    '&:focus': {
      outline: 'none'
    },
    minWidth: '40vw'
  },
  button: {
    marginTop: '1rem',
    marginRight: '1.5%'
  },
  editCounter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    minHeight: '200px'
  },
  monitoringInterval: {
    fontSize: '48px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '22px'
    }
  },
  intervalButton: {
    padding: '8px',
    margin: '16px',
    [theme.breakpoints.down('sm')]: {
      padding: '4px',
      margin: '8px'
    }
  }
}));

const EditMonitoringInterval: React.FC<Props> = ({ open, onClose, onSuccess }) => {
  const classes = useStyles();
  const theme = useTheme();

  const { appInContext } = useContext(AppContext);
  const [monitoringInterval, setMonitoringInterval] = useState<number>(appInContext.postmanMonitoringDetails!.monitoringRate);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [type, setType] = useState<string>();

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    let currentRate = appInContext.postmanMonitoringDetails!.monitoringRate;
    if (currentRate == monitoringInterval) {
      onClose();
    } else {
      try {
        const response = await EditMonitoringRate(monitoringInterval, appInContext.appId);
        if (response.status === 200) {
          appInContext.postmanMonitoringDetails!.monitoringRate = monitoringInterval;
          onClose();
          onSuccess();
        } else {
          setOpenConfirmation(true);
          setType('error');
        }
      } catch (e) {
        throw new Error(e);
      }
    }
  };

  const handleClear = () => {
    onClose();
  };

  const decrementMonitoringInterval = () => {
    if (monitoringInterval > 1) {
      setMonitoringInterval(prev => (prev -= 1));
    }
  };

  const incrementMonitoringInterval = () => {
    if (monitoringInterval < 30) {
      setMonitoringInterval(prev => (prev += 1));
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <div className={classes.paper}>
        <Typography variant="h4">Edit Monitoring Interval</Typography>
        <form onSubmit={handleSubmit}>
          <div className={classes.editCounter}>
            <Button className={classes.intervalButton} onClick={decrementMonitoringInterval}>
              -
            </Button>
            <p className={classes.monitoringInterval}>
              {monitoringInterval} {monitoringInterval != 1 ? 'minutes' : 'minute'}
            </p>
            <Button className={classes.intervalButton} onClick={incrementMonitoringInterval}>
              +
            </Button>
          </div>
          <Button type="submit" className={classes.button} id="submit-collection">
            Submit
          </Button>
          <Button type="reset" onClick={handleClear} className={classes.button}>
            Cancel
          </Button>
          <ConfirmationSnackbar open={openConfirmation} setOpen={setOpenConfirmation} type={type} />
        </form>
      </div>
    </Modal>
  );
};

export default EditMonitoringInterval;
