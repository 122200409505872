import React, { useState } from 'react';
import { Grid, Typography, makeStyles, Button, LinearProgress } from '@material-ui/core';
import { ApiEndpointReport, EndpointBreakdown, FailedReportsOverview } from './Interface/ApiMonitoringResponse';
import FailureReportsList from './FailureReportsList';
import { getFailedReports } from '../../api-queries/apiMonitoring';

const useStyles = makeStyles(theme => ({
  details: {
    margin: theme.spacing(2, 0, 3)
  },
  container: {
    '& p': {
      [theme.breakpoints.up('md')]: {
        marginBottom: '20px'
      }
    }
  }
}));

interface Props {
  endpoint: EndpointBreakdown;
  appId: string;
}

const EndpointBreakdownCard: React.FC<Props> = ({ endpoint, appId }) => {
  const classes = useStyles();
  // failure reports State
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [failedReports, setFailedReports] = useState<ApiEndpointReport[] | null>(null);
  const [failedReportsOverview, setFailedReportsOverview] = useState<FailedReportsOverview | null>(null);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const getFailedReportsData = async () => {
    if (failedReports) {
      //If reports already downloaded dont make another request
      toggleOpen();
      return;
    }
    try {
      setLoading(true);
      const response = await getFailedReports(appId, endpoint.failedReportIds);
      const data: { failedReports: ApiEndpointReport[]; overview: FailedReportsOverview } = await response.json();
      if (response.status === 200) {
        setFailedReports(data.failedReports);
        setFailedReportsOverview(data.overview);
      }
      setLoading(false);
    } catch (e) {
      throw new Error((e as unknown) as string);
    }
    toggleOpen();
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} md={6}>
        <Typography noWrap>
          <strong>Url:</strong> {endpoint.endpointDetails.url}
        </Typography>
        <Typography>
          <strong>Rejected Status Codes: </strong>
          {endpoint.endpointDetails.rejectedStatusCodes.map((status, idx) => (
            <span key={idx}> {status + (idx < endpoint.endpointDetails.rejectedStatusCodes.length - 1 ? ',' : '')}</span>
          ))}
        </Typography>
        <Typography>
          <strong>Average Response Time:</strong> {endpoint.avgResponseTime.toFixed(2)}ms
        </Typography>
        <Typography>
          <strong>Min Response Time:</strong> {endpoint.minResponseTime}ms
        </Typography>
        <Typography>
          <strong>Timeout Rate:</strong> {endpoint.timeoutRate.toFixed(2)}%
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography>
          <strong>Max Response Time:</strong> {endpoint.maxResponseTime}ms
        </Typography>
        <Typography>
          <strong>Success Rate:</strong> {endpoint.successRate.toFixed(2)}%
        </Typography>
        <Typography>
          <strong>Total API Calls:</strong> {endpoint.totalApiCalls}
        </Typography>
        <Typography>
          <strong>Failed Responses:</strong> {endpoint.failedReportIds.length}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.details}>
        {!loading ? (
          <Button onClick={getFailedReportsData} disabled={endpoint.failedReportIds.length < 1}>
            View Reports
          </Button>
        ) : (
          <LinearProgress />
        )}
        {failedReportsOverview && (
          <FailureReportsList open={open} toggleOpen={toggleOpen} failedReports={failedReports ? failedReports : []} overview={failedReportsOverview} />
        )}
      </Grid>
    </Grid>
  );
};

export default EndpointBreakdownCard;
