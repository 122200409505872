import React, { useContext } from 'react';
import { Modal, Backdrop, Fade, Button, makeStyles, Avatar } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { UserContext } from '../../../../contexts/UserContext';
import { logout } from '../../../../api-queries/login';
import { LogOutIcon, ApplicationListIcon, ProfileIcon, ApiMonitoringIcon } from '../../../../Icons';
import BusinessIcon from '@material-ui/icons/Business';
import SslIcon from '../../../../Icons/SslIcon';
import { UserLevels } from '../../../../utils/userLevelEnum';

const useStyles = makeStyles(theme => ({
  paper: {
    maxWidth: '300px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[0],
    padding: theme.spacing(3),
    outline: 'none',
    border: 'solid 1px rgba(35, 0, 30, 0.2)',
    borderRadius: '5px',
    '&:focus': {
      outline: 'none'
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  button: {
    margin: theme.spacing(1, 'auto'),
    width: '100%'
  },
  avatar: {
    backgroundColor: '#f4984f',
    margin: theme.spacing(1, 'auto', 2)
  }
}));

interface Props {
  open: boolean;
  toggleModal(): void;
}

export const NavigationModal: React.FC<Props> = ({ open, toggleModal }) => {
  const classes = useStyles();
  const { loggedInUser, setLoggedInUser } = useContext<any>(UserContext);

  const isSuperUser = loggedInUser!.userLevel === UserLevels.superUser;
  const sslOverviewPermissions = loggedInUser.sslOverviewPermission;
  const apiOverviewPermissions = loggedInUser.apiOverviewPermission;

  const handleLogout = (): void => {
    logout(setLoggedInUser);
  };

  const disabledIconStyle = { opacity: 0.3 };

  return (
    <Modal
      aria-labelledby="Profile Menu"
      aria-describedby="Profile Menu"
      className={classes.modal}
      open={open}
      onClose={toggleModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      style={{ zIndex: 1305 }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            {loggedInUser.firstName.charAt(0)}
            {loggedInUser.lastName.charAt(0)}
          </Avatar>

          <Button size="small" className={classes.button} endIcon={<ApplicationListIcon />} component={Link} to={'/'}>
            Application List
          </Button>
          <Button
            size="small"
            className={classes.button}
            endIcon={<SslIcon />}
            component={Link}
            to={'/ssl-overview'}
            disabled={isSuperUser ? false : !sslOverviewPermissions}
          >
            SSL Overview
          </Button>
          <Button
            size="small"
            className={classes.button}
            endIcon={<ApiMonitoringIcon style={(isSuperUser ? false : !apiOverviewPermissions) ? disabledIconStyle : {}} />}
            component={Link}
            to={'/api-overview'}
            disabled={isSuperUser ? false : !apiOverviewPermissions}
          >
            API Monitoring Overview
          </Button>
          <Button size="small" className={classes.button} endIcon={<ProfileIcon />} component={Link} to={'/profile'}>
            Profile
          </Button>
          <Button size="small" className={classes.button} endIcon={<BusinessIcon style={{ fill: '#3c3c3b' }} />} component={Link} to={'/manage'}>
            Company Management
          </Button>
          <Button size="small" className={classes.button} endIcon={<LogOutIcon />} onClick={handleLogout}>
            Logout
          </Button>
        </div>
      </Fade>
    </Modal>
  );
};
