import React, { Fragment, useState } from 'react';
import { Avatar, Button, Container, TextField, Typography, Link as LinkUI, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { forgottenPasswordRequest, forgottenPasswordConfirmation } from '../../../api-queries/passwordReset';
import SvgApadmiPlus from '../../../Icons/ApadmiPlus';
import { ValidateEmail } from '../../../utils/helpers';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  link: {
    textAlign: 'left',
    fontWeight: 'bold'
  },
  logo: {
    marginTop: '20px',
    marginBottom: '20px',
    width: '120px',
    textAlign: 'center'
  },
  appName: {
    fontFamily: 'Zilla Slab, serif',
    fontSize: '60px',
    fontWeight: 700
  },
  subHeading: {
    fontFamily: 'Zilla Slab, serif',
    fontSize: '30px',
    fontWeight: 700
  },
  confirmationMessage: {
    marginTop: '20px'
  },
  apadmiLogo: {
    marginBottom: '20px'
  }
}));

export default function ResetPassword() {
  const classes = useStyles();
  const [codeSent, isCodeSent] = useState(false);
  const [renderResetForm, setRenderResetForm] = useState(false);
  const [hasResetPassword, setHasResetPassword] = useState(false);

  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [userError, setUserError] = useState('');

  const validateCodeForm = email.length > 0;
  const validateResetForm = email.length > 0 && code.length > 0 && password.length > 0 && password === confirmedPassword;

  const handleResetPassword = async event => {
    event.preventDefault();
    try {
      const response = await forgottenPasswordConfirmation(email, code, password);
      if (response.status === 200) {
        setHasResetPassword(true);
        localStorage.removeItem('idToken');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };

  const handleSendCode = async event => {
    event.preventDefault();
    try {
      const response = await forgottenPasswordRequest(email);
      if (response.status === 200) {
        setUserError('');
        isCodeSent(true);
      } else {
        setUserError('Request code not sent. Please wait a while before trying again');
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };

  const handleContinue = event => {
    event.preventDefault();
    setRenderResetForm(true);
  };

  const handleChangeEmail = ({ target: { value } }) => {
    setEmail(value);
    if (!ValidateEmail(value)) {
      setUserError('Please enter a valid email address');
    } else {
      setUserError('');
    }
  };

  function RequestCodeForm() {
    return (
      <form className={classes.form} onSubmit={!codeSent ? handleSendCode : handleContinue}>
        {!codeSent ? (
          <Fragment>
            <TextField
              onChange={handleChangeEmail}
              value={email}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              bordercolor="error.main"
              error={userError.length > 0}
            />
            {userError && (
              <Typography variant="body2" color="error">
                {userError}
              </Typography>
            )}
            <Button type="submit" disabled={!validateCodeForm} fullWidth variant="contained" color="primary" className={classes.submit} id="submit">
              Send Confirmation Code
            </Button>
            <LinkUI href="#" color="primary" className={classes.link} onClick={handleContinue}>
              Already have a reset code?
            </LinkUI>
          </Fragment>
        ) : (
          <Fragment>
            <Typography variant="h4" component="h5" align="center" paragraph={true} fontWeight="normal">
              Your confirmation code has been sent!
            </Typography>
            <Typography variant="body2" align="center" color="textSecondary">
              Please check your email <i>{`(${email})`}</i>. If the email does not arrive soon, please check your spam folder.
            </Typography>
            <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit} id="submit">
              Next
            </Button>
          </Fragment>
        )}
      </form>
    );
  }

  function RequestResetForm() {
    return (
      <form className={classes.form} onSubmit={handleResetPassword}>
        <TextField
          onChange={handleChangeEmail}
          value={email}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          bordercolor="error.main"
          error={userError.length > 0}
        />
        <TextField
          onChange={({ target: { value } }) => {
            setCode(value);
          }}
          value={code}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="code"
          label="Code"
          type="tel"
          name="code"
          autoFocus
          bordercolor="error.main"
          error={userError.length > 0}
        />
        <TextField
          onChange={({ target: { value } }) => {
            setPassword(value);
          }}
          value={password}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Set a new password"
          type="password"
          id="password"
          autoComplete="current-password"
          error={userError.length > 0}
        />
        <TextField
          onChange={({ target: { value } }) => {
            setConfirmedPassword(value);
          }}
          value={confirmedPassword}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Confirm new password"
          type="password"
          id="password"
          autoComplete="current-password"
          error={userError.length > 0}
        />
        {userError && (
          <Typography variant="body2" color="error">
            {userError}
          </Typography>
        )}

        <Button type="submit" disabled={!validateResetForm} fullWidth variant="contained" color="primary" className={classes.submit} id="submit">
          Reset Password
        </Button>
      </form>
    );
  }

  return (
    <Container className={classes.paper} maxWidth="xs">
      <SvgApadmiPlus className={classes.apadmiLogo}></SvgApadmiPlus>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5" paragraph={true}>
        Reset Password
      </Typography>
      {hasResetPassword ? (
        <div>
          <Typography className={classes.confirmationMessage}>You have successfully changed your password</Typography>
          <Button className={classes.confirmationMessage} fullWidth variant="contained" color="primary" component={Link} to={'/signin'}>
            Return to sign in page
          </Button>
        </div>
      ) : renderResetForm ? (
        RequestResetForm()
      ) : (
        RequestCodeForm()
      )}
    </Container>
  );
}
