import { BiLockAlt } from 'react-icons/bi';
import { useTheme } from '@material-ui/core';
import { IconContext } from 'react-icons';
import * as React from 'react';

function SslIcon(props) {
  const theme = useTheme();
  const size = props.size ? props.size : '24px';

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <IconContext.Provider value={{ size: `${size}` }}>
        <BiLockAlt />
      </IconContext.Provider>
    </div>
  );
}

export default SslIcon;
