import React, { useContext, useState } from 'react';
import { Button, Container, FormControlLabel, Link as LinkUI, makeStyles, TextField, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { login } from '../../../api-queries/login';
import { UserContext } from '../../../contexts/UserContext';
import SvgApadmiPlus from '../../../Icons/ApadmiPlus';
import { LocalStorageItemEnum } from '../../../models/enums/localStorageItemEnum.ts';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  placeHolder: {
    margin: theme.spacing(1),
    height: '100px',
    width: '100px',
    backgroundColor: '#bbb',
    borderRadius: '50%',
    textAlign: 'center'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(4)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#fff',
    border: 'solid 1px rgba(35, 0, 30, 0.2)',
    textTransform: 'none',
    boxShadow: 'none',
    padding: '10px'
  },
  errorMessage: {
    color: 'red'
  },
  link: {
    fontWeight: 'bold'
  },
  appName: {
    fontFamily: 'Zilla Slab, serif',
    fontSize: '60px',
    fontWeight: 700
  },
  subHeading: {
    fontFamily: 'Zilla Slab, serif',
    fontSize: '30px',
    fontWeight: 700
  },
  linkContainer: {
    textAlign: 'center'
  },
  rememberEmail: {
    margin: theme.spacing(0)
  },
  apadmiLogo: {
    marginBottom: '20px'
  }
}));

export default function SignIn() {
  const classes = useStyles();

  const { setLoggedInUser, setNewPasswordRequired, setUser, setProfileUpdateRequired, setResetPassword } = useContext(UserContext);

  setResetPassword(false);
  const savedEmail = localStorage.getItem(LocalStorageItemEnum.email);
  const [rememberEmail, setRememberEmail] = useState(Boolean(savedEmail));
  const [email, setEmail] = useState(savedEmail || '');
  const [password, setPassword] = useState('');
  const [userError, setUserError] = useState(false);

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      await login({
        email,
        password,
        setLoggedInUser,
        setNewPasswordRequired,
        setUserError,
        setUser,
        setProfileUpdateRequired
      });
    } catch (err) {
      throw new Error(err.message);
    }
  }

  return (
    <Container className={classes.paper} maxWidth="xs">
      <SvgApadmiPlus className={classes.apadmiLogo}></SvgApadmiPlus>
      <Typography className={classes.subHeading}>analytics portal</Typography>
      <form className={classes.form} onSubmit={handleSubmit}>
        <TextField
          onChange={({ target: { value } }) => {
            setEmail(value);
          }}
          value={email}
          variant="outlined"
          margin="normal"
          fullWidth
          required
          id="email"
          label="Email address"
          name="email"
          autoComplete="email"
          autoFocus
          bordercolor="error.main"
          error={userError}
        />
        <TextField
          onChange={({ target: { value } }) => {
            setPassword(value);
          }}
          variant="outlined"
          margin="normal"
          fullWidth
          required
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          error={userError}
        />
        {userError ? <p className={classes.errorMessage}>Email / password is incorrect</p> : null}
        <FormControlLabel
          className={classes.rememberEmail}
          control={
            <input
              type="checkbox"
              checked={rememberEmail}
              color="primary"
              onChange={() => {
                setRememberEmail(!rememberEmail);
                !rememberEmail ? localStorage.setItem('email', email) : localStorage.removeItem('email');
              }}
            />
          }
          label="Remember my email address"
        />
        <Button type="submit" fullWidth variant="contained" className={classes.submit} id="submit">
          Log in
        </Button>
        <div className={classes.linkContainer}>
          <LinkUI to="/reset-password" color="primary" component={Link} className={classes.link}>
            Forgot password?
          </LinkUI>
        </div>
      </form>
    </Container>
  );
}
