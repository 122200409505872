import { makeStyles } from '@material-ui/core';
import React from 'react';
import { UrlObject } from './interface/UrlObject';
import UrlListItem from './UrlListItem';

interface Props {
  urls: UrlObject[];
  deleteUrl: (url: string) => void;
  newUrlName: string;
}

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: '32px'
  }
}));

const URLsList: React.FC<Props> = ({ urls, deleteUrl, newUrlName }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {urls
        .sort((a, b) => a.daysUntilExpiry - b.daysUntilExpiry)
        .map((item, index) => {
          if (item.url == newUrlName) {
            return (
              <UrlListItem key={index} url={item.url} expiryDate={item.expiry} daysUntilExpiry={item.daysUntilExpiry} deleteUrl={deleteUrl} newUrl={true} />
            );
          } else {
            return (
              <UrlListItem
                key={index}
                url={item.url}
                expiryDate={item.expiry}
                daysUntilExpiry={item.daysUntilExpiry}
                deleteUrl={deleteUrl}
                newUrl={false}
              />
            );
          }
        })}
    </div>
  );
};

export default URLsList;
