import React, { useContext, useEffect, useMemo, useState } from 'react';
import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import AuthRoutes from './routes';
import { UserContext } from './contexts/UserContext';
import { refreshSession } from './api-queries';
import theme from './AppStyles';
import { LocalStorageItemEnum } from './models/enums/localStorageItemEnum';
import jwtDecode from 'jwt-decode';
import { CognitoAccessToken, CognitoIdToken } from './models/cognitoTokenModel';
import { ScheduledTimer } from './utils/timingUtils';
import ConfirmationSnackbar from './components/common/Confirmation/confirmationSnackbar';
import ResetScrollOnPathChange from './components/ResetScrollOnPathChange';

function App() {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [signedOutSnackbarOpen, setSignedOutSnackbarOpen] = useState(false);
  const { loggedInUser, newPasswordRequired, setLoggedInUser, resetPassword } = useContext(UserContext);
  const logoutTimer = useMemo(() =>
    new ScheduledTimer(() => {
      setLoggedInUser(null);
      setSignedOutSnackbarOpen(true);
    })
  , []);

  const accessToken = localStorage.getItem(LocalStorageItemEnum.accessToken);
  const idToken = localStorage.getItem(LocalStorageItemEnum.idToken);

  useEffect(() => {
    async function onLoad() {
      
      if (idToken && accessToken) {
        try {
          const response = await refreshSession(idToken, accessToken);
          setLoggedInUser(response.userDetails);
        } catch (e) {
          setLoggedInUser(null);
        }
      } else {
        setLoggedInUser(null);
      }
      setIsAuthenticating(false);
    }

    onLoad();
  }, []);

  useEffect(() => {
    if (!loggedInUser) {
      logoutTimer.time = undefined;
    }

    if (!accessToken || !idToken) {
      return;
    }

    const accessTokenParsed: CognitoAccessToken = jwtDecode(accessToken);
    const idTokenParsed: CognitoIdToken = jwtDecode(idToken);

    const logoutTimeSeconds = Math.min(accessTokenParsed.exp, idTokenParsed.exp);

    logoutTimer.time = logoutTimeSeconds * 1000;
  }, [loggedInUser]);

  useEffect(() => {
    if (loggedInUser && signedOutSnackbarOpen) {
      setSignedOutSnackbarOpen(false);
    }
  }, [loggedInUser, signedOutSnackbarOpen]);



  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {!isAuthenticating && <AuthRoutes appProps={{ loggedInUser, newPasswordRequired, resetPassword }} />}
      <ConfirmationSnackbar
        type="error"
        text="Your session has expired. Please sign in again"
        timeout={30000}
        open={signedOutSnackbarOpen}
        setOpen={setSignedOutSnackbarOpen}
      />
      <ResetScrollOnPathChange />
    </MuiThemeProvider>
  );
}

export default App;
