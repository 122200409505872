import React, {useContext, useEffect, useState} from 'react';
import {Avatar, CardContent, Link as LinkUI, List, ListItem, makeStyles, Typography} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {UserContext} from '../../contexts/UserContext';
import DeleteProfile from './DeleteProfile';
import EditProfile from './EditProfile';

const useStyles = makeStyles(theme => ({
	link: {
		fontWeight: 'bold',
		cursor: 'pointer'
	},
	avatar: {
		backgroundColor: '#f4984f',
		height: theme.spacing(8),
		width: theme.spacing(8)
	}
}));

const UserProfile = (): JSX.Element => {
	const classes = useStyles();
	const {setResetPassword, loggedInUser, profileUpdateRequired} = useContext<any>(UserContext);
	const [edit, setEdit] = useState<boolean>(false);
	const [deleteProfile, setDeleteProfile] = useState<boolean>(false);


	useEffect(() => {
		if (profileUpdateRequired) {
			setEdit(true);
		}
	}, [profileUpdateRequired]);

	const handleReset = (): void => {
		setResetPassword(true);
	};

	const handleEdit = (): void => {
		setEdit(!edit);
	};

	const handleDelete = (): void => {
		setDeleteProfile(!deleteProfile);
	};

	return (
		<div>
			<CardContent>
				<List>
					<ListItem>
						<Avatar className={classes.avatar}>
							{loggedInUser.firstName.charAt(0)}
							{loggedInUser.lastName.charAt(0)}
						</Avatar>
					</ListItem>
					<ListItem>
						<Typography variant="h5">
							{loggedInUser.firstName} {loggedInUser.lastName}
						</Typography>
					</ListItem>
					<ListItem>
						<Typography>{loggedInUser.email}</Typography>
					</ListItem>
					<ListItem>
						<LinkUI className={classes.link} onClick={handleEdit}>
							Edit details
						</LinkUI>
					</ListItem>
					<ListItem>
						<LinkUI className={classes.link} to={'/reset-password'} component={Link} onClick={handleReset}>
							Reset password
						</LinkUI>
					</ListItem>
					<ListItem>
						<LinkUI className={classes.link} onClick={handleDelete}>
							Delete account
						</LinkUI>
					</ListItem>
				</List>
			</CardContent>
			<EditProfile open={edit} handleClose={handleEdit}/>
			<DeleteProfile open={deleteProfile} handleClose={handleDelete}/>
		</div>
	);
};

export default UserProfile;
