import React, { useMemo, useEffect, PropsWithChildren } from "react";
import { matchPath, useHistory } from "react-router-dom";
import { fetchAppById } from "../api-queries";
import { App, emptyApp } from "../components/AppList/AppModel";
import AppLocationState from "../models/appLocationState";
import usePromiseWatcher from "../utils/usePromiseWatcher";
import { AppContext } from "./AppContext";

export default function RoutedAppProvider({ children }: PropsWithChildren<{}>) {
  const history = useHistory();
  const appMatch = useMemo(() =>
    matchPath<{ appId: string; }>(
      history.location.pathname,
      {
        path: '/app/:appId/',
        exact: false,
        strict: false,
      }
    )
  , [history.location.pathname]);
  const app = usePromiseWatcher<App | undefined>();

  const locationAppData = useMemo(() => {
    const appId = appMatch?.params.appId;
    const appFromState =
      history.location.state instanceof AppLocationState && history.location.state.app.appId === appId
      ? history.location.state.app
      : undefined;

    return {
      appId,
      app: appFromState
    }
  }, [history.location.state, appMatch?.params.appId]);

  useEffect(() => {
    if (locationAppData.appId) {

      if (locationAppData.app) {
        app.resolve(locationAppData.app);
        history.replace({
          ...history.location,
          state: undefined
        });

      } else if (locationAppData.appId !== app.value?.appId) {
        app.watch(
          fetchAppById(locationAppData.appId)
          .then(resp => {
            if (resp.apps.length <= 0) {
              throw new Error(`Got empty apps list when trying to fetch ${locationAppData.appId}`);
            }
            return resp.apps[0];
          })
        );
      }

    } else {
      app.resolve(undefined);
    }
  }, [locationAppData]);

  function setAppInContext(newValue: App) {
    if (app.value?.appId && (newValue.appId !== app.value.appId)) {
      console.error(`Attempted to update the app-in-context to a different app ID ${newValue.appId}! Was it a a late async operation?`);
      return;
    }

    app.resolve(newValue);
  }

  const effectiveApp = locationAppData.app ?? app.value ?? emptyApp;

  return (
    <AppContext.Provider 
      value={{
        appInContext: effectiveApp,
        setAppInContext: setAppInContext,
        status: app.status,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}