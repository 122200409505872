import { createContext } from 'react';
import { emptyApp } from '../components/AppList/AppModel';
import { PromiseStatus } from '../utils/usePromiseWatcher';
import { AppContextModel } from './interfaces/appContextModel';

export const AppContext = createContext<AppContextModel>({
  appInContext: emptyApp,
  setAppInContext: () => {/* Empty */},
  status: PromiseStatus.NotStarted
});
