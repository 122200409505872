/* eslint-disable max-len */
import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function ApiTimeoutRateIcon(props) {
  return (
    <SvgIcon viewBox="0 0 55 46" width="55px" height="46px" titleAccess="API Timeout Rate Icon" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M27.5,0.5 C33.7132034,0.5 39.3382034,3.01839828 43.4099026,7.09009742 C47.4816017,11.1617966 50,16.7867966 50,23 C50,29.2132034 47.4816017,34.8382034 43.4099026,38.9099026 C39.3382034,42.9816017 33.7132034,45.5 27.5,45.5 C21.2867966,45.5 15.6617966,42.9816017 11.5900974,38.9099026 C7.51839828,34.8382034 5,29.2132034 5,23 C5,22.1551834 5.04653985,21.3212383 5.13721272,20.500591 L5.13721272,20.500591 L25.4999945,20.4999851 L25.500215,0.587625008 C26.159071,0.52961537 26.8260765,0.5 27.5,0.5 Z"
          id="Combined-Shape"
          stroke="#23001E"
        ></path>
        <circle id="Oval" fill="#FFFFFF" cx="27.5" cy="23" r="22"></circle>
        <path
          d="M27.5,10 C28.3284271,10 29,10.6715729 29,11.5 L29.0012525,23.9062555 C29.0644959,23.9521738 29.1249624,24.003642 29.1819805,24.0606602 L35.5459415,30.4246212 C36.131728,31.0104076 36.131728,31.9601551 35.5459415,32.5459415 C34.9601551,33.131728 34.0104076,33.131728 33.4246212,32.5459415 L27.0606602,26.1819805 C26.9519693,26.0732897 26.8634457,25.9520685 26.7950894,25.8229669 C26.3219851,25.5720399 26,25.0736218 26,24.5 L26,11.5 C26,10.6715729 26.6715729,10 27.5,10 Z"
          id="Combined-Shape"
          fillOpacity="0.2"
          fill="#23001E"
        ></path>
        <path
          d="M20.0111531,2.04895158 C17.6875653,3.01964846 15.5909811,4.37871341 13.7214006,6.12614644"
          id="Path-7"
          stroke="#23001E"
          strokeLinecap="round"
        ></path>
        <path
          d="M11.0082653,8.95298315 C9.49400342,10.5435601 8.24829401,12.5739859 7.27113706,15.0442608"
          id="Path-8"
          stroke="#23001E"
          strokeLinecap="round"
        ></path>
        <line x1="25" y1="0.64" x2="24.874762" y2="0.66" id="Path-9" stroke="#23001E" strokeLinecap="round"></line>
        <line x1="5.21625468" y1="19.9052401" x2="5.19232322" y2="20.03" id="Path-9" stroke="#23001E" strokeLinecap="round"></line>
      </g>
    </SvgIcon>
  );
}

export default ApiTimeoutRateIcon;
