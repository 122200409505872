import { AddEndpointRequest, DeleteEndpointRequest } from '../components/ApiMonitoring/Interface/AddEndpointRequest';
import { EditEndpointRequest } from '../components/ApiMonitoring/Interface/EditEndpointRequest';
import { LocalStorageItemEnum } from '../models/enums/localStorageItemEnum';
import { Options } from './models/optionsModel';

const { REACT_APP_BACKEND_API_URL } = process.env;

const getOptions = (method: string): Options => {
  const idToken = localStorage.getItem(LocalStorageItemEnum.idToken) as string;
  const options: Options = {
    method: `${method}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: idToken
    }
  };

  return options;
};

export const getApiReports = (appId: string, start: number, end: number, clearCache?: boolean): Promise<Response> => {
  const options = getOptions('GET');
  if (clearCache) {
    options.headers = {
      ...options.headers,
      'Cache-Control': 'max-age=0'
    };
  }
  return fetch(`${REACT_APP_BACKEND_API_URL}/api-monitoring/reports?appId=${appId}&startTime=${start}&endTime=${end}&useSummaries=true`, options);
};

export const getFailedReports = (appId: string, failedReportIds: string[]): Promise<Response> => {
  const options = getOptions('POST');
  return fetch(`${REACT_APP_BACKEND_API_URL}/api-monitoring/reports/failed`, {
    ...options,
    body: JSON.stringify({ appId: appId, failedReportIds: failedReportIds })
  });
};

export const AddNewEndpoint = (endpoint: AddEndpointRequest): Promise<Response> => {
  const options = getOptions('POST');
  return fetch(`${REACT_APP_BACKEND_API_URL}/api-monitoring/endpoints`, { ...options, body: JSON.stringify(endpoint) });
};

export const deleteEndpoint = (requestBody: DeleteEndpointRequest): Promise<Response> => {
  const options = getOptions('DELETE');
  return fetch(`${REACT_APP_BACKEND_API_URL}/api-monitoring/endpoints`, { ...options, body: JSON.stringify(requestBody) });
};

export const editEndpoint = (requestBody: EditEndpointRequest): Promise<Response> => {
  const options = getOptions('PATCH');
  return fetch(`${REACT_APP_BACKEND_API_URL}/api-monitoring/endpoints`, { ...options, body: JSON.stringify(requestBody) });
};

export const getApiOverviewData = (start: number, end: number, clearCache?: boolean): Promise<Response> => {
  const options = getOptions('GET');
  if (clearCache) {
    options.headers = {
      ...options.headers,
      'Cache-Control': 'max-age=0'
    };
  }
  return fetch(`${REACT_APP_BACKEND_API_URL}/api-monitoring/overview?startTime=${start}&endTime=${end}`, options);
};
