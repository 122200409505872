/* eslint-disable max-len */
import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function ApiSuccessRateIcon(props) {
  return (
    <SvgIcon viewBox="0 0 55 46" width="55px" height="46px" titleAccess="API Sucess Rate Icon" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <circle id="Oval" stroke="#23001E" cx="27.5" cy="23" r="22.5"></circle>
        <circle id="Oval" strokeOpacity="0.2" stroke="#23001E" cx="27.5" cy="23" r="17.5"></circle>
        <path
          d="M27.5,13.1297757 L30.4007861,19.0074104 L36.8871411,19.949933 L32.1935706,24.5250335 L33.3015723,30.9851792 L27.5,27.9351121 L21.6984277,30.9851792 L22.8064294,24.5250335 L18.1128589,19.949933 L24.5992139,19.0074104 L27.5,13.1297757 Z"
          id="Star"
          stroke="#23001E"
        ></path>
      </g>
    </SvgIcon>
  );
}

export default ApiSuccessRateIcon;
