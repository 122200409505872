import { MonitoringAuth } from '../components/ApiMonitoring/Interface/ApiMonitoringAuth';
import { App } from '../components/AppList/AppModel';

const checkMonitoringAuthIsEmpty = (monitoringAuthObj: MonitoringAuth): boolean => {
  return Object.values(monitoringAuthObj).every(value => value === '');
};

const disabledSwitchExplanation = 'In order to enable this switch an Auth User must first be configured.';

const checkSwitchIsDisabled = (app: App): boolean => {
  if (!app.monitoringAuth) return true;
  if (checkMonitoringAuthIsEmpty(app.monitoringAuth)) return true;
  return false;
};

export { disabledSwitchExplanation, checkSwitchIsDisabled };
