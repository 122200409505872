/* eslint-disable max-len */
import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function ApiResTimeIcon(props) {
  return (
    <SvgIcon width="55px" height="46px" viewBox="0 0 55 46" titleAccess="API AVG Speed Icon" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M27.5,0.5 C34.9548286,0.5 41.7034849,3.5773143 46.5887124,8.55136413 C51.4771713,13.5287041 54.5,20.4050397 54.5,28 C54.5,34.5157277 52.2752715,40.5025289 48.5562603,45.2150081 C43.4244981,39.2586655 35.8931158,35.5 27.5,35.5 C19.1063842,35.5 11.5746005,39.259115 6.44222163,45.2137249 C2.72444516,40.501777 0.5,34.5153177 0.5,28 C0.5,20.4050397 3.5228287,13.5287041 8.4112876,8.55136413 C13.2965151,3.5773143 20.0451714,0.5 27.5,0.5 Z"
          id="Combined-Shape"
          stroke="#23001E"
          fill="#FFFFFF"
        ></path>
        <path
          d="M27.4677267,9.50175896 L27.4965556,9.50406368 L27.501195,9.51403574 L28.4404864,25.9715094 C28.4483538,26.1093563 28.399603,26.2373421 28.3144236,26.3328331 C28.2316128,26.4256687 28.1143711,26.4877916 27.9812506,26.4984039 L27.9812506,26.4984039 L27.0570976,26.5 C26.9190264,26.5 26.7940264,26.4440356 26.7035442,26.3535534 C26.6155835,26.2655927 26.5602436,26.1450113 26.5572275,26.0115123 L26.5572275,26.0115123 L27.4722531,9.51332158 L27.34,9.584 L27.375039,9.56088181 C27.4101814,9.53538826 27.443436,9.51009611 27.4677267,9.50175896 Z"
          id="Rectangle"
          stroke="#23001E"
          fill="#23001E"
        ></path>
        <circle id="Oval" stroke="#23001E" fill="#FFFFFF" cx="27.5" cy="28.5" r="3"></circle>
        <path
          d="M47.8006273,40.1270348 C50.2700328,35.9815481 51.3291124,31.264843 50.977866,25.9769195"
          id="Path-6"
          strokeOpacity="0.4"
          stroke="#23001E"
          strokeWidth="2"
        ></path>
        <path
          d="M44.2497463,23.1152953 C47.0334798,18.4912279 48.0904159,13.2418238 47.4205545,7.36708317"
          id="Path-6"
          strokeOpacity="0.4"
          stroke="#23001E"
          strokeWidth="2"
          transform="translate(45.932595, 15.241189) rotate(-45.000000) translate(-45.932595, -15.241189) "
        ></path>
        <path
          d="M4,40.1270348 C6.46940554,35.9815481 7.5284851,31.264843 7.17723869,25.9769195"
          id="Path-6"
          strokeOpacity="0.4"
          stroke="#23001E"
          strokeWidth="2"
          transform="translate(5.621424, 33.051977) scale(-1, 1) translate(-5.621424, -33.051977) "
        ></path>
        <path
          d="M7.19717127,22.9604274 C9.92734548,18.2579431 10.99642,13.2270323 10.4043948,7.86769517"
          id="Path-6"
          strokeOpacity="0.4"
          stroke="#23001E"
          strokeWidth="2"
          transform="translate(8.879908, 15.414061) scale(-1, 1) rotate(-45.000000) translate(-8.879908, -15.414061) "
        ></path>
        <path
          d="M24.7031156,15.9062881 C29.4744488,10.1698861 31.247591,3.6606726 30.0225421,-3.62135232"
          id="Path-6"
          strokeOpacity="0.4"
          stroke="#23001E"
          strokeWidth="2"
          transform="translate(27.550535, 6.142468) rotate(-105.000000) translate(-27.550535, -6.142468) "
        ></path>
      </g>
    </SvgIcon>
  );
}

export default ApiResTimeIcon;
