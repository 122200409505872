import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { AppTheme } from '../../AppStyles';
import clsx from 'clsx';

const useStyles = makeStyles((theme: AppTheme) => ({
  text: {
    fontSize: theme.pageTitle.fontSize,
    marginBottom: '20px',
    [theme.breakpoints.only('xs')]: {
      fontSize: '2rem'
    }
  }
}));

interface Props {
  text: string;
  className?: string;
}

export default function PageTitle({ text, className }: Props) {
  const classes = useStyles();
  return (
    <Typography
      className={clsx(
        classes.text,
        className
      )}
    >
      {text}
    </Typography>
  );
}
