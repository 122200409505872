import { Button, Typography, makeStyles, Switch, Card } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { deleteApp, updateApp } from '../../api-queries/appManagement';
import { AppContext } from '../../contexts/AppContext';
import { AppContextModel } from '../../contexts/interfaces/appContextModel';
import { UserContextModel } from '../../contexts/interfaces/userContextModel';
import { UserContext } from '../../contexts/UserContext';
import { ActiveServices } from '../AppList/AppModel';
import ConfirmationSnackbar from '../common/Confirmation/confirmationSnackbar';
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai';
import { ConfirmationGeneric } from '../common/Confirmation/confirmationGeneric';
import { useHistory } from 'react-router-dom';
import { EditAppResponse } from '../../api-queries/models/editAppResponse';
import { UserLevels } from '../../utils/userLevelEnum';
import OpsgenieConfigModal from './OpsgenieConfigModal';

const useStyles = makeStyles(theme => ({
  settingsCard: {
    minHeight: '60px',
    width: '450px',
    paddingLeft: '10px',
    marginTop: '15px',
    marginBottom: '15px',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  cardTitle: {
    lineHeight: '60px',
    fontSize: 21,
    display: 'inline-block',
    fontWeight: 700
  },
  statusActive: {
    color: 'green'
  },
  statusInactive: {
    color: '#a33'
  },
  button: {
    marginBottom: '20px'
  },
  deleteButton: {
    backgroundColor: '#ff5656',
    color: 'white',
    width: '150px',
    height: '36px',
    marginTop: '15px',
    '&:hover': {
      color: 'black',
      backgroundColor: 'white'
    }
  },
  main: {
    display: 'flex'
  },
  leftContent: {
    width: '100%'
  },
  apiKey: {
    fontSize: '17px',
    marginBottom: '16px',
    marginTop: '5px'
  },
  cardText: {
    fontSize: '18px',
    marginBottom: '16px'
  },
  opsGenieAlertsConfig: { display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', width: '100%' },
  enabled: { color: 'green' },
  disabled: { color: '#a33' },
  statusIcon: { position: 'relative', top: 7, left: 5, fontSize: '25px' },
  configButton: { marginRight: '20px' }
}));

const AppOverview = () => {
  const classes = useStyles();
  const { appInContext, setAppInContext } = useContext<AppContextModel>(AppContext);
  const [showOpsgenieConfigModal, setOpsgenieConfigModal] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openApiMonitoringConfirmation, setOpenApiMonitoringConfirmation] = useState<boolean>(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState<boolean>(false);
  const [type, setType] = useState<string>('success');
  const { setLoggedInUser, loggedInUser } = useContext<UserContextModel>(UserContext);

  const history = useHistory();

  const submitServices = async (servicesActive: ActiveServices): Promise<void> => {
    try {
      const response = await updateApp({ appId: appInContext.appId, servicesActive });
      if (response.status === 200) {
        const editAppResponse: EditAppResponse = await response.json();
        setAppInContext(editAppResponse.app);
        setType('success');
        setOpenSnackbar(true);
      } else {
        if (response.status === 401) {
          setLoggedInUser(null);
        }
        setType('error');
        setOpenSnackbar(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleApiMonitoringToggle = () => {
    appInContext.servicesActive.apiMonitoring ? setOpenApiMonitoringConfirmation(true) : toggleApiMonitoring();
  };

  const toggleApiMonitoring = () => {
    submitServices({ apiMonitoring: !appInContext.servicesActive.apiMonitoring });
  };

  const handleDeleteApp = async () => {
    try {
      const response = await deleteApp(appInContext.appId);
      if (response.status === 204) {
        history.push('/');
        setOpenSnackbar(true);
        setType('success');
      } else {
        setOpenSnackbar(true);
        setType('error');
      }
    } catch (e) {
      console.log(e);
    }
  };

  const editOpsgenieSuccess = () => {
    setOpenSnackbar(true);
    setType('success');
  };

  const isSuperUser = loggedInUser!.userLevel === UserLevels.superUser;
  const isAdminOrHigher = isSuperUser || appInContext.userLevel >= UserLevels.adminUser;

  const apiMonitoringActive = appInContext.servicesActive.apiMonitoring;
  const opsgenieConfig = appInContext.opsgenieConfig;

  const alertsEnabled = opsgenieConfig!.alertsEnabled && opsgenieConfig!.opsgenieApiKey;
  let colorClass = classes.enabled;

  const disableApiMonitoringMessage = (
    <>
      <Typography>Disabling this service will impact your reports and you will not be able to access relevant data in the future.</Typography>
      <br />
      <Typography>Your endpoints and auth user details will be saved until you re-enable monitoring.</Typography>
    </>
  );

  const deleteAppMessage = (
    <>
      <Typography>Deleting this app will cause you to lose access to all API monitoring data.</Typography>
      <br />
      <Typography>Do you wish to proceed?</Typography>
    </>
  );

  if (!alertsEnabled) {
    colorClass = classes.disabled;
  }

  return (
    <div>
      <div className={classes.main}>
        <div className={classes.leftContent}>
          <Card className={classes.settingsCard}>
            <Typography className={classes.cardTitle}>
              API Monitoring:{' '}
              <span className={apiMonitoringActive ? classes.statusActive : classes.statusInactive}>{apiMonitoringActive ? 'Enabled' : 'Disabled'}</span>
              {isAdminOrHigher && <Switch checked={apiMonitoringActive} color="primary" onClick={handleApiMonitoringToggle} />}
            </Typography>
          </Card>
          <Card className={classes.settingsCard}>
            <Typography className={classes.cardTitle}>Opsgenie Integration</Typography>
            <Typography className={classes.apiKey}>
              {opsgenieConfig!.opsgenieApiKey ? `API Key: ${opsgenieConfig!.opsgenieApiKey}` : 'No API Key Set'}
            </Typography>
            <div className={classes.opsGenieAlertsConfig}>
              <Typography className={`${classes.cardText} ${colorClass}`}>
                {alertsEnabled ? 'Alerts Enabled' : 'Alerts Disabled'}
                <span className={classes.statusIcon}>{alertsEnabled ? <AiFillCheckCircle /> : <AiFillCloseCircle />}</span>
              </Typography>
              <Button className={classes.configButton} onClick={() => setOpsgenieConfigModal(true)} disabled={!isAdminOrHigher}>
                <Typography>Configure</Typography>
              </Button>
            </div>
          </Card>
        </div>
        {isAdminOrHigher && (
          <Button onClick={() => setOpenDeleteConfirmation(true)} className={classes.deleteButton}>
            <Typography>Delete App</Typography>
          </Button>
        )}
      </div>
      <ConfirmationSnackbar open={openSnackbar} setOpen={setOpenSnackbar} type={type} />
      <ConfirmationGeneric
        onConfirm={toggleApiMonitoring}
        title={'Are you sure?'}
        message={disableApiMonitoringMessage}
        open={openApiMonitoringConfirmation}
        setOpen={setOpenApiMonitoringConfirmation}
      />
      <ConfirmationGeneric
        onConfirm={handleDeleteApp}
        title={'Are you sure you want to delete this app?'}
        message={deleteAppMessage}
        open={openDeleteConfirmation}
        setOpen={setOpenDeleteConfirmation}
      />
      <OpsgenieConfigModal
        open={showOpsgenieConfigModal}
        onSuccess={editOpsgenieSuccess}
        onClose={() => {
          setOpsgenieConfigModal(prev => !prev);
        }}
      />
    </div>
  );
};

export default AppOverview;
