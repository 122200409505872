import * as React from 'react';

function SvgApadmiSmall(props) {
  return (
    <svg width={48} height={48} {...props}>
      <g fillRule="nonzero" fill="none">
        <path d="M40.8 4.8l-.001 7.2H48v2.4h-7.201l.001 7.2h-2.4l-.001-7.2H31.2V12h7.199l.001-7.2h2.4z" fill="#F59952" />
        <path d="M19.091 4.8L37.2 42h-6.709l-2.946-6.164H9.49L6.655 42H0L17.943 4.8h1.148zm-.3 12h-.382L12 31.2h13.2l-6.409-14.4z" fill="#42454A" />
      </g>
    </svg>
  );
}

export default SvgApadmiSmall;
