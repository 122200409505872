import * as React from 'react';

export default function SvgApadmiPlus(props) {
  return (
    // preserveAspectRatio="<align> [<meetOrSlice>]"
    <svg viewBox="-5 0 200 48" preserveAspectRatio="xMinYMin meet" {...props}>
      <g fillRule="nonzero" fill="none">
        <g fill="#42454A">
          <path d="M52.272 5.133h-8.804V19.14h8.804c4.973 0 8.056-2.102 8.056-7.082 0-4.57-3.181-6.93-8.056-6.93M38 0h14.521C60.876 0 66 4.262 66 11.855c0 8.067-5.57 12.364-13.777 12.364H43.57V35h-5.52V0H38zM103.483 5.132v24.736h6.545c7.092 0 12.466-4.413 12.466-12.368s-5.39-12.368-12.466-12.368h-6.545zM110.374 35H98V0h12.367C121.108 0 128 7.08 128 17.5S121.108 35 110.367 35M159.566 12.212l-10.41 11.548h-1.258l-10.464-11.548V35H132V0h1.51l14.938 16.32L163.59 0H165v35h-5.434zM169 0h6v35h-6zM17.962 0L35 35h-6.312l-2.772-5.8H8.928L6.262 35H0L16.882 0h1.08zm-.788 11h-.348L11 24h12l-5.826-13zM78.301 10.673h.359l6.005 13.704H72.288l6.013-13.704zM89.687 35H96L78.96 0h-1.076L61 35h6.262l2.667-5.8h16.99l2.768 5.8z" />
        </g>
        <path d="M188 0v6h6v2h-6v6h-2V8h-6V6h6V0h2z" fill="#F59952" />
      </g>
    </svg>
  );
}
