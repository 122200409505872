import * as React from 'react';
import iconSize from './iconSizes';

function SvgApplicationListIcon(props) {
  return (
    <svg
      id="ApplicationListIcon_svg__Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      {...props}
      height={iconSize(props.size)}
      width={iconSize(props.size)}
    >
      <defs>
        <style>
          {
            '.ApplicationListIcon_svg__cls-1{fill:none;stroke:#3c3c3b;stroke-miterlimit:10;stroke-linecap:round;stroke-width:.75px}.ApplicationListIcon_svg__cls-3{fill:#3c3c3b}'
          }
        </style>
      </defs>
      <path
        className="ApplicationListIcon_svg__cls-1"
        d="M8.07 11H4a1 1 0 01-1-1V4a1 1 0 011-1h6a1 1 0 011 1v4M13 8V4a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1h-4.1M15.83 12.91H20a1 1 0 011 1v6a1 1 0 01-1 1h-6a1 1 0 01-1-1v-4.2"
      />
      <path
        d="M14.74 11.85A2.76 2.76 0 0112 14.61a2.72 2.72 0 01-1.77-.61 2.4 2.4 0 01-.39-.39 2.73 2.73 0 01-.63-1.74 2.77 2.77 0 115.53 0z"
        opacity={0.5}
        fill="none"
      />
      <path
        className="ApplicationListIcon_svg__cls-3"
        d="M6.31 13.49h1.11v6.43a.51.51 0 01-.51.51h-.09a.51.51 0 01-.51-.51v-6.43z"
        transform="rotate(45 6.867 16.954)"
      />
      <path className="ApplicationListIcon_svg__cls-3" d="M9.45 14a2.46 2.46 0 00.39.4l-.32.32-.39-.39z" />
      <circle cx={11.98} cy={11.85} r={3.02} strokeWidth={0.5} stroke="#3c3c3b" strokeMiterlimit={10} fill="none" />
    </svg>
  );
}

export default SvgApplicationListIcon;
