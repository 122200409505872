import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { Company } from '../Models/CompanyModel';
import { updateCompany } from '../../../api-queries/companyManagement';

interface Props {
  company: Company;
  fullCompanies: Company[];
  setCompanies: React.Dispatch<React.SetStateAction<any>>;
  setEditCompany: React.Dispatch<React.SetStateAction<any>>;
  toggleAnchorEl: () => void;
  setOpenConfirm: React.Dispatch<React.SetStateAction<any>>;
  setType: React.Dispatch<React.SetStateAction<any>>;
}

const EditCompany: React.FC<Props> = ({ company, fullCompanies, setCompanies, setEditCompany, toggleAnchorEl, setOpenConfirm, setType }): JSX.Element => {
  const [companyBeingEdited, setCompanyBeingEdited] = useState<Company>(company);

  const handleClose = (): void => setEditCompany(null);

  const open = Boolean(company);

  const handleSubmitCompany = async () => {
    try {
      const result = await updateCompany(companyBeingEdited);
      if (result.status === 200) {
        const editCompanyIndex = fullCompanies.findIndex(company => company.companyId === companyBeingEdited.companyId);
        const fullCompaniesCopy = [...fullCompanies];
        fullCompaniesCopy[editCompanyIndex] = companyBeingEdited;
        setCompanies([...fullCompaniesCopy]);
        setOpenConfirm(true);
        setType('success');
        setEditCompany(null);
        toggleAnchorEl();
      } else {
        setOpenConfirm(true);
        setType('error');
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          <span>Please edit the details of the selected company below:</span>
        </DialogTitle>
        <DialogContent>
          <form noValidate autoComplete="off">
            <TextField
              id="CompanyName"
              label="Company Name"
              fullWidth
              required
              onChange={e => setCompanyBeingEdited({ ...companyBeingEdited, companyName: e.target.value })}
              value={companyBeingEdited.companyName}
            />
            <TextField
              id="CompanyContactName"
              label="Company Contact Name"
              fullWidth
              required
              onChange={e => setCompanyBeingEdited({ ...companyBeingEdited, companyContactName: e.target.value })}
              value={companyBeingEdited.companyContactName}
            />
            <TextField
              id="CompanyContactEmail"
              label="Company Contact Email"
              fullWidth
              required
              onChange={e => setCompanyBeingEdited({ ...companyBeingEdited, companyContactEmail: e.target.value })}
              value={companyBeingEdited.companyContactEmail}
            />
            <TextField
              id="CompanyDescription"
              label="Company Description"
              fullWidth
              required
              onChange={e => setCompanyBeingEdited({ ...companyBeingEdited, companyDescription: e.target.value })}
              value={companyBeingEdited.companyDescription}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmitCompany} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditCompany;
