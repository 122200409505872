import React, { useState } from 'react';
import { Modal, Backdrop, Fade, makeStyles, Typography } from '@material-ui/core';
import { ApiEndpointReport, FailedReportsOverview } from './Interface/ApiMonitoringResponse';
import Paginator from '../common/Paginator';
import { FailedReportsHeader } from './FailedReportsHeader';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    width: '585px',
    maxHeight: '99%',
    overflowY: 'auto',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[0],
    padding: theme.spacing(3),
    paddingBottom: '0',
    outline: 'none',
    border: 'solid 1px rgba(35, 0, 30, 0.2)',
    borderRadius: '5px',
    '&:focus': {
      outline: 'none'
    }
  },
  reportsTableParent: {
    maxHeight: '600px',
    overflowY: 'auto'
  },
  reportsTable: {
    border: '1px solid gray',
    width: '100%',
    textAlign: 'center',
    borderCollapse: 'collapse'
  },
  row: { border: '2px solid gray', borderCollapse: 'collapse' },
  cell: {
    textAlign: 'center',
    border: '2px solid gray',
    borderCollapse: 'collapse'
  }
}));

interface FailureReportListProps {
  open: boolean;
  toggleOpen(): void;
  failedReports: ApiEndpointReport[];
  overview: FailedReportsOverview;
}

interface FailureReportRowProps {
  report: ApiEndpointReport;
}

const convertTimestamp = (timestamp: number): string => {
  const date = new Date(timestamp);
  return date.toLocaleString('en-GB');
};

const FailureReportRow: React.FC<FailureReportRowProps> = ({ report }) => {
  const classes = useStyles();
  return (
    <tr className={classes.row}>
      <td className={classes.cell}>
        <Typography>{convertTimestamp(report.timestamp)}</Typography>
      </td>
      <td className={classes.cell}>
        <Typography>{report.timeout ? 'True' : '-'}</Typography>
      </td>
      <td className={classes.cell}>
        <Typography>{report.responseTime}ms</Typography>
      </td>
      <td className={classes.cell}>
        <Typography>{report.statusCode}</Typography>
      </td>
    </tr>
  );
};

const FailureReportList: React.FC<FailureReportListProps> = ({ open, toggleOpen, failedReports, overview }) => {
  const classes = useStyles();

  // Paginator
  const [page, setPage] = useState<number>(0);
  const [rows, setRows] = useState<number>(20);

  return (
    <Modal
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      className={classes.modal}
      open={open}
      onClose={toggleOpen}
      aria-labelledby="Failure Report List"
      aria-describedby="Failure Report List"
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <FailedReportsHeader data={overview} />
          <div className={classes.reportsTableParent}>
            <table className={classes.reportsTable}>
              <tr className={classes.row}>
                <th className={classes.cell}>Date</th>
                <th className={classes.cell}>Time Out</th>
                <th className={classes.cell}>Response Time</th>
                <th className={classes.cell}>Status Code</th>
              </tr>
              {failedReports.slice(rows * page, rows * page + rows).map((report, idx) => (
                <FailureReportRow key={idx} report={report} />
              ))}
            </table>
          </div>
          <Paginator totalItems={failedReports.length} page={page} setPage={setPage} rows={rows} setRows={setRows} />
        </div>
      </Fade>
    </Modal>
  );
};

export default FailureReportList;
