import * as React from 'react';
import iconSize from './iconSizes';

function SvgApiMonitoringIcon(props) {
  return (
    <svg id="ApiMonitoringIcon_svg__Layer_1" data-name="Layer 1" viewBox="0 0 24 24" {...props} height={iconSize(props.size)} width={iconSize(props.size)}>
      <defs>
        <style>
          {
            '.ApiMonitoringIcon_svg__cls-4{fill:none}.ApiMonitoringIcon_svg__cls-2{fill:#3c3c3b}.ApiMonitoringIcon_svg__cls-4{stroke:#3c3c3b;stroke-linecap:round;stroke-miterlimit:10}.ApiMonitoringIcon_svg__cls-5{fill:#fff}'
          }
        </style>
      </defs>
      <path d="M11.68 11.41a2.56 2.56 0 01-4.18 2 2.16 2.16 0 01-.37-.41 2.56 2.56 0 114.55-1.62z" opacity={0.5} fill="none" />
      <path
        className="ApiMonitoringIcon_svg__cls-2"
        d="M9.12 8.33A3.09 3.09 0 006 11.41a3 3 0 00.73 2 3.08 3.08 0 102.39-5.08zm0 5.65a2.54 2.54 0 01-1.62-.58 2.16 2.16 0 01-.37-.4 2.56 2.56 0 112 1z"
      />
      <path
        className="ApiMonitoringIcon_svg__cls-2"
        d="M3.86 12.94h1v5.93a.51.51 0 01-.51.51.51.51 0 01-.51-.51v-5.93h.02z"
        transform="rotate(45 4.375 16.162)"
      />
      <path className="ApiMonitoringIcon_svg__cls-2" d="M6.77 13.4a3.06 3.06 0 00.36.36l-.3.3-.36-.36z" />
      <path d="M22 5.48v1.35H2V5.48a1 1 0 011-1h18a1 1 0 011 1z" stroke="#3c3c3b" strokeLinecap="round" strokeMiterlimit={10} fill="#3c3c3b" />
      <path className="ApiMonitoringIcon_svg__cls-4" d="M22 6.83v10.65a1 1 0 01-1 1H3.94M2 16.51V6.83" />
      <circle className="ApiMonitoringIcon_svg__cls-5" cx={3.25} cy={5.66} r={0.5} />
      <rect className="ApiMonitoringIcon_svg__cls-5" x={4.76} y={5.16} width={1} height={1} rx={0.5} />
      <rect className="ApiMonitoringIcon_svg__cls-5" x={6.76} y={5.16} width={1} height={1} rx={0.5} />
    </svg>
  );
}

export default SvgApiMonitoringIcon;
