import React from 'react';
import { ApiOverviewResponse } from './interface/ApiOverviewResponse';
import OverviewListItem from './OverviewListItem';

interface Props {
  appList: ApiOverviewResponse[];
}
const ApiOverviewList: React.FC<Props> = ({ appList }) => {
  return (
    <div>
      {appList
        .sort((a, b) => a.successRateInPeriod - b.successRateInPeriod)
        .map((app, idx) => (
          <OverviewListItem
            key={idx}
            appId={app.appId}
            appLogoUrl={app.appLogoUrl}
            appName={app.appName}
            successRateInPeriod={app.successRateInPeriod}
            endpointsAlertedInPeriod={app.endpointsAlertedInPeriod}
            endpointsCurrentlyAlerting={app.endpointsCurrentlyAlerting}
            totalEndpoints={app.totalEndpoints}
            archived={app.archived}
          />
        ))}
    </div>
  );
};

export default ApiOverviewList;
