import {EditMonitoringRateAndAlertsRequest, EditMonitoringRateAndAlertsResponse, EditServicesActive} from '../components/ApiMonitoring/Interface/AlertsData';
import {App, CreateAppRequest, EditPlatformsAndPackageNames} from '../components/AppList/AppModel';
import {HttpMethodsEnum} from '../models/enums/httpMethodsEnum';
import {LocalStorageItemEnum} from '../models/enums/localStorageItemEnum';
import {DeleteApiMonitoringAuth, MonitoringAuthRequest} from '../components/ApiMonitoring/Interface/ApiMonitoringAuth';
import { AuthUserTestResponse } from '../components/ApiMonitoring/Interface/AuthUserTestResponse';
import { throwIfResponseNotOk } from '../utils/fetchNotOkUtils';

const {REACT_APP_BACKEND_API_KEY, REACT_APP_BACKEND_API_URL} = process.env;

const getOptions = (method: HttpMethodsEnum): { method: HttpMethodsEnum; headers: Headers } => {
	const idToken = localStorage.getItem(LocalStorageItemEnum.idToken);
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	if (typeof REACT_APP_BACKEND_API_KEY === 'string') {
		headers.append('x-api-key', REACT_APP_BACKEND_API_KEY);
	}
	if (idToken != null) {
		headers.append('Authorization', idToken);
	}
	return {
		method: method,
		headers
	};
};

const getApps = (): Promise<Response> => {
	const options = getOptions(HttpMethodsEnum.get);
	return fetch(`${REACT_APP_BACKEND_API_URL}/apps`, options);
};

const getAppsByCompanyId = (companyId: string): Promise<Response> => {
	const options = getOptions(HttpMethodsEnum.get);
	return fetch(`${REACT_APP_BACKEND_API_URL}/apps?companyId=${companyId}`, options);
};

const createApp = (appDetails: CreateAppRequest): Promise<Response> => {
	const options = getOptions(HttpMethodsEnum.post);
	return fetch(`${REACT_APP_BACKEND_API_URL}/apps`, {...options, body: JSON.stringify(appDetails)});
};

const updateApp = (appDetails: App | EditServicesActive | EditPlatformsAndPackageNames): Promise<Response> => {
	const options = getOptions(HttpMethodsEnum.patch);
	return fetch(`${REACT_APP_BACKEND_API_URL}/apps`, {...options, body: JSON.stringify(appDetails)});
};

const deleteApp = (appId: string): Promise<Response> => {
	const options = getOptions(HttpMethodsEnum.delete);
	return fetch(`${REACT_APP_BACKEND_API_URL}/apps`, {...options, body: JSON.stringify({appId})});
};

const postAppAuthUser = (authUser: MonitoringAuthRequest): Promise<Response> => {
	const options = getOptions(HttpMethodsEnum.post);
	return fetch(`${REACT_APP_BACKEND_API_URL}/api-monitoring/auth-details`, {
		...options,
		body: JSON.stringify(authUser)
	});
};

const deleteAppAuthUser = (appId: DeleteApiMonitoringAuth): Promise<Response> => {
	const options = getOptions(HttpMethodsEnum.delete);
	return fetch(`${REACT_APP_BACKEND_API_URL}/api-monitoring/auth-details`, {...options, body: JSON.stringify(appId)});
};

const testAuthUserConfig = (authUser: MonitoringAuthRequest): Promise<AuthUserTestResponse> => {
	const options = getOptions(HttpMethodsEnum.post);
	return fetch(`${REACT_APP_BACKEND_API_URL}/api-monitoring/auth-details/test`, {...options, body: JSON.stringify(authUser)})  .then(response => response.json())
}

const editApiMonitoringRateAndAlerts = (request: EditMonitoringRateAndAlertsRequest): Promise<EditMonitoringRateAndAlertsResponse> => {
  const options = getOptions(HttpMethodsEnum.post);
  return (
    fetch(`${REACT_APP_BACKEND_API_URL}/api-monitoring/config`, { ...options, body: JSON.stringify(request) })
    .then(throwIfResponseNotOk)
    .then(resp => resp.json() as Promise<EditMonitoringRateAndAlertsResponse>)
  );
}


export {getApps, getAppsByCompanyId, createApp, updateApp, deleteApp, postAppAuthUser, deleteAppAuthUser, testAuthUserConfig, editApiMonitoringRateAndAlerts};
