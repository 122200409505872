import React, { useContext, useState } from 'react';
import { Button, Container, TextField, Typography, makeStyles } from '@material-ui/core';
import { challengeLogin } from '../../../api-queries/login';

import { UserContext } from '../../../contexts/UserContext';
import SvgApadmiPlus from '../../../Icons/ApadmiPlus';

const useStyles = makeStyles(theme => ({
  paper: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#fff',
    border: 'solid 1px rgba(35, 0, 30, 0.2)',
    textTransform: 'none',
    boxShadow: 'none',
    padding: '10px'
  },
  appName: {
    fontFamily: 'Zilla Slab, serif',
    fontSize: '60px',
    fontWeight: 700
  },
  subHeading: {
    fontFamily: 'Zilla Slab, serif',
    fontSize: '30px',
    fontWeight: 700
  },
  titleMessage: {
    marginTop: theme.spacing(4),
    textAlign: 'center',
    color: 'red'
  },
  logo: {
    marginTop: '20px',
    marginBottom: '20px',
    width: '120px',
    textAlign: 'center'
  },
  errorMessage: {
    color: 'red'
  },
  apadmiLogo: {
    marginBottom: '20px'
  }
}));

export default function NewPassword() {
  const classes = useStyles();

  const { setLoggedInUser, setNewPasswordRequired, user, setProfileUpdateRequired } = useContext(UserContext);

  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [userError, setUserError] = useState(false);

  async function handleSubmit(event) {
    event.preventDefault();
    await challengeLogin({
      user,
      newPassword,
      setLoggedInUser,
      setNewPasswordRequired,
      setUserError,
      setProfileUpdateRequired
    });
  }

  return (
    <Container className={classes.paper} maxWidth="xs">
      <SvgApadmiPlus className={classes.apadmiLogo}></SvgApadmiPlus>
      <Typography className={classes.subHeading}>analytics portal</Typography>
      <Typography className={classes.titleMessage} component="h1" variant="h5" paragraph={true}>
        First time login
        <br></br>
        New password required.
      </Typography>
      <form className={classes.form} onSubmit={handleSubmit}>
        <TextField
          onChange={({ target: { value } }) => {
            setNewPassword(value);
          }}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="New Password"
          type="password"
          id="password"
          autoComplete="new-password"
          error={userError}
        />
        <TextField
          onChange={({ target: { value } }) => {
            setConfirmNewPassword(value);
          }}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Confirm New Password"
          type="password"
          id="password"
          autoComplete="confirm-password"
          error={userError}
        />

        <p>Passwords must be a minimum of 8 characters and contain at least 1 lowercase character, 1 uppercase character and 1 symbol character</p>

        <Button
          disabled={!newPassword.length || newPassword !== confirmNewPassword}
          type="submit"
          fullWidth
          variant="contained"
          className={classes.submit}
          id="submit"
        >
          Change password
        </Button>
      </form>
    </Container>
  );
}
