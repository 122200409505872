import { makeStyles } from '@material-ui/core';
import React from 'react';
import EndpointCard from './EndpointCard';
import { EndpointData } from './Interface/EndpointData';

interface Props {
  endpoints: EndpointData[];
}

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: '10px',
    marginBottom: '60px'
  }
}));

const EndpointList: React.FC<Props> = ({ endpoints }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {endpoints.length > 0 ? (
        endpoints.map((endpoint: EndpointData, idx) => (
          <EndpointCard
            key={idx}
            name={endpoint.name}
            failCount={endpoint.failCount}
            failing={endpoint.failing}
            url={endpoint.url}
            method={endpoint.method}
          />
        ))
      ) : (
        <p>It looks like you haven't added any endpoints yet</p>
      )}
    </div>
  );
};

export default EndpointList;
