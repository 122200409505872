import { Button, Link as LinkUi, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { App } from '../../AppList/AppModel';
import ConfirmationSnackbar from '../../common/Confirmation/confirmationSnackbar';
import CreateApp from '../createApp';
import SdkSetup from '../SdkSetup';
import Paginator from '../../common/Paginator';

interface Props {
  apps: App[];
  setApps: React.Dispatch<React.SetStateAction<any>>;
  companyId: string;
}

const useStyles = makeStyles(() => ({
  appName: {
    width: '35%'
  },
  viewAppColumn: {
    minWidth: '135px'
  },
  row: {
    height: '81px'
  }
}));

const AppsTable: React.FC<Props> = ({ apps, setApps, companyId }): JSX.Element => {
  const [addApp, setAddApp] = useState<boolean>(false);
  const [sdkSetupOpen, setSdkSetupOpen] = useState<boolean>(false);
  //Paginator
  const [page, setPage] = useState<number>(0);
  const [rows, setRows] = useState<number>(5);

  const classes = useStyles();

  interface CreatedApp {
    name: string;
    appId: string;
  }
  const [createdApp, setCreatedApp] = useState<CreatedApp>({ name: '', appId: '' });

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [type, setType] = useState<string>('');

  const handleAddApp = (): void => {
    setAddApp(!addApp);
  };

  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.appName}>App Name</TableCell>
              <TableCell>API Monitoring enabled</TableCell>
              <TableCell className={classes.viewAppColumn}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {apps.slice(rows * page, rows * page + rows).map((app, idx) => (
              <TableRow className={classes.row} key={idx}>
                <TableCell>{app.appName}</TableCell>
                <TableCell>{app.servicesActive.apiMonitoring ? 'true' : 'false'}</TableCell>
                <TableCell>
                  <Button component={Link} to={`/app/${app.appId}/app-overview`}>
                    <Typography>View App</Typography>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Paginator totalItems={apps.length} page={page} setPage={setPage} rows={rows} setRows={setRows} />
      <Button onClick={handleAddApp}>Add App</Button>
      <CreateApp
        open={addApp}
        companyId={companyId}
        handleClose={handleAddApp}
        apps={apps}
        setApps={setApps}
        setSdkSetupOpen={setSdkSetupOpen}
        setCreatedApp={setCreatedApp}
        setOpenSnackbar={setOpenSnackbar}
        setType={setType}
      />
      <ConfirmationSnackbar open={openSnackbar} setOpen={setOpenSnackbar} type={type} />
      <SdkSetup open={sdkSetupOpen} setOpen={setSdkSetupOpen} appName={createdApp.name} appId={createdApp.appId} />
    </div>
  );
};

export default AppsTable;
