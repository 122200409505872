import React, {useContext, useState} from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	makeStyles,
	TextField
} from '@material-ui/core';
import {UserContext} from '../../contexts/UserContext';
import {updateUser} from '../../api-queries/userManagement';
import ConfirmationSnackbar from '../common/Confirmation/confirmationSnackbar';
import {User} from '../../models/userModel';

interface Props {
	open: boolean;
	handleClose: () => void;
}

const useStyles = makeStyles(theme => ({
	formItem: {
		margin: '8px'
	}
}));


const EditProfile: React.FC<Props> = ({open, handleClose}): JSX.Element => {
	const classes = useStyles();
	const {loggedInUser, setLoggedInUser, profileUpdateRequired, setProfileUpdateRequired} = useContext(UserContext);
	const [user, setUser] = useState<User>(loggedInUser!);
	const [openConfirmation, setOpenConfirmation] = useState(false);
	const [type, setType] = useState<string>();

	const handleUpdateProfile = async () => {
		user.profileUpdateRequired = false;
		try {
			const response = await updateUser(user);
			const data = await response.json()
			if (response.status === 200) {
				setOpenConfirmation(true);
				setType('success');
				setProfileUpdateRequired(false);
				handleClose();
				setLoggedInUser(data?.user);
			} else {
				setOpenConfirmation(true);
				setType('error');
			}
		} catch (err) {
			throw new Error(err);
		}
	};

	return (
		<div>
			<Dialog fullWidth
							maxWidth="sm"
							open={open} onClose={handleClose} aria-labelledby="form-dialog-title">


				<DialogTitle id="form-dialog-title">
					{profileUpdateRequired ?
						<span>Please update your profile before continuing</span> :
						<span>Edit Details</span>}
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Make changes to your profile here
					</DialogContentText>
					<form noValidate autoComplete="off">
						<TextField
							className={classes.formItem}
							autoFocus
							id="firstName"
							label="First Name"
							type="text"
							value={user.firstName}
							fullWidth
							required
							onChange={e => {
								setUser({...user, firstName: e.target.value});
							}}
						/>
						<TextField
							className={classes.formItem}
							id="lastName"
							label="Last Name"
							fullWidth
							required
							value={user.lastName}
							onChange={e => {
								setUser({...user, lastName: e.target.value});
							}}
						/>
					</form>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Cancel
					</Button>
					<Button onClick={handleUpdateProfile} color="primary" disabled={!user.lastName || !user.firstName}>
						Update
					</Button>
				</DialogActions>
			</Dialog>
			<ConfirmationSnackbar open={openConfirmation} setOpen={setOpenConfirmation} type={type}/>
		</div>
	);
};

export default EditProfile;
