import React from 'react';
import { Card, Grid, Typography, makeStyles, useTheme, useMediaQuery } from '@material-ui/core';
import { ApiAvgResTimeIcon, ApiMinResTimeIcon, ApiMaxResTimeIcon, ApiSuccessRateIcon, ApiTimeoutRateIcon, ApiCallsIcon } from '../../Icons';
import { ApiMonitoringResponse } from './Interface/ApiMonitoringResponse';

interface Props {
  data: ApiMonitoringResponse;
}

const useStyles = makeStyles(theme => ({
  card: {
    padding: theme.spacing(5, 3),
    textAlign: 'center',
    height: '100%',
    '& h3': {
      marginTop: theme.spacing(2)
    }
  },
  text: {
    fontSize: '32px'
  },
  container: {
    marginTop: '10px'
  },
  apicon: {
    fontSize: '3.6rem',
    verticalAlign: 'middle',
    margin: theme.spacing(0, 1, 1, 0)
  }
}));

const ApiStatsCard: React.FC<Props> = ({ data }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div className={classes.container}>
      <Grid container item direction="row" alignItems="stretch" spacing={isSm ? 3 : 6}>
        <Grid item xs={12} sm={6} md={4}>
          <Card className={classes.card}>
            <ApiAvgResTimeIcon className={classes.apicon} />
            <Typography variant="h3" component="span">
              {data.avgResponseTime.toFixed(2) + 'ms'}
            </Typography>
            <Typography variant="h2" component="h3">
              Average Response Time
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card className={classes.card}>
            <ApiMaxResTimeIcon className={classes.apicon} />
            <Typography variant="h3" component="span">
              {data.maxResponseTime.toFixed(2) + 'ms'}
            </Typography>
            <Typography variant="h2" component="h3">
              Maximum Response Time
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card className={classes.card}>
            <ApiMinResTimeIcon className={classes.apicon} />
            <Typography variant="h3" component="span">
              {data.minResponseTime.toFixed(2) + 'ms'}
            </Typography>
            <Typography variant="h2" component="h3">
              Minimum Response Time
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card className={classes.card}>
            <ApiSuccessRateIcon className={classes.apicon} />
            <Typography variant="h3" component="span">
              {data.overallSuccessRate.toFixed(2) + '%'}
            </Typography>
            <Typography variant="h2" component="h3">
              Overall Success Rate
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card className={classes.card}>
            <ApiTimeoutRateIcon className={classes.apicon} />
            <Typography variant="h3" component="span">
              {data.overallTimeoutRate.toFixed(2) + '%'}
            </Typography>
            <Typography variant="h2" component="h3">
              Overall Timeout Rate
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card className={classes.card}>
            <ApiCallsIcon className={classes.apicon} />
            <Typography variant="h3" component="span">
              {data.totalApiCalls}
            </Typography>
            <Typography variant="h2" component="h3">
              Total API Calls
            </Typography>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default ApiStatsCard;
