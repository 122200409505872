import { Paper, Grid, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import DataCard from './DataCard';
import { ApiOverviewResponse } from './interface/ApiOverviewResponse';

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    marginBottom: '12px',
    padding: theme.spacing(2, 3),
    [theme.breakpoints.down('sm')]: {
      marginTop: '12px'
    }
  },
  red: {
    backgroundColor: '#D2222D'
  },
  amber: {
    backgroundColor: '#FFBF00'
  },
  green: {
    backgroundColor: '#2BB02B'
  },
  appLogo: { width: '50px' },
  appName: {
    textDecoration: 'none',
    color: 'black',
    [theme.breakpoints.down('md')]: {
      fontSize: '15px'
    }
  }
}));

const OverviewListItem: React.FC<ApiOverviewResponse> = ({
  appLogoUrl,
  appId,
  appName,
  successRateInPeriod,
  endpointsAlertedInPeriod,
  endpointsCurrentlyAlerting,
  totalEndpoints
}) => {
  const classes = useStyles();
  let successRateRagColor = classes.green;
  let endpointAlertedRagColor = classes.green;
  let currentEndpointRagColor = classes.green;

  if (successRateInPeriod < 97) {
    successRateRagColor = classes.red;
  } else if (successRateInPeriod < 99) {
    successRateRagColor = classes.amber;
  }

  if (endpointsAlertedInPeriod > 0) {
    endpointAlertedRagColor = classes.amber;
  }

  if (endpointsCurrentlyAlerting > 0) {
    currentEndpointRagColor = classes.red;
  }

  return (
    <div>
      <Paper className={classes.paper}>
        <Grid container item xs={12}>
          <Grid item container alignItems="center" justify="center" xs={12} md={2}>
            {/* TODO Add image */}
            {/* <img src={appLogoUrl} className={classes.appLogo} /> */}
            <Link to={`/app/${appId}/api-monitoring`} style={{ textDecoration: 'none' }}>
              <Typography noWrap className={classes.appName}>
                <strong>{appName}</strong>
              </Typography>
            </Link>
          </Grid>
          <Grid item container alignItems="center" justify="center" xs={12} md={10}>
            <DataCard title={'Success Rate In Period'} value={`${successRateInPeriod.toFixed(2)}%`} colorClass={successRateRagColor} />
            <DataCard title={'Endpoints Alerted In Period'} value={`${endpointsAlertedInPeriod}/${totalEndpoints}`} colorClass={endpointAlertedRagColor} />
            <DataCard
              title={'Endpoints Currently Alerting'}
              value={`${endpointsCurrentlyAlerting}/${totalEndpoints}`}
              colorClass={currentEndpointRagColor}
            />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default OverviewListItem;
