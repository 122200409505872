import { makeStyles, Grid, Fade, Button, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { getApiOverviewData } from '../../api-queries/apiMonitoring';
import { SelectionRangeContext } from '../../contexts/SelectionRangeContext';
import ProgressBar from '../common/ProgressBar';
import PageTitle from '../PageTitle';
import ApiOverviewList from './ApiOverviewList';
import { ApiOverviewResponse } from './interface/ApiOverviewResponse';

const useStyles = makeStyles(theme => ({
  progressBar: {
    height: '100vh',
    width: '100vw'
  },
  divider: {
    width: '100%',
    border: '1px solid lightgrey',
    display: 'block'
  },
  subTitle: {
    display: 'block',
    color: 'black',
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: '15px'
    }
  }
}));

const ApiMonitoringOverview: React.FC = () => {
  const classes = useStyles();
  const [checked] = useState(true);
  const [loading, setLoading] = useState(false);

  const { selectionRange } = useContext(SelectionRangeContext);

  const [apiOverviewList, setApiOverviewList] = useState<ApiOverviewResponse[]>([]);
  const [archivedApiOverviewList, setArchivedApiOverviewList] = useState<ApiOverviewResponse[]>([]);

  const loadData = async (clearCache?: boolean) => {
    console.log('cache', clearCache);
    setLoading(true);
    try {
      const response = await getApiOverviewData(selectionRange.startDateMS, selectionRange.endDateMS, clearCache);
      if (response.status === 200) {
        const jsonRes = await response.json();
        const _apiOverviewList = jsonRes.apiOverviewData.filter((appData: ApiOverviewResponse) => !appData.archived);
        const _archivedApiOverviewList = jsonRes.apiOverviewData.filter((appData: ApiOverviewResponse) => appData.archived);
        setApiOverviewList(_apiOverviewList);
        setArchivedApiOverviewList(_archivedApiOverviewList);
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData(false);
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} md={12} container direction={'row'} justify={'space-between'} alignItems={'center'}>
        <PageTitle text={'API Monitoring Overview'} />
        <Button onClick={() => loadData(true)}>Refresh</Button>
      </Grid>

      {!loading && apiOverviewList.length > 0 && (
        <>
          <Fade in={checked} {...(checked ? { timeout: 300 } : {})}>
            <Grid item xs={12}>
              <ApiOverviewList appList={apiOverviewList} />
            </Grid>
          </Fade>
        </>
      )}
      {!loading && archivedApiOverviewList.length > 0 && (
        <>
          <hr className={classes.divider} />
          <p className={classes.subTitle}>Archived</p>
          <Fade in={checked} {...(checked ? { timeout: 300 } : {})}>
            <Grid item xs={12}>
              <ApiOverviewList appList={archivedApiOverviewList} />
            </Grid>
          </Fade>
        </>
      )}
      {!loading && apiOverviewList.length <= 0 && <p>There is no data for the APIs being monitored in the selected date range.</p>}
      {loading && (
        <div className={classes.progressBar}>
          <ProgressBar />
        </div>
      )}
    </Grid>
  );
};

export default ApiMonitoringOverview;
