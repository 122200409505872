import React from 'react';
import { makeStyles, Button, Typography, CardContent, Paper, Modal, Fade, Backdrop } from '@material-ui/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const useStyles = makeStyles((theme: any) => ({
  form: {
    width: '100%'
  },
  paper: {
    width: '500px',
    [theme.breakpoints.down('md')]: {
      width: '450px'
    }
  },
  cardContent: {
    width: '100%'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  button: {
    ...theme.buttonGreen,
    marginTop: '20px'
  },
  content: {
    marginTop: '20px'
  }
}));

interface Props {
  open: boolean;
  setOpen: Function;
  appName: string;
  appId: string;
}

const SdkSetup: React.FC<Props> = ({ open, setOpen, appName, appId }) => {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleClose}
      BackdropComponent={Backdrop}
    >
      <Fade in={open}>
        <Paper className={classes.paper}>
          <CardContent className={classes.cardContent}>
            <Typography variant="h5">Installing the SDK</Typography>
            <Typography className={classes.content}>To install the SDK for {appName}</Typography>
            <Typography className={classes.content}>Please copy the following app ID into the SDK code</Typography>
            <Typography variant="h6" className={classes.content}>
              {appId}
            </Typography>
            <CopyToClipboard
              text={appId}
              onCopy={() => {
                setOpen(true);
              }}
            >
              <Button variant="contained" color="primary" className={classes.button}>
                Copy to clipboard
              </Button>
            </CopyToClipboard>
          </CardContent>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default SdkSetup;
