import React, {useState} from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	makeStyles,
	TextField,
	Theme
} from '@material-ui/core';
import ConfirmationSnackbar from '../common/Confirmation/confirmationSnackbar';
import {createCompanyUser} from '../../api-queries/userManagement';
import {ValidateEmail} from '../../utils/helpers';
import {User} from '../../models/userModel';
import {userLevels} from '../../utils/userLevelEnum';

const useStyles = makeStyles((theme: Theme) => ({
	formItem: {
		margin: '8px'
	}
}));

interface Props {
	open: boolean;
	companyId: string;
	handleClose: () => void;
	users: User[];
	setUsers: React.Dispatch<React.SetStateAction<any>>;
}

const CreateUser: React.FC<Props> = ({open, companyId, handleClose, users, setUsers}): JSX.Element => {
	const classes = useStyles();
	const emptyUser = {email: '', companyId, userLevel: userLevels['Basic User']};
	const [userDetails, setUserDetails] = useState(emptyUser);

	// Success snackbar
	const [type, setType] = useState<string>();
	// Confirmation modal state
	const [openConfirm, setOpenConfirm] = useState(false);

	const [emailValid, setEmailValid] = useState(true);

	const handleSubmit = async (): Promise<void> => {
		try {
			const result = await createCompanyUser(userDetails);
			if (result.status === 200) {
				const {user} = await result.json();
				setOpenConfirm(true);
				setType('success');
				setUserDetails(emptyUser);
				setEmailValid(true);
				setUsers([user, ...users]);
				handleClose();
			} else {
				setOpenConfirm(true);
				setType('error');
			}
		} catch (err) {
			console.log(err);
		}
	};

	const handleEmailValidation = (event: any) => {
		setEmailValid(ValidateEmail(event.target.value.trim()));
		setUserDetails({...userDetails, email: event.target.value.trim()});
	};

	const closeModal = () => {
		setUserDetails(emptyUser);
		handleClose();
	};

	return (
		<div>
			<Dialog fullWidth maxWidth="sm" open={open} onClose={closeModal} aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">
					<span>Please enter the email of the user you would like to add to this organisation below:</span>
				</DialogTitle>
				<DialogContent>
					<form noValidate autoComplete="off">
						<TextField
							className={classes.formItem}
							id="email"
							label="Email"
							fullWidth
							required
							error={!emailValid}
							onChange={e => handleEmailValidation(e)}
						/>
					</form>
				</DialogContent>
				<DialogActions>
					<Button onClick={closeModal} color="primary">
						Cancel
					</Button>
					<Button onClick={handleSubmit} color="primary" disabled={!emailValid || !userDetails.email}>
						Submit
					</Button>
				</DialogActions>
			</Dialog>
			<ConfirmationSnackbar open={openConfirm} setOpen={setOpenConfirm} type={type}/>
		</div>
	);
};

export default CreateUser;
