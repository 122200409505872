import React, { useState, useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Input from '@material-ui/core/Input';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { deleteUser } from '../../api-queries/userManagement';
import { UserContext } from '../../contexts/UserContext';
import { logout } from '../../api-queries/login';

interface Props {
  open: boolean;
  handleClose: () => void;
}

const deleteString = 'DELETE';

const DeleteProfile: React.FC<Props> = ({ open, handleClose }): JSX.Element => {
  const [matched, setMatched] = useState<boolean>(false);
  const { loggedInUser, setLoggedInUser } = useContext<any>(UserContext);

  const handleDelete = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const userInput = e.target.value;
    userInput === deleteString ? setMatched(true) : setMatched(false)
  };

  const submitDelete = async (): Promise<void> => {
    try {
      const response = await deleteUser(loggedInUser.userId);
      if (response.status === 200) {
        logout(setLoggedInUser);
      }
    } catch (e) {
      throw new Error(e);
    }
  };

  const closeModal = () => {
		setMatched(false)
  	handleClose()
	}

  return (
    <Dialog open={open} onClose={closeModal} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{'Delete Account'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete your account? If so please type DELETE into the field below. After you confirm this action you will be logged out
          from the system.
        </DialogContentText>
        <Input onChange={handleDelete} />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color="primary">
          Cancel
        </Button>
        <Button disabled={!matched} color="primary" onClick={submitDelete} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteProfile;
